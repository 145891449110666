import {setBackendError, toggleHasMore, toggleIsFetching, toggleMoreLoading} from "./appReducer";
import otvechaiMiniApi from "../api/otvechaiMiniApi";

const SET_NOTIFS = 'SET_NOTIFS';
const ADD_OTHER = "ADD_OTHER";
const SET_OTHER = 'SET_OTHER'
const SET_NOTE_REMOVED = 'SET_NOTE_REMOVED';

let initialState = {
    notifs: {
        a: null,
        c: null,
        f: null,
        pms: null,
        pm: null,
        n: null,
        p: null,
        pn: null,
        bc: null
    },
    other: []
}

export const setNotifs = (notifs) => ({type:SET_NOTIFS, notifs});
const setOther = (other) => ({type:SET_OTHER, other});
const addOther = (other) => ({type:ADD_OTHER, other});
export const setNoteRemoved = (id)=>({type:SET_NOTE_REMOVED,id})

export const getOther = (page=null) => {
    return async (dispatch) => {
        if (page) dispatch(toggleMoreLoading(true))
        else dispatch(toggleIsFetching(true));
        try{
            let response = await otvechaiMiniApi.getOther(page);
            if(response.status===200) {
                if(page) {
                    dispatch(toggleMoreLoading(false))
                    dispatch(addOther(response.data.notes))
                }
                else {
                    dispatch(setOther(response.data.notes));
                    dispatch(toggleHasMore(response.data.hasMore))
                    dispatch(toggleIsFetching(false));
                }
            }
            else{
                dispatch(toggleIsFetching(false))
            }
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleIsFetching(false))
            dispatch(toggleMoreLoading(false))
        }
    }
};

export const removeNotif = (obj) => {
    return async (dispatch) => {
        let response = await otvechaiMiniApi.removeNotif(obj);
        if(response.data.status===0){
            dispatch(setNoteRemoved(obj.id));
        }
    }
}


const notifyReducer = (state=initialState,action) => {
    switch (action.type) {
        case SET_NOTIFS:
            if(action.notifs)
                return {...state, notifs:action.notifs}
            return initialState
        case SET_OTHER:
            return{
                ...state,
                other: action.other
            };
        case ADD_OTHER:
            return{
                ...state,
                other: state.other.concat(action.other)
            };
        case SET_NOTE_REMOVED:
            return {
                ...state,
                other:state.other.filter(({id})=>id!==action.id)
            };
        default:
            return state
    }
};

export default notifyReducer

