import React, {useEffect} from "react";
import {Panel, PanelHeader, Title,Div} from "@vkontakte/vkui";
import TopNav from "../Nav/TopNav";
import {useDispatch, useSelector} from "react-redux";
import {getStatic} from "../Redux/staticReducer";
import BottomNav from "../Nav/BottomNav";


const StaticPanel = (props) => {
    const dispatch = useDispatch()
    const staticPage = useSelector(state => state.static);

    useEffect(() => {
        dispatch(getStatic(staticPage.current));
    }, [staticPage.current]);


    return(
        <Panel id={props.id}>
            <PanelHeader
                left={<TopNav/>}
            />
            <Div>
                <Title level={"2"} weight={"semibold"} style={{ marginBottom: 16, textAlign:"center"}}>{staticPage.title}</Title>
                <div dangerouslySetInnerHTML={{__html:staticPage.body}}/>
            </Div>
            <div className={'lastElement'}/>
            <BottomNav/>
        </Panel>
    )
}

export default StaticPanel