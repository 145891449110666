import {
    setActivePanel,
    setBackendError,
    toggleHasMore,
    toggleInProgress,
    toggleIsFetching,
    toggleMoreLoading
} from "./appReducer";
import otvechaiMiniApi from "../api/otvechaiMiniApi";
import {
    setStatsType,
    setSubStatType,
    setUserAnswers,
    setUserComments,
    setUserName,
    toggleStatsFromNotes
} from "./userPostsReducer";

let initialState = {
    needToLoad:null,
    random:null,
    id:null,
    answerId:null,
    noteType:null,
    questionAdded:0,
    quest: {
        id: null,
        author_id: null,
        author_username: null,
        author_name: null,
        author_avatar: null,
        author_online: null,
        author_gl: null,
        removed:{author:null,staff:null},
        liked: null,
        likes: null,
        answers: null,
        views: null,
        date_pub: null,
        body: {content:[],raw:null},
        stopped:null,
        tags:[],
        image:null,
        image_processing:null,
        poll:{name: "", voted:false, options: []}
    },
    answer: {
        id: null,
        author_id: null,
        author_username: null,
        author_name: null,
        author_avatar: null,
        author_online: null,
        removed:{author:null,staff:null},
        liked: null,
        likes: null,
        comments: null,
        date_pub: null,
        body: {content:[],raw:null},
        lastCommentRemoved:null,
        comments_disabled:null
    },
    answers: [],
    comments:[],
    tags:[],
    edit:{
        type:null,
        id:null,
        body:null,
        tags:[],
        image:null,
        image_processing:null
    }
}

const LOAD_RANDOM_QUEST = 'LOAD_RANDOM_QUEST'
const LOAD_QUEST = 'LOAD_QUEST'
const LOAD_ANSWER = 'LOAD_ANSWER'
const TOGGLE_NEED_TO_LOAD = 'TOGGLE_NEED_TO_LOAD'

const SET_NOTE_TYPE= 'SET_NOTE_TYPE'

const SET_QUEST = "SET_QUEST";
const SET_ANSWER = "SET_ANSWER";
const SET_ANSWERS = "SET_ANSWERS";
const ADD_ANSWERS = "ADD_ANSWERS";
const SET_TAGS = "SET_TAGS";
const SET_COMMENTS = "SET_COMMENTS";
const ADD_COMMENTS = "ADD_COMMENTS";

const TOGGLE_QUEST_LIKE = 'TOGGLE_QUEST_LIKE';
const SET_QUEST_LIKED = "SET_QUEST_LIKED";
const SET_QUEST_LIKES = "SET_QUEST_LIKES";
const TOGGLE_ANSWER_LIKE = "TOGGLE_ANSWER_LIKE"
const SET_ANSWER_LIKED = "SET_ANSWER_LIKED";
const SET_ANSWER_LIKES = "SET_ANSWER_LIKES";
const TOGGLE_ANSWERS_LIKE = 'TOGGLE_ANSWERS_LIKE';
const SET_ANSWERS_LIKED = "SET_ANSWERS_LIKED";
const SET_ANSWERS_LIKES = "SET_ANSWERS_LIKES";
const TOGGLE_COMMENTS_LIKE = 'TOGGLE_COMMENTS_LIKE';
const SET_COMMENTS_LIKED = "SET_COMMENTS_LIKED";
const SET_COMMENTS_LIKES = "SET_COMMENTS_LIKES";

const SET_ANSWER_REMOVED = "SET_ANSWER_REMOVED";
const SET_COMMENT_REMOVED = "SET_COMMENT_REMOVED";

const TOGGLE_POST_SUBSCRIPTION = "TOGGLE_POST_SUBSCRIPTION";

const SET_AUTHOR_GL = "SET_AUTHOR_GL"

const SET_EDIT_POST = "SET_EDIT_POST"
const EDIT_QUEST = "EDIT_QUEST"
const EDIT_ANSWER = "EDIT_ANSWER"
const EDIT_COMMENT = "EDIT_COMMENT"

const SET_POST_IMAGE = 'SET_POST_IMAGE'
const SET_QUESTION_ADDED = 'SET_QUESTION_ADDED'

const SET_POLL_VOTED = 'SET_POLL_VOTED'

export const loadRandomQuest =(needToLoad=true) => ({type:LOAD_RANDOM_QUEST,needToLoad})
export const loadQuest =(id) => ({type:LOAD_QUEST,id})
export const loadAnswer =(id) => ({type:LOAD_ANSWER,id})
export const toggleNeedToLoad =(status) => ({type:TOGGLE_NEED_TO_LOAD,status})

const setQuest = (quest) => ({type:SET_QUEST, quest});
const setAnswer = (answer) => ({type:SET_ANSWER, answer});
const setAnswers = (answers) => ({type:SET_ANSWERS, answers});
const addAnswers = (answers) => ({type:ADD_ANSWERS, answers});
const setTags = (tags) => ({type:SET_TAGS, tags});
const setComments = (comments) => ({type:SET_COMMENTS, comments});
const addComments = (comments,reverse=false) => ({type:ADD_COMMENTS, comments,reverse});

const setNoteType = (id,noteType) => ({type:SET_NOTE_TYPE, id,noteType});

const setQuestLiked = (liked) => ({type:SET_QUEST_LIKED, liked});
const setQuestLikes = (likes) => ({type:SET_QUEST_LIKES, likes});
const toggleQuestLike = () => ({type:TOGGLE_QUEST_LIKE});
const toggleAnswerLike = () => ({type:TOGGLE_ANSWER_LIKE});
const setAnswerLiked = (liked) => ({type:SET_ANSWER_LIKED, liked});
const setAnswerLikes = (likes) => ({type:SET_ANSWER_LIKES, likes});
const setAnswersLiked = (id,liked) => ({type:SET_ANSWERS_LIKED, id, liked});
const setAnswersLikes = (id, likes) => ({type:SET_ANSWERS_LIKES, id, likes});
const toggleAnswersLike = (id) => ({type:TOGGLE_ANSWERS_LIKE,id});
const setCommentsLiked = (id,liked) => ({type:SET_COMMENTS_LIKED, id, liked});
const setCommentsLikes = (id, likes) => ({type:SET_COMMENTS_LIKES, id, likes});
const toggleCommentsLike = (id) => ({type:TOGGLE_COMMENTS_LIKE,id});

const setAnswerRemoved = (id)=>({type:SET_ANSWER_REMOVED,id})
const setCommentRemoved = (id)=>({type:SET_COMMENT_REMOVED,id})

export const setQuestionAdded =(questId) => ({type:SET_QUESTION_ADDED,questId})

export const togglePostSubscription = (id,sub,objType) => ({type:TOGGLE_POST_SUBSCRIPTION, id,sub,objType});

export const setEditPost = (postType,id,body,image,image_processing,tags=null) => ({type:SET_EDIT_POST, postType, id,body,image,image_processing,tags});
export const editQuest = (id,body,tags) => ({type:EDIT_QUEST, id,body,tags});
export const editAnswer = (id,body) => ({type:EDIT_ANSWER, id,body});
export const editComment = (id,body) => ({type:EDIT_COMMENT, id,body});

const setAuthorGl = (gl) => ({type:SET_AUTHOR_GL,gl})

const setPostImage = (postType,id,image,image_processing) => ({type:SET_POST_IMAGE,postType,id,image,image_processing})

const setPollVoted = (id) => ({type:SET_POLL_VOTED,id})


export const pollVote = (id,type,postid) => {
    return async (dispatch) => {
        dispatch(setPollVoted(id))
        await otvechaiMiniApi.pollVote(id,type,postid)
    }
}

export const getRandomQuest = (skip=null) => {
    return async (dispatch) => {
        dispatch(toggleIsFetching(true));
        dispatch(loadRandomQuest(false))
        try{
            let response
            if(skip)
                response = await otvechaiMiniApi.skipRandomQuestion(skip);
            else
                response = await otvechaiMiniApi.getRandomQuestion();
            if(response.status===200){
                dispatch(setQuest(response.data.quest));
                dispatch(setAnswers([]));
                dispatch(setAnswer(initialState.answer))
            }
            else dispatch(setBackendError(response.status))
            dispatch(toggleIsFetching(false))
            dispatch(toggleNeedToLoad(false))
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleIsFetching(false))
            dispatch(toggleNeedToLoad(false))
        }
    }
};

export const getQuest = (id, page=null) => {
    return async (dispatch) => {
        dispatch(toggleIsFetching(true));
        try{
            let response = await otvechaiMiniApi.getQuestion(id,page);
            if(response.status===200){
                dispatch(setQuest(response.data.quest));
                dispatch(setAnswers(response.data.answers));
                dispatch(toggleHasMore(response.data.hasMore));
            }
            else dispatch(setBackendError(response.status))
            dispatch(toggleIsFetching(false))
            dispatch(toggleNeedToLoad(false))
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleIsFetching(false))
            dispatch(toggleNeedToLoad(false))
        }
    }
};

export const getTags = () => {
    return async (dispatch) => {
        dispatch(toggleInProgress(true));
        try{
            let response = await otvechaiMiniApi.getTags();
            if(response.status===200){
                dispatch(setTags(response.data.tags))
            }
            dispatch(toggleInProgress(false));
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleInProgress(false));
        }
    }
};

export const sendQuest = (quest) => {
    return async (dispatch) => {
        dispatch(toggleInProgress(true));
        try {
            let response = await otvechaiMiniApi.sendQuest(quest);
            dispatch(toggleInProgress(false));
            return (response.data.id)
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleInProgress(false));
        }

    }
};

export const skip = (obj) => {
    return async (dispatch) => {
        dispatch(toggleIsFetching(true));
        try{
            let response = await otvechaiMiniApi.skip(obj);
            if(response.data.status===0) {
                dispatch(setQuest(response.data.quest));
                dispatch(setAnswer(response.data.answer));
                dispatch(setComments(response.data.comments));
                dispatch(toggleIsFetching(false))
                return(response.data.answer.id)
            }
            else {
                dispatch(toggleIsFetching(false))
                return false
            }
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(1))
            else dispatch(setBackendError(1))
            dispatch(toggleIsFetching(false))
            return false
        }
    }
}


export const getAnswer = (id,page=null) => {
    return async (dispatch) => {
        dispatch(toggleIsFetching(true));
        try{
            let response
            if(id==='answers')
                response = await otvechaiMiniApi.getAnswerNotify();
            else if(id==='comments')
                response = await otvechaiMiniApi.getCommentNotify();
            else if(id==='streams')
                response = await otvechaiMiniApi.getAnswerStream();
            else
                response = await otvechaiMiniApi.getAnswer(id,page);
            if(response.status===200) {
                dispatch(setQuest(response.data.quest));
                dispatch(setAnswer(response.data.answer));
                dispatch(setComments(response.data.comments));
                dispatch(toggleHasMore(response.data.hasMore));
            }
            dispatch(toggleIsFetching(false))
            dispatch(toggleNeedToLoad(false))
        }
        catch (e) {
            if(e.response) {
                if (e.response.status === 404 && ['answers','comments','streams'].includes(id)) {
                    dispatch(toggleIsFetching(false))
                    return (1)
                }
                else dispatch(setBackendError(1))
            }
            else {
                dispatch(setBackendError(1))
                dispatch(toggleIsFetching(false))
            }
            dispatch(toggleNeedToLoad(false))
        }
    }
};

export const getMoreAnswers = (id, page) => {
    return async (dispatch) => {
        dispatch(toggleMoreLoading(true))
        try {
            let response = await otvechaiMiniApi.getQuestion(id, page, true);
            if(response.status===200) {
                dispatch(addAnswers(response.data.answers));
                dispatch(toggleHasMore(response.data.hasMore));
            }
            dispatch(toggleMoreLoading(false))
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleMoreLoading(false));
        }
    }
}

export const editPost = (type,id,data) => {
    return async (dispatch) => {
        dispatch(toggleIsFetching(true));
        try{
            let response
            if(type==='question')
                response = await otvechaiMiniApi.editQuest(id,data);
            else if(type==='answer')
                response = await otvechaiMiniApi.editAnswer(id,data);
            else if(type==='comment')
                response = await otvechaiMiniApi.editComment(id,data);
            if(response.status===200){
                if(type==='question') dispatch(editQuest(response.data.id,response.data.body,response.data.tags))
                else if(type==='answer') dispatch(editAnswer(response.data.id,response.data.body))
                else if(type==='comment') dispatch(editComment(response.data.id,response.data.body))
            }
            else dispatch(setBackendError(response.status))
            dispatch(toggleIsFetching(false))
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleIsFetching(false))
        }
    }
};

export const sendAnswer = (id,answer) => {
    return async (dispatch) => {
        dispatch(toggleInProgress(true));
        let response = await otvechaiMiniApi.sendAnswer(id,answer);
        if(response.data.status===0) {
            dispatch(toggleInProgress(false));
        }

    }
}

export const getMoreComments = (id, page,reverse=null) => {
    return async (dispatch) => {
        dispatch(toggleMoreLoading(true))
        try {
            let response = await otvechaiMiniApi.getAnswer(id, page, true,reverse);
            if(response.status===200) {
                dispatch(addComments(response.data.comments,reverse));
                dispatch(toggleHasMore(response.data.hasMore));
            }
            dispatch(toggleMoreLoading(false));
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleMoreLoading(false));
        }
    }
}

export const sendComment = (id,comment,skip=null) => {
    return async (dispatch) => {
        dispatch(toggleInProgress(true));
        let response = await otvechaiMiniApi.sendComment(id,comment,skip);
        if(response.data.status===0) {
            dispatch(toggleInProgress(false));
        }

    }
}

export const likeQuest = (id) => {
    return async (dispatch) => {
        dispatch(toggleInProgress(true));
        dispatch(toggleQuestLike())
        try {
            let response = await otvechaiMiniApi.likeQuestion(id);
            if (response.data.status === 0) {
                dispatch(setQuestLiked(response.data.liked));
                dispatch(setQuestLikes(response.data.likes));
            } else {
                dispatch(toggleQuestLike())
            }
            dispatch(toggleInProgress(false));
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleInProgress(false));
        }

    }
}

export const likeAnswer = (id,single=true) => {
    return async (dispatch) => {
        dispatch(toggleInProgress(true));
        if(single) dispatch(toggleAnswerLike())
        else dispatch(toggleAnswersLike(id))
        try {
            let response = await otvechaiMiniApi.likeAnswer(id);
            if (response.data.status === 0) {
                if (single) {
                    dispatch(setAnswerLiked(response.data.liked));
                    dispatch(setAnswerLikes(response.data.likes));
                } else {
                    dispatch(setAnswersLiked(id, response.data.liked));
                    dispatch(setAnswersLikes(id, response.data.likes));
                }
                dispatch(toggleInProgress(false));
            }
        }
    catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleInProgress(false));
        }
    }
}

export const likeComments = (id) => {
    return async (dispatch) => {
        dispatch(toggleInProgress(true));
        dispatch(toggleCommentsLike(id))
        try {
            let response = await otvechaiMiniApi.likeComment(id);
            if (response.data.status === 0) {
                dispatch(setCommentsLiked(id, response.data.liked));
                dispatch(setCommentsLikes(id, response.data.likes));
                dispatch(toggleInProgress(false));
            } else {
                dispatch(toggleInProgress(false));
                dispatch(toggleCommentsLike(id));
            }
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleInProgress(false));
        }
    }
}

export const removePost = (id,type,needDispatch=false) =>{
    return async (dispatch) => {
        dispatch(toggleInProgress(true));
        try {
            let response = await otvechaiMiniApi.removePost(id, type)
            if (response.data.status === 0) {
                if (needDispatch) {
                    if (type === 'answer') dispatch(setAnswerRemoved(id))
                    else if (type === 'comment') dispatch(setCommentRemoved(id))
                }
            }
            dispatch(toggleInProgress(false));
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleInProgress(false));
        }
    }
}

export const sendSub = (id,type) =>{
    return async (dispatch) =>{
        dispatch(toggleInProgress(true));
        try {
            let response = await otvechaiMiniApi.subPost(id, type)
            if (response.data.status === 0) {
                dispatch(togglePostSubscription(id, response.data.subscribed, type))
                dispatch(toggleInProgress(false));
            }
        }
        catch (e) {
            if (e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleInProgress(false));
        }

    }
}

export const sendReport = (id,type) =>{
    return async (dispatch) =>{
        dispatch(toggleInProgress(true));
        try {
            let response = await otvechaiMiniApi.reportPost(id, type)
            dispatch(toggleInProgress(false));
            return (response.data.status)
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleInProgress(false));
            return (1)
        }
    }
}

export const setGl = (id,gl) => {
    return async (dispatch) =>{
        dispatch(toggleInProgress(true));
        try {
            let response
            if(gl) response = await otvechaiMiniApi.removeGreylist(id);
            else response = await otvechaiMiniApi.addGreylist(id);
            if(response.status===200) {
                dispatch(setAuthorGl(response.data.in_greylist));
            }
            dispatch(toggleInProgress(false));
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleInProgress(false));
        }

    }
}

export const getNotes = (type) => {
    return async (dispatch) => {
        dispatch(toggleIsFetching(true));
        try {
            let response
            if (type === '/') response = await otvechaiMiniApi.getRootNote();
            else if(type === 's') response = await otvechaiMiniApi.getStreamNote();
            else if (type==='a') response = await otvechaiMiniApi.getAnswerNote();
            else if (type==='c') response = await otvechaiMiniApi.getCommentNote();
            const types = {
                answers:['f','a','c'],
                userStats:['sf','sa','sc'],
                stats:{'sf':'answers','sa':'answers','sc':'comments'}
            }
            if(response.status===200) {
                if (response.data.status === 0) {
                    if (types.answers.includes(response.data.type)) {
                        dispatch(setActivePanel('question'))
                        dispatch(setQuest(response.data.quest));
                        dispatch(setAnswer(response.data.answer));
                        dispatch(setNoteType(response.data.answer.id,response.data.type));
                        dispatch(setComments(response.data.comments));
                        dispatch(toggleHasMore(response.data.hasMore));
                    }  else if (types.userStats.includes(response.data.type)) {
                        dispatch(toggleStatsFromNotes(true))
                        dispatch(setActivePanel('stats'))
                        dispatch(setStatsType(types.stats[response.data.type]));
                        if (response.data.type === 'sc') dispatch(setUserComments(response.data['comments']))
                        else dispatch(setUserAnswers(response.data['answers']));
                        dispatch(setUserName(response.data.user_name));
                        dispatch(toggleHasMore(response.data.hasMore));
                        dispatch(setSubStatType(true))
                    }  else if (response.data.type === 'rq') {
                        dispatch(setActivePanel('question'))
                        dispatch(setQuest(response.data.quest));
                        dispatch(setAnswers([]));
                    }
                    dispatch(toggleIsFetching(false));
                } else {
                    dispatch(toggleIsFetching(false))
                    dispatch(setBackendError(response.data.status))
                }
            }
        }
        catch (e) {
            dispatch(setBackendError(1))
            dispatch(toggleIsFetching(false));
        }
    }
}

export const deletePostImage = (type,id) => {
    return async (dispatch) => {
        let response = await otvechaiMiniApi.deleteImage(type,id);
        if(response.status===200){
            dispatch(setPostImage(type,response.data.id,response.data.image,response.data.image_processing))
            return 0
        }
    }
}

export const getPostImage = (type,id) => {
    return async (dispatch) => {
        let response = await otvechaiMiniApi.checkUpload(type,id);
        if(response.status===200&&response.data.image_processing===false){
            dispatch(setPostImage(type,response.data.id,response.data.image,response.data.image_processing))
        }
    }
}

export const setVoteOption = (poll, id) => {
    return {
        ...poll,
        voted: true,
        votes_total: poll.votes_total + 1,
        options: poll.options.map(a => {
            if (id == a.id) {
                return { ...a, votes: a.votes + 1, voted: id == a.id }
            }
            return a
        })
    }
}

const questionReducer = (state=initialState,action) => {
    switch (action.type) {
        case TOGGLE_NEED_TO_LOAD:
            return {
                ...state,
                needToLoad: action.status
            };
        case LOAD_RANDOM_QUEST:
            return {
                ...state,
                random: true,
                id:null,
                answerId:null,
                needToLoad: action.needToLoad,
                noteType: null
            };
        case LOAD_QUEST:
            return {
                ...state,
                id:action.id,
                random: false,
                answerId:null,
                needToLoad: true,
                noteType: null
            };
        case LOAD_ANSWER:
            return {
                ...state,
                id:null,
                random: false,
                answerId:action.id,
                needToLoad: true,
                noteType: null
            };
        case SET_NOTE_TYPE:
            return{
              ...state,
                id:null,
                answerId: action.id,
                noteType: action.noteType,
                random: false
            };
        case SET_QUEST:
            return{
                ...state,
                quest: action.quest
            };
        case SET_ANSWER:
            return{
                ...state,
                answer: action.answer
            };
        case SET_ANSWERS:
            return{
                ...state,
                answers: action.answers
            };
        case ADD_ANSWERS:
            return{
                ...state,
                answers: state.answers.concat(action.answers)
            };
        case SET_COMMENTS:
            return{
                ...state,
                comments: action.comments
            };
        case ADD_COMMENTS:
            if (action.reverse)
            {
                return {
                    ...state,
                    comments: action.comments.concat(state.comments)
                }
            };
            return{
                ...state,
                comments: state.comments.concat(action.comments)
            };
        case SET_TAGS:
            return{
                ...state,
                tags: action.tags
            };
        case SET_QUEST_LIKED:
            return{
                ...state,
                quest:{
                    ...state.quest,
                    liked:action.liked
                }
            };
        case SET_QUEST_LIKES:
            return{
                ...state,
                quest:{
                    ...state.quest,
                    likes:action.likes
                }
            };
        case TOGGLE_QUEST_LIKE:
            if(state.quest.liked){
                return{
                    ...state,
                    quest:{
                        ...state.quest,
                        liked:!state.quest.liked,
                        likes:state.quest.likes-1
                    }
                }
            }
            return{
                ...state,
                quest:{
                    ...state.quest,
                    liked:!state.quest.liked,
                    likes:state.quest.likes+1
                }
            };
        case SET_ANSWER_LIKED:
            return{
                ...state,
                answer:{
                    ...state.answer,
                    liked:action.liked
                }
            };
        case SET_ANSWER_LIKES:
            return{
                ...state,
                answer:{
                    ...state.answer,
                    likes:action.likes
                }
            };
        case TOGGLE_ANSWER_LIKE:
            if(state.answer.liked){
                return{
                    ...state,
                    answer:{
                        ...state.answer,
                        liked:!state.answer.liked,
                        likes:state.answer.likes-1
                    }
                }
            }
            return{
                ...state,
                answer:{
                    ...state.answer,
                    liked:!state.answer.liked,
                    likes:state.answer.likes+1
                }
            };
        case SET_ANSWERS_LIKED:
            return {
                ...state,
                answers:state.answers.map(a => {
                    if (a.id === action.id){
                        return{...a, liked:action.liked}
                    }
                    return a
                })
            };
        case SET_ANSWERS_LIKES:
            return {
                ...state,
                answers:state.answers.map(a => {
                    if (a.id === action.id){
                        return{...a, likes:action.likes}
                    }
                    return a
                })
            };
        case TOGGLE_ANSWERS_LIKE:
            return {
                ...state,
                answers:state.answers.map(a => {
                    if (a.id === action.id){
                        if(a.liked){
                            return{
                                ...a,
                                liked:!a.liked,
                                likes:a.likes-1
                            }
                        }
                        return{
                            ...a,
                            liked:!a.liked,
                            likes:a.likes+1
                        };
                    }
                    return a
                })
            };
        case SET_COMMENTS_LIKED:
            return {
                ...state,
                comments:state.comments.map(a => {
                    if (a.id === action.id){
                        return{...a, liked:action.liked}
                    }
                    return a
                })
            };
        case SET_COMMENTS_LIKES:
            return {
                ...state,
                comments:state.comments.map(a => {
                    if (a.id === action.id){
                        return{...a, likes:action.likes}
                    }
                    return a
                })
            };
        case TOGGLE_COMMENTS_LIKE:
            return {
                ...state,
                comments:state.comments.map(a => {
                    if (a.id === action.id){
                        if(a.liked){
                            return{
                                ...a,
                                liked:!a.liked,
                                likes:a.likes-1
                            }
                        }
                        return{
                            ...a,
                            liked:!a.liked,
                            likes:a.likes+1
                        };
                    }
                    return a
                })
            };
        case SET_ANSWER_REMOVED:
            return {
                ...state,
                answers:state.answers.filter(function(value, index, arr){ return value.id !==action.id;})
            };
        case SET_COMMENT_REMOVED:
            return {
                ...state,
                comments:state.comments.filter(function(value, index, arr){ return value.id !==action.id;})
            };
        case TOGGLE_POST_SUBSCRIPTION:
            if(action.objType==='question')
                return{
                    ...state,
                    quest:{...state.quest,subscribed:action.sub}
                }
            else if(action.objType==='answer')
                return {
                    ...state,
                    answer: {...state.answer,subscribed:action.sub}
                }
            else if(action.objType==='answers')
                return{
                    ...state,
                    answers:state.answers.map(a => {
                        if (a.id === action.id){
                            return {
                                ...a,
                                subscribed: action.sub
                            }
                        }
                        return a
                    })
                };
        case SET_AUTHOR_GL:
            return {
                ...state,
                quest: {...state.quest, author_gl: action.gl}
            };
        case SET_EDIT_POST:
            return{
                ...state,
                edit:{
                    type:action.postType,
                    id:action.id,
                    body:action.body,
                    tags:action.tags,
                    image:action.image,
                    image_processing: action.image_processing
                }
            };
        case EDIT_QUEST:
            if(state.quest.id===action.id)
            return{
                ...state,
                quest:{
                    ...state.quest,
                    body:action.body,
                    tags:action.tags
                }
            };
        case EDIT_ANSWER:
            if(state.answer.id===action.id)
                return{
                    ...state,
                    answer:{
                        ...state.answer,
                        body:action.body,
                    },
                    answers:state.answers.map(a => {
                        if (a.id === action.id){
                            return{...a, body:action.body}
                        }
                        return a
                    })
                };
            else
                return{
                    ...state,
                    answers:state.answers.map(a => {
                        if (a.id === action.id){
                            return{...a, body:action.body}
                        }
                        return a
                    })

                };
        case EDIT_COMMENT:
            return{
                ...state,
                comments:state.comments.map(a => {
                        if (a.id === action.id){
                            return{...a, body:action.body}
                        }
                        return a
                    })

                };
        case SET_POST_IMAGE:
            if(action.postType==='q')
                return{
                    ...state,
                    edit:{...state.edit,image:action.image,image_processing: action.image_processing},
                    quest: action.id===state.quest.id?{...state.quest, image:action.image, image_processing: action.image_processing}:state.quest
                }
            else if (action.postType==='a')
                return{
                    ...state,
                    edit:{...state.edit,image:action.image,image_processing: action.image_processing},
                    answer:action.id===state.answer.id?{...state.answer, image:action.image, image_processing: action.image_processing}:state.answer,
                    answers:state.answers.map(a => {
                        if (a.id === action.id){
                            return{...a, image:action.image, image_processing: action.image_processing}
                        }
                        return a
                    })
                }
            else if (action.postType==='c')
                return{
                    ...state,
                    edit:{...state.edit,image:action.image,image_processing: action.image_processing},
                    comments:state.comments.map(a => {
                        if (a.id === action.id){
                            return{...a, image:action.image, image_processing: action.image_processing}
                        }
                        return a
                    })
                }
        case SET_QUESTION_ADDED:
            return{
                ...state,
                questionAdded:action.questId};

        case SET_POLL_VOTED:
            if (state.quest.poll) {
                return {
                    ...state,
                    quest: {
                        ...state.quest,
                        poll: setVoteOption(state.quest.poll,action.id)
                    }
                };
            }
            
        return state

        default:
            return state
    }
}




export default questionReducer