import {useEffect, useState} from "react";

const TimeToNextDay = () => {

    const getNextday = () => {
        let nextMidnight = new Date();
        nextMidnight.setUTCHours(24,0,0,0);
        let now = new Date();
        let rest = (nextMidnight.getTime() - now.getTime())/1000;
        const hours = Math.floor(rest/3600);
        rest = rest-(hours*3600);
        const minutes = Math.floor(rest/60);
        rest = rest-(minutes*60);
        const seconds = Math.floor(rest);
        return(hours + ':' +  ("0" + minutes).slice(-2) + ':' + ("0" + seconds).slice(-2))
    }


    const [nexDay, setNextDay] = useState(getNextday());

    useEffect(()=>{
        const interval = setInterval(()=>{setNextDay(getNextday())},1000)
        return () => {
            clearInterval(interval)
        }
    },[]);

    return nexDay
}

export default TimeToNextDay