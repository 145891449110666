import React from "react";
import {Panel, PanelHeader, Div, Button} from "@vkontakte/vkui";
import {useDispatch, useSelector} from "react-redux";
import {restoreProfile} from "../Redux/settingsReducer";


const BannedDeletedPanel = (props) => {
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch()
    let header
    switch (auth.userStatus) {
        case 101:
            header = 'Вы забанены'
            break
        case 103:
            header = 'Вы удалили профиль'
            break
        default:
            break
    }

    return(
        <Panel id={props.id}>
            <PanelHeader>{header}</PanelHeader>
            {auth.userStatus === 103 &&
            <Div>
                <Div style={{fontWeight:"bold",textAlign:"center",marginTop:10,fontSize:36}}>Вы удалили свой профиль.</Div>
                <Div style={{textAlign:"center",marginTop:10,fontSize:18}}>Восстановите профиль, чтобы продолжить пользоваться сервисом.</Div>
                <Button stretched={true} size={'l'} mode={'commerce'} onClick={()=>{dispatch(restoreProfile())}}>Восстановить</Button>
            </Div>
            }
            {auth.userStatus === 101 &&
            <Div>
                <Div style={{fontWeight:"bold",textAlign:"center",marginTop:10,fontSize:36}}>Ваш профиль заблокирован</Div>
                {auth.banReason && <Div style={{textAlign:"center",marginTop:10,fontSize:18}}>Причина блокировки: {auth.banReason}</Div>}
            </Div>
            }
        </Panel>
    )
}


export default BannedDeletedPanel