import React from "react";
import {
    Avatar,
    Cell, Counter,
    Group,
    MiniInfoCell,
    ModalPage,
    ModalPageHeader,
    PanelHeaderButton,
    PanelHeaderContent
} from "@vkontakte/vkui";
import {
    Icon24Cancel,
    Icon28HomeOutline,
    Icon20QuestionCircleFillGreen,
    Icon28SettingsOutline,
    Icon28HelpOutline,
    Icon28MessageOutline,
    Icon24CommentOutline,
    Icon28UsersOutline,
    Icon28UserStarBadgeOutline,
    Icon24InfoCircleOutline,
    Icon20ErrorCircleOutline,
    Icon28HeadphonesOutline, Icon16LockOutline, Icon28AchievementCircleFillBlue,Icon28MailCircleFillBlue,Icon28LightbulbCircleFillYellow
} from "@vkontakte/icons";
import {useDispatch, useSelector} from "react-redux";
import {loadProfile} from "../Redux/profileReducer";
import {checkDev} from "../utils/helpers";
import {setActiveModal, setActivePanel, toggleShowGuide} from "../Redux/appReducer";
import {setStatsType, setUserName, toggleStatsFromNotes} from "../Redux/userPostsReducer";
import {setPmCurrentUser} from "../Redux/pmReducer";
import {setStaticPage} from "../Redux/staticReducer";


function ModalMenu(props){
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const pm = useSelector(state => state.notifs.notifs.pm);

    return(
        <ModalPage id={props.id}
                   dynamicContentHeight
                   header={
                       <ModalPageHeader
                           right={<PanelHeaderButton onClick={props.closeModal}><Icon24Cancel/></PanelHeaderButton>}
                       >
                           <PanelHeaderContent
                               before={<Avatar size={36} src={checkDev(auth.user.avatar_small)} />}
                           >
                               {auth.user.name}
                           </PanelHeaderContent>
                       </ModalPageHeader>
                   }
        >
            <Group>
                <Cell
                    before={<Icon28HomeOutline/>}
                    onClick={()=>{
                        dispatch(loadProfile(auth.user.username));dispatch(setActivePanel('profile'));props.closeModal()
                    }}
                >
                    Моя страница
                </Cell>

                <Cell
                    indicator={pm > 0 && <Counter size="s" mode="prominent" aria-label="Уведомлений: ">{pm}</Counter>}
                    before={<Icon28MailCircleFillBlue
                        width={28} height={28}/>}
                    onClick={()=>{
                        dispatch(setActivePanel('chats'))
                        props.closeModal()}}
                >
                    Сообщения
                </Cell>

                <Cell
                    before={<Icon20QuestionCircleFillGreen width={28} height={28}/>}
                    onClick={()=>{
                        dispatch(toggleStatsFromNotes(false))
                        dispatch(setStatsType('top'))
                        dispatch(setActivePanel('stats'))
                        props.closeModal()}}
                >
                    Топ 10 вопросов
                </Cell>
                <Cell
                    before={<Icon28AchievementCircleFillBlue width={28} height={28}/>}
                    onClick={()=>{
                        dispatch(setActivePanel('leaders'))
                        props.closeModal()}}
                >
                    Лидеры
                </Cell>
                <Cell
                    before={<Icon28SettingsOutline/>}
                    onClick={()=>{
                        dispatch(setActivePanel("settings"))
                        props.closeModal()}}
                >
                    Настройки
                </Cell>
            </Group>
            <Group>
                <Cell
                    before={<Icon28HelpOutline/>}
                    onClick={()=>{
                        dispatch(toggleStatsFromNotes(false))
                        dispatch(setUserName(auth.user.username))
                        dispatch(setStatsType('quests'))
                        dispatch(setActivePanel('stats'))
                        props.closeModal()}}
                >
                    Мои вопросы
                </Cell>
                <Cell
                    before={<Icon28MessageOutline/>}
                    onClick={()=>{
                        dispatch(toggleStatsFromNotes(false))
                        dispatch(setUserName(auth.user.username))
                        dispatch(setStatsType('answers'))
                        dispatch(setActivePanel('stats'))
                        props.closeModal()}}
                >
                    Мои ответы
                </Cell>
                <Cell
                    before={<Icon24CommentOutline height={28} width={28}/>}
                    onClick={()=>{
                        dispatch(toggleStatsFromNotes(false))
                        dispatch(setUserName(auth.user.username))
                        dispatch(setStatsType('comments'))
                        dispatch(setActivePanel('stats'))
                        props.closeModal()}}
                >
                    Мои комментарии
                </Cell>
                <Cell
                    before={<Icon28UserStarBadgeOutline/>}
                    onClick={()=>{
                        dispatch(setActiveModal('friends'))}
                    }
                >
                    Мои друзья
                </Cell>
                <Cell
                    before={<Icon28UsersOutline/>}
                    onClick={()=>{
                        dispatch(setActiveModal('subs'))}
                    }
                >
                    Мои подписчики
                </Cell>
            </Group>
            <Group>
                <MiniInfoCell
                    before={<Icon24InfoCircleOutline width={20} height={20}/>}
                    textWrap="full"
                    textLevel="primary"
                    onClick={()=>{
                        dispatch(setStaticPage('about'))
                        dispatch(setActivePanel('static'));
                        props.closeModal()}}
                >
                    О сервисе
                </MiniInfoCell>
                <MiniInfoCell
                    before={<Icon20ErrorCircleOutline/>}
                    textWrap="full"
                    textLevel="primary"
                    onClick={()=>{
                        dispatch(setStaticPage('rules'))
                        dispatch(setActivePanel('static'));
                        props.closeModal()}}
                >
                    Правила
                </MiniInfoCell>
                <MiniInfoCell
                    before={<Icon16LockOutline height={20} width={20}/>}
                    textWrap="full"
                    textLevel="primary"
                    onClick={()=>{
                        dispatch(setStaticPage('privacy'))
                        dispatch(setActivePanel('static'));
                        props.closeModal()}}
                >
                    Приватность
                </MiniInfoCell>
                <MiniInfoCell
                    before={<Icon28HeadphonesOutline height={20} width={20}/>}
                    textWrap="full"
                    textLevel="primary"
                    onClick={()=>{
                        dispatch(setPmCurrentUser('moderator'));
                        dispatch(setActivePanel('chat'));
                        props.closeModal()}}
                >
                    Поддержка
                </MiniInfoCell>
                <MiniInfoCell
                    before={<Icon28LightbulbCircleFillYellow height={20} width={20}/>}
                    textWrap="full"
                    textLevel="primary"
                    onClick={()=>{
                        dispatch(setActivePanel('question'))
                        dispatch(toggleShowGuide(true))
                        props.closeModal()}}
                >
                    Подсказки
                </MiniInfoCell>
            </Group>
        </ModalPage>
    )
}

export default ModalMenu