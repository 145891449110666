import React, {useState} from "react";
import {Alert, Button, Cell, Div, Group, MiniInfoCell} from "@vkontakte/vkui";
import {useDispatch, useSelector} from "react-redux";
import {deleteProfile, updateAnket} from "../Redux/settingsReducer";
import ProfileForm from "../Forms/ProfileForm";
import {setActivePanel} from "../Redux/appReducer";
import {loadRandomQuest} from "../Redux/questionReducer";
import { Icon20UserSquareOutline,Icon28MailCircleFillGray } from '@vkontakte/icons';


const EditProfile = (props) => {
    const dispatch=useDispatch()

    const user = useSelector(state => state.auth.user);
    const new_email = useSelector(state => state.auth.new_email);
    const [file, setFile] = useState({data:{},dataUrl:null});
    const [crop, setCrop] = useState({ unit: 'px', keepSelection:true, width: 200, aspect: 1 });
    const [cropPercent, setCropPercent] = useState({});

    const encodeGender = (gender) => {
        switch (gender){
            case true:
                return '0'
            case false:
                return '1'
            default:
                return '2'
        }
    }

    const decodeGender = (gender) => {
        switch (gender){
            case '0':
                return true
            case '1':
                return false
            default:
                return null
        }
    }

    const submitProfile = (data) => {
        let formData = new FormData();
        formData.append('name', data.name)
        formData.append('gender', decodeGender(data.gender))
        formData.append('birthday', data.birthday)
        formData.append('location', data.location)
        if(file&&file.data&&crop) {
            formData.append('avatar', file.data)
            formData.append('crop', JSON.stringify(cropPercent))
        }
        dispatch(updateAnket(formData)).then(()=>{props.setShowSnackbar(true)})
    }

    return(
        <Div>
            <Group>
                <MiniInfoCell
                    before={<Icon20UserSquareOutline />}
                    textWrap={"short"}
                    textLevel={"primary"}
                >
                    <span style={{fontWeight:500}}>Имя пользователя:</span> {user.username}
                </MiniInfoCell>
                <MiniInfoCell
                    before={<Icon28MailCircleFillGray width={20} height={20} />}
                    textWrap={"short"}
                    textLevel={"primary"}
                >
                    <span style={{fontWeight:500}}>Email:</span> {new_email?new_email:user.email}
                </MiniInfoCell>
            </Group>

            <ProfileForm
                initialValues={{
                    name: props.anket.info.name,
                    gender: encodeGender(props.anket.info.gender),
                    birthday: props.anket.info.birthday,
                    location: props.anket.info.location
                }}
                onSubmit={submitProfile}
                onCancel={()=>{
                    dispatch(setActivePanel('question'));
                    dispatch(loadRandomQuest())
                }}
                formDisabled={props.profileUpdating||(file.data&&file.data.size/1024/1024>4)}
                file={file}
                setFile={setFile}
                crop={crop}
                setCrop={setCrop}
                avatarSmall={props.anket.avatar.avatar_small}
                avatarBig={props.anket.avatar.avatar_big}
                setCropPercent={setCropPercent}
            />

            <Group>
                <Cell
                    multiline={true}
                    disabled={true}
                    description={'После удаления профиля Ваше имя и аватар перестанут отображаться на сайте. Вопросы, ответы и комментарии останутся, но будут обезличены. Если у Вас есть блог - он также станет недоступен для других пользователей. Восстановить профиль и блог можно в любой момент.'}
                    after={
                        <Button
                            onClick={() => {
                                props.setPopout(
                                    <Alert
                                        actions={[{
                                            title: 'Отмена',
                                            autoclose: true,
                                            mode: 'cancel'
                                        }, {
                                            title: 'Удалить',
                                            autoclose: true,
                                            mode: 'destructive',
                                            action: () =>  dispatch(deleteProfile())
                                        }]}
                                        actionsLayout="horizontal"
                                        onClose={()=>{props.setPopout(null)}}
                                        header={`Удалить профиль?`}
                                        text={`Вы уверены, что хотите удалить свой профиль?`}
                                    />
                                )
                            }}
                            mode={'destructive'}> Удалить профиль </Button>}
                />
            </Group>
        </Div>
    )
}

export default EditProfile