import React, {useEffect, useState} from "react";
import {Button, Group, Panel, PanelHeader, PanelSpinner, Spinner} from "@vkontakte/vkui";
import BottomNav from "../Nav/BottomNav";
import {useDispatch, useSelector} from "react-redux";
import {getMoreStats, getUserStats} from "../Redux/userPostsReducer";
import Post from "../QuestionPanel/Post";
import Style from "../QuestionPanel/Question.module.css";
import {historyPush, toggleDoNotAdd} from "../Redux/routerReducer";
import TopNav from "../Nav/TopNav";
import SiteHead from "../Shared/SiteHead";

function UserStatsPanel(props){
    const app = useSelector(state => state.app);
    const auth = useSelector(state => state.auth);
    const userPosts = useSelector(state => state.userPosts);
    const dispatch = useDispatch()
    const [page,setPage] = useState(2)

    const doNotAdd = useSelector(state => state.router.doNotAdd);

    const getMore = () =>{
        dispatch(getMoreStats(userPosts.username,userPosts.type,userPosts.sub,page)).then(()=>{setPage(prev=>prev+1)})
    }


    let content
    if(['quests','top'].includes(userPosts.type))
    content = userPosts.quests.map((el)=>(
        <Post
            key={el.id}
            post={el}
            isFetching={app.isFetching}
            inProgress={app.inProgress}
            type={'question'}
            size={60}
            needDispatch={true}
            stats={userPosts.type}
        />
    ))

    else if(userPosts.type==='answers')
        content = userPosts.answers.map((el)=>(
            <div key={el.id}>
            <Post
                post={el.quest}
                isFetching={app.isFetching}
                inProgress={app.inProgress}
                type={'question'}
                size={60}
                needDispatch={true}
                stats={userPosts.type}
            />
                <Group className={Style.answer}>
                <Post
                    post={el}
                    token={auth.token.access_token}
                    isFetching={app.isFetching}
                    inProgress={app.inProgress}
                    type={'answer'}
                    size={50}
                    needDispatch={true}
                    stats={userPosts.type}
                />
                </Group>
            </div>
        ))

    else if(userPosts.type==='comments')
        content = userPosts.comments.map((el)=>(
            <div key={el.id}>
                <Post
                    post={el.answer.quest}
                    isFetching={app.isFetching}
                    inProgress={app.inProgress}
                    type={'question'}
                    size={60}
                    needDispatch={true}
                    stats={userPosts.type}
                />
                <Group className={Style.answer}>
                    <Post
                        post={el.answer}
                        isFetching={app.isFetching}
                        inProgress={app.inProgress}
                        type={'answer'}
                        size={50}
                        needDispatch={true}
                        stats={userPosts.type}
                    />
                </Group>
                <Group className={Style.comment}>
                    <Post
                        post={el}
                        isFetching={app.isFetching}
                        inProgress={app.inProgress}
                        type={'comment'}
                        size={40}
                        needDispatch={true}
                        stats={userPosts.type}
                    />
                </Group>
            </div>
        ))

    useEffect(() => {
        if(!userPosts.fromNotes) dispatch(getUserStats(userPosts.username, userPosts.type,userPosts.myStats))

    }, [userPosts.username,userPosts.type]);

    //router
    useEffect(()=>{
        if(!doNotAdd){
            if (userPosts.username && userPosts.type) dispatch(historyPush({
                panel: 'stats',
                type:userPosts.type,
                id: userPosts.fromNotes? 'myStats':userPosts.username
            }))
        }
        dispatch(toggleDoNotAdd(false))
    },[userPosts.username,userPosts.type]);


    return(
        <Panel id={props.id}>
            <PanelHeader
                left={<TopNav/>}
            />
            <SiteHead/>
            {app.isFetching?<PanelSpinner/>:<>
            {content}

            {(!app.isFetching && app.hasMore) &&
            <Group className={Style.loadMoreContainer}>
                <Button mode={'secondary'}
                        onClick={getMore}
                        disabled={app.moreLoading}
                >
                    {app.moreLoading?<Spinner/>:'Загрузить ещё'}
                </Button>
            </Group>
            }
            </>}
            <div className={'lastElement'}/>
            <BottomNav/>
        </Panel>
    )
};

export default UserStatsPanel