import React from "react";
import { Form, Field } from "react-final-form";
import {FormLayout} from "@vkontakte/vkui";
import {required, sendByKey} from "./Validators";
import {FinalFormChatField} from "./FormControls";
import {useSelector} from "react-redux";


const ChatForm = (props) => {

    const settings = useSelector(state => state.auth.settings);

    const handleKeyDown = (e,submit) => {
        if (!props.meTyping)
            props.toggleMeTyping(true)
        if(!settings.disable_enter) sendByKey(e,submit)
    }

    return(
        <Form
            onSubmit={(val,form)=>{props.onSubmit(val);setTimeout(form.reset)}}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
                <FormLayout>
                    <Field component={FinalFormChatField}
                           type={"text"}
                           name={'chat'} placeholder={'Сообщение...'}
                           handleSubmit={handleSubmit}
                           validate={required}
                           scheme={props.scheme}
                           //disabled={app.inProgress}
                           onKeyDown={(e)=>{
                               handleKeyDown(e,handleSubmit)
                           }}
                    />
                </FormLayout>
            )}
        />
    )
}

export default ChatForm