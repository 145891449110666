import React from "react";
import {Cell, Group, Header, List, ModalPage, ModalPageHeader, PanelHeaderButton} from "@vkontakte/vkui";
import {Icon24Cancel} from "@vkontakte/icons";
import {useSelector} from "react-redux";
import { Icon24SkullOutline } from '@vkontakte/icons';


function ModalWarnings(props){
    const profile = useSelector(state => state.profile);
    const warnings = profile.data.warnings.map((el)=>(
        <Cell
            key={el.id}
            before={<Icon24SkullOutline/>}
            disabled
            multiline
        >
            {el.warning}
        </Cell>
    ))
    return(
        <ModalPage id={props.id}
                   dynamicContentHeight
                   header={
                       <ModalPageHeader
                           right={<PanelHeaderButton onClick={props.closeModal}><Icon24Cancel/></PanelHeaderButton>}
                       > Предупреждения
                       </ModalPageHeader>
                   }
        >
            <Group header={<Header mode="secondary">Ваши предупреждения</Header>}>
                <List>
                    {warnings}
                </List>
            </Group>

        </ModalPage>
    )
};

export default ModalWarnings