import React from "react";
import {Button, Div, FormItem, FormLayout, } from "@vkontakte/vkui";
import {Field, Form} from "react-final-form";
import {FinalFormCheckbox} from "./FormControls";

const NotesForm = (props) => {

    return(
        <Form
            initialValues={props.initialValues}
            onSubmit={(val,form)=>{props.onSubmit(val);}}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
                <FormLayout>
                    <FormItem>
                        <Field component={FinalFormCheckbox}
                               name={'disable_pm_notifs'}
                               type={"checkbox"}
                        >Отключить письма о новых личных сообщениях</Field>
                    </FormItem>
                    <FormItem>
                        <Field component={FinalFormCheckbox}
                               name={'disable_a_notifs'}
                               type={"checkbox"}
                        >Отключить письма о новых ответах</Field>
                    </FormItem>
                    <FormItem>
                        <Field component={FinalFormCheckbox}
                               name={'disable_c_notifs'}
                               type={"checkbox"}
                        >Отключить письма о новых комментариях</Field>
                    </FormItem>
                    <FormItem
                        bottom={"Новостные рассылки происходят крайне редко. Только очень важные новости."}
                    >
                        <Field component={FinalFormCheckbox}
                               name={'disable_l_notifs'}
                               type={"checkbox"}
                        >Отписаться от новостной рассылки</Field>
                    </FormItem>
                    <Div style={{display: "flex", justifyContent: "space-between"}}>
                        <Button centered onClick={handleSubmit} disabled={props.formDisabled}> Сохранить </Button>
                        <Button centered onClick={props.onCancel}> Отмена </Button>
                    </Div>
                </FormLayout>
            )}
        />
    )
}


export default NotesForm