import {Group,Cell,List,Header,Div} from '@vkontakte/vkui';
import React from "react";
import {useDispatch,useSelector} from 'react-redux';
import { Icon28CheckCircleFill } from '@vkontakte/icons';
import {pollVote} from "../Redux/questionReducer";
import {statsPollVote} from "../Redux/userPostsReducer"

function PollOption(props){
    const dispatch = useDispatch();
    const scheme = useSelector(state => state.app.scheme)
    const votePercent = Math.floor(props.votes_total===0?0:(props.votes/props.votes_total) * 100)
    const vote = (stats) =>{
        return stats?
            dispatch(statsPollVote(props.id,props.type,props.posId,props.stats)):
            dispatch(pollVote(props.id,props.type,props.posId))
    }
    return(
        <>
        <Cell 
            style={{
                boxShadow: `0 0 6px ${['space_gray','vkcom_dark'].includes(scheme)?"rgba( 115, 115, 115, .4 )":"rgba( 178, 178, 178, .4 )"}`, background:`['space_gray','vkcom_dark'].includes(scheme)?"#393e47":"#9CAAC9"`, margin:5
            }}
            after={props.voted?<Icon28CheckCircleFill width={14} height={14} style={{position:"relative", zIndex:10}} />:null}
            before={props.pollVoted?<div style={{marginRight:10, position:"relative", zIndex:10}}>{votePercent}%</div>:null}
            multiline
            disabled={props.pollVoted}
            onClick={
                ()=>{
                    !props.pollVoted&&vote(props.stats)}}
        >   
            <div style={{
                position:"absolute",
                top: 0,
                left:0,
                height:"100%",
                width:`${votePercent}%`,
                background:['space_gray','vkcom_dark'].includes(scheme)? "#4A7E96": "#6bc678"
            }} />
            <div style={{position:"relative",zIndex:10}}>
                {props.name}
            </div>
        </Cell>
        </>
    )
}

function Poll(props){
    let optionsSet = props.poll.options.map((el)=>(
        <PollOption
            pollVoted={props.poll.voted}
            voted={el.voted}
            id={el.id}
            name={el.name}
            votes_total={props.poll.votes_total}
            votes={el.votes}
            type={props.type}
            posId={props.posId}
            stats={props.stats}
         />
    )
    )

    return(
        <Div>
        <Group mode={"card"}>
            <Header multiline>{props.poll.name}</Header>
            <List>
                {optionsSet}
            </List>
            <Header mode="secondary" style={{display:"flex", justifyContent:"right"}}>Проголосовало {props.poll.votes_total}</Header>
        </Group>
        </Div>
    )

}

export default Poll