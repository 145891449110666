import {setBackendError, toggleIsFetching} from "./appReducer";
import otvechaiMiniApi from "../api/otvechaiMiniApi";

const SET_STATS = 'SET_STATS';
const TOGGLE_STATS_IN_PROGRESS = 'TOGGLE_STATS_IN_PROGRESS'
const SET_BROADCAST = 'SET_BROADCAST'
const SET_BROADCAST_DETAILS = 'SET_BROADCAST_DETAILS'

const initialState = {
    statsInProgress:null,
    awards:[],
    broadcast:{
        short: {
            user: null,
            message: null,
            cost: 20
        },
        details:{
            id:null,
            user:{
                id: null,
                username: null,
                name: null,
                avatar_small: null,
                friend: null,
                online: null
            },
            date: null,
            message: null
        }
    }

}


const setStats = (stats) => ({type:SET_STATS, stats});
const toggleStatsInProgress = (progress) => ({type:TOGGLE_STATS_IN_PROGRESS, progress});
export const setBroadcast = (broadcast) => ({type:SET_BROADCAST, broadcast});
const setBroadcastDetails = (broadcast) => ({type:SET_BROADCAST_DETAILS, broadcast});

export const getStats = () => {
    return async (dispatch) => {
        dispatch(toggleStatsInProgress(true))
        try {
            let response = await otvechaiMiniApi.getStats();
            if(response.status===200) {
                dispatch(setStats(response.data.stats))
                //dispatch(setBroadcast(response.data.broadcast))
            }
            dispatch(toggleStatsInProgress(false))
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleStatsInProgress(null))
        }
    }
}

export const getBroadcastDetails = () => {
    return async (dispatch) => {
        dispatch(toggleIsFetching(true))
        try {
            let response = await otvechaiMiniApi.getBroadcastDetails();
            if(response.status===200) {
                dispatch(setBroadcastDetails(response.data.broadcast))
            }
            dispatch(toggleIsFetching(false))
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleIsFetching(false))
        }
    }
}

export const addBroadcast = (message) =>{
    return async  (dispatch) => {
        dispatch(toggleStatsInProgress(true))
        try {
            const response = await otvechaiMiniApi.addBroadcast(message);
            if (response.status===200){
                dispatch(toggleStatsInProgress(false))
                return response.data.status
            }
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleStatsInProgress(null))
            return 1
        }
    }
}


const headerReducer  = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_STATS_IN_PROGRESS:
            return {
                ...state,
                statsInProgress:action.progress
            };
        case SET_STATS:
            return {
                ...state,
                awards: action.stats
            };
        case SET_BROADCAST:
            return {
                ...state,
                broadcast: {...state.broadcast, short:action.broadcast}
            };
        case SET_BROADCAST_DETAILS:
            return {
                ...state,
                broadcast: {...state.broadcast, details:action.broadcast}
            };
        default:
            return state
    }
};


export default headerReducer