
import {
    setBackendError,
    toggleHasMore,
    toggleIsFetching,
    toggleMoreLoading,
} from "./appReducer";
import otvechaiMiniApi from "../api/otvechaiMiniApi";

let initialState = {
    isFetching:null,
    inProgress:null,
    showcase:[],
    showcaseCategory:null,
    categoryId:null,
    opened:false,
    sendSuccess:null,
    sendFailed:null,
    showSelected:null,
    selected:{
        id: null,
        name: null,
        cost: null,
        image: null
    },
    userGifts:{
        title:null,
        presents:[],
        newPresents:[]
    },
    user:{
        username: null,
        name: null,
        avatar: null
    }
};

const TOGGLE_GIFTS_FETCHING = 'TOGGLE_GIFTS_FETCHING';
const TOGGLE_GIFT_IN_PROGRESS = 'TOGGLE_GIFT_IN_PROGRESS';
const SET_GIFTS_SHOWCASE = 'SET_GIFTS_SHOWCASE'
const SET_SHOWCASE_CATEGORY = 'SET_SHOWCASE_CATEGORY'
const SET_SHOWCASE_CATEGORY_ID = 'SET_SHOWCASE_CATEGORY_ID'
const SELECT_GIFT = 'SELECT_GIFT'
const SET_USER_GIFTS = 'SET_USER_GIFTS'
const ADD_USER_GIFTS = 'ADD_USER_GIFTS'
const ADD_GIFT_TO_ACCEPTED = 'ADD_GIFT_TO_ACCEPTED'
const REMOVE_GIFT_FROM_NEW = 'REMOVE_GIFT_FROM_NEW'
const TOGGLE_GIFT_STATUS = 'TOGGLE_GIFT_STATUS'
const TOGGLE_GIFT_SEND_SUCCESS = 'TOGGLE_GIFT_SEND_SUCCESS'
const TOGGLE_GIFT_SEND_FAILED = 'TOGGLE_GIFT_SEND_FAILED'

const CLEAR_GIFTS_STATE = 'CLEAR_GIFTS_STATE'

const SET_GIFT_USER = 'SET_GIFT_USER'

const SHOW_SELECTED_GIFT = 'SHOW_SELECTED_GIFT'


const toggleGiftsFetching = (status) => ({type:TOGGLE_GIFTS_FETCHING, status});
const toggleGiftInProgress = (status) => ({type:TOGGLE_GIFT_IN_PROGRESS, status});
const setGiftsShowcase = (showcase) => ({type:SET_GIFTS_SHOWCASE, showcase});
export const setShowcaseCategory = (category) => ({type:SET_SHOWCASE_CATEGORY, category})
export const setShowcaseCategoryId = (id) => ({type:SET_SHOWCASE_CATEGORY_ID, id})
export const selectGift = (gift) => ({type:SELECT_GIFT,gift})
const setUserGifts = (gifts) => ({type:SET_USER_GIFTS,gifts})
const addUserGifts = (gifts) => ({type:ADD_USER_GIFTS,gifts})
const addGiftToAccepted = (gift) => ({type:ADD_GIFT_TO_ACCEPTED,gift})
const removeGiftFromNew = (id) => ({type:REMOVE_GIFT_FROM_NEW,id})

export const setGiftUser = (user)=> ({type:SET_GIFT_USER,user})

export const toggleGiftShowcase = (status) => ({type:TOGGLE_GIFT_STATUS,status})
export const toggleGiftSendSuccess = (status) => ({type:TOGGLE_GIFT_SEND_SUCCESS,status})
export const toggleGiftSendFailed = (status) => ({type:TOGGLE_GIFT_SEND_FAILED,status})

export const showSelectedGift = (status) => ({type:SHOW_SELECTED_GIFT,status})

export const clearGiftState = () => ({type:CLEAR_GIFTS_STATE})
export const getGiftsShowcase = (category=null) => {
    return async (dispatch) => {
        dispatch(toggleGiftsFetching(true));
        try{
            let response = await otvechaiMiniApi.getGifts(category);
            if (response.data.status===0) {
                dispatch(setShowcaseCategory(response.data.showcaseCategory))
                dispatch(setGiftsShowcase(response.data.gifts));
                dispatch(toggleGiftsFetching(false))
            }


        }
        catch (e) {
            dispatch(toggleGiftsFetching(true))
        }
    }
};

export const getUserGifts = (username) => {
    return async (dispatch) => {
        dispatch(toggleIsFetching(true));
        try{
            let response = await otvechaiMiniApi.getUserGifts(username);
            if (response.data.status===0) {
                dispatch(setUserGifts(response.data))
                dispatch(toggleIsFetching(false));
                dispatch(toggleHasMore(response.data.hasMore))
            }

        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleIsFetching(false))
        }
    }
};



export const sendGift = (username,gift) => {
    return async (dispatch) => {
        dispatch(toggleGiftInProgress(true));
        try{
            let response = await otvechaiMiniApi.userGift(username,gift);
            if (response.status === 200) {
                dispatch(toggleGiftInProgress(false))
                dispatch(toggleGiftShowcase(false))
                dispatch(toggleGiftSendSuccess(true))
                return response.data.status
            }

        }
        catch (e) {
            dispatch(toggleGiftsFetching(true))
            dispatch(toggleGiftInProgress(false))
            dispatch(toggleGiftShowcase(false))
            dispatch(toggleGiftSendFailed(true))
        }
    }
};

export const acceptGift = (id) => {
    return async (dispatch) => {
        dispatch(toggleGiftInProgress(true));
        try{
            let response = await otvechaiMiniApi.acceptGift(id);
            if (response.status === 200) {
                dispatch(addGiftToAccepted(response.data.present))
                dispatch(removeGiftFromNew(id))
                dispatch(toggleGiftInProgress(false))
                return(0)
            }

        }
        catch (e) {
            dispatch(toggleGiftsFetching(true))
            dispatch(toggleGiftInProgress(false))
            return(1)
        }
    }
};

export const removeGift = (id) => {
    return async (dispatch) => {
        dispatch(toggleGiftInProgress(true));
        try{
            let response = await otvechaiMiniApi.deleteGift(id);
            if (response.status === 200) {
                dispatch(removeGiftFromNew(id))
                dispatch(toggleGiftInProgress(false));
            }

        }
        catch (e) {
            dispatch(toggleGiftsFetching(true))
            dispatch(toggleGiftInProgress(false))
        }
    }
};


export const getMoreGifts = (username, page) => {
    return async (dispatch) => {
        dispatch(toggleMoreLoading(true))
        try {
            let response = await otvechaiMiniApi.getUserGifts(username, page);
            if(response.status===200) {
                dispatch(addUserGifts(response.data.presents));
                dispatch(toggleHasMore(response.data.hasMore));
            }
            dispatch(toggleMoreLoading(false));
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleMoreLoading(false));
        }
    }
}


const giftsReducer = (state=initialState,action) => {
    switch (action.type) {
        case TOGGLE_GIFTS_FETCHING:
            return{
                ...state,
                isFetching: action.status
            };
        case TOGGLE_GIFT_IN_PROGRESS:
            return{
                ...state,
                inProgress: action.status
            };
        case SET_SHOWCASE_CATEGORY:
            return{
                ...state,
                showcaseCategory: action.category
            };
        case SET_SHOWCASE_CATEGORY_ID:
            return{
                ...state,
                categoryId: action.id
            };
        case SET_GIFTS_SHOWCASE:
            return{
                ...state,
                showcase: action.showcase
            };
        case SELECT_GIFT:
            return{
                ...state,
                selected: action.gift
            };
        case SET_USER_GIFTS:
            return{
                ...state,
                userGifts: action.gifts
            };
        case ADD_USER_GIFTS:
                return {
                    ...state,
                    userGifts: {...state.userGifts, presents:[...state.userGifts.presents,...action.gifts]}
                };
        case REMOVE_GIFT_FROM_NEW:
            return{
                ...state,
                userGifts:{...state.userGifts, newPresents: state.userGifts.newPresents.filter(el => el.id!==action.id)}
            };
        case ADD_GIFT_TO_ACCEPTED:
            return{
                ...state,
                userGifts:{...state.userGifts, presents: [action.gift, ...state.userGifts.presents]}
            };
        case SET_GIFT_USER:
            return{
                ...state,
                user:action.user
            };
        case TOGGLE_GIFT_STATUS:
            return{
                ...state,
                opened:action.status
            };
        case TOGGLE_GIFT_SEND_SUCCESS:
            return{
                ...state,
                sendSuccess:action.status
            };
        case TOGGLE_GIFT_SEND_FAILED:
            return{
                ...state,
                sendFailed:action.status
            };
        case CLEAR_GIFTS_STATE:
            return{
                ...state,
                sendFailed:null,
                sendSuccess:null,
                opened:false,
                user:{username: null, name: null, avatar: null},
                showSelected: null
            };
        case SHOW_SELECTED_GIFT:
            return{
                ...state,
                showSelected:action.status
            };
        default:
            return state
    }
};

export default giftsReducer