import {Group, MiniInfoCell, Panel, PanelHeader, Title} from "@vkontakte/vkui";
import React from "react";
import BottomNav from "../Nav/BottomNav";
import SendForm from "../Forms/Forms";
import TopNav from "../Nav/TopNav";
import SiteHead from "../Shared/SiteHead";
import {useSelector} from 'react-redux';
import {Icon20FavoriteCircleFillYellow } from "@vkontakte/icons";
import TimeToNextDay from "../Shared/TimeToNextDay";


function AskPanel(props){

    return(
        <Panel id={props.id}>
            <PanelHeader
                left={<TopNav/>}
            />
            {false&&<SiteHead/>}

            <Title level={"2"} weight={"semibold"} style={{ marginBottom: 16, textAlign:"center"}}>Задайте вопрос!</Title>



            <Group>
                <SendForm
                    type={'question'}
                    fieldName={'quest'}
                    placeholder={'Задайте вопрос'}
                    button={'Задать'}
                />
            </Group>

            <div className={'lastElement'}/>
            <BottomNav/>
        </Panel>
    )
}

export default AskPanel