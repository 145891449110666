import React, {useEffect, useState} from "react";
import {Alert, Button, Div, Panel, PanelHeader, Spinner, Title} from "@vkontakte/vkui";
import TopNav from "../Nav/TopNav";
import BottomNav from "../Nav/BottomNav";
import {useDispatch, useSelector} from "react-redux";
import {acceptGift, getMoreGifts, getUserGifts, removeGift, setGiftUser, setShowcaseCategoryId} from "../Redux/giftsReducer";
import {setActiveModal, setActivePanel} from "../Redux/appReducer";
import {loadProfile} from "../Redux/profileReducer";
import Present from "./Present";


const GiftPanel = (props) => {
    const dispatch = useDispatch()
    const presents = useSelector(state => state.gifts)
    const app = useSelector(state => state.app)
    const auth = useSelector(state => state.auth)
    const [page, setPage] = useState(2);

    useEffect(()=>{
        dispatch(getUserGifts(presents.user.username?presents.user.username:auth.user.username));
    },[presents.user.username]);

    const loadMore = (reverse=false) => {
        if(!app.moreLoading) {
            dispatch(getMoreGifts(presents.user.username, page))
            setPage(prev=>prev+1)
        }
    }

    const newPresentsSet = presents.userGifts.newPresents.map((el)=>(
        <div>
            <Present
                loadProfile={()=>{
                    dispatch(setActivePanel('profile'))
                    dispatch(loadProfile(el.presenter.username))
                }}
                avatar={el.presenter.avatar_small}
                date={el.date}
                name={el.presenter.name}
                comment={el.comment}
                gift={el.gift.image}
                private={el.private}
            />
            <div style={{display:"flex",justifyContent:"center",marginTop:5,marginBottom:10}}>
                <Button
                    onClick={()=>{
                        props.setPopout(
                            <Alert
                                actions={[{
                                    title: 'Нет',
                                    autoclose: true,
                                    mode: 'cancel'
                                }, {
                                    title: 'Да',
                                    autoclose: true,
                                    mode: 'destructive',
                                    action: () => dispatch(acceptGift(el.id)),
                                }]}
                                actionsLayout="horizontal"
                                onClose={()=>{props.setPopout(null)}}
                                header={`Принять подарок?`}
                                text={`Принять ${el.presenter.name?`подарок пользователя ${el.presenter.name}`:'анонимный подарок'}?`}
                            />
                        )
                    }}

                    style={{marginRight:25}}>Принять</Button>
                <Button
                    onClick={()=>{
                        props.setPopout(
                            <Alert
                                actions={[{
                                    title: 'Нет',
                                    autoclose: true,
                                    mode: 'cancel'
                                }, {
                                    title: 'Да',
                                    autoclose: true,
                                    mode: 'destructive',
                                    action: () => dispatch(removeGift(el.id)),
                                }]}
                                actionsLayout="horizontal"
                                onClose={()=>{props.setPopout(null)}}
                                header={`Удалить подарок?`}
                                text={`Удалить ${el.presenter.name?`подарок пользователя ${el.presenter.name}`:'анонимный подарок'}?`}
                            />
                        )
                    }}
                    mode={"destructive"}>Удалить</Button>
            </div>
        </div>
    ))

    const presentsSet = presents.userGifts.presents.map((el)=>(
        <div>
            <Present
                loadProfile={()=>{
                    dispatch(setActivePanel('profile'))
                    dispatch(loadProfile(el.presenter.username))
                }}
                avatar={el.presenter.avatar_small}
                date={el.date}
                name={el.presenter.name}
                comment={el.comment}
                gift={el.gift.image}
                private={el.private}
            />
            {auth.user.username === presents.user.username &&
            <div 
            onClick={() => {
                dispatch(setGiftUser({
                    name: el.presenter.name,
                    username: el.presenter.username,
                    avatar: el.presenter.avatar_small
                }));
                dispatch(setShowcaseCategoryId(null))
                dispatch(setActiveModal('giftShowcase'))
            }}
            style={{display: "flex", justifyContent: "center", marginTop: 5, marginBottom: 10}}>
                <Button>Отправить подарок в ответ</Button>
            </div>
            }
        </div>
    ))

    return(
        <Panel id={props.id}>
            <PanelHeader
                left={<TopNav/>}
            />
            {app.isFetching ?
                <Spinner/> :
                <Div>

                    {presents.userGifts.newPresents.length > 0 &&
                    <Title level="2" weight="semibold" style={{marginBottom: 16, textAlign: "center"}}>Новые
                        подарки</Title>}
                    {newPresentsSet}
                    <Title level="2" weight="semibold" style={{marginBottom: 16, textAlign: "center"}}>{(auth.user.username === presents.user.username||!presents.user.username)?'Принятые подарки':`Подарки пользователя ${presents.user.name}`}</Title>
                    {presentsSet}

                    {app.hasMore &&
                    <Div style={{display: "flex", justifyContent: "center"}}><Button mode={"secondary"}
                                                                                     onClick={loadMore}>Загрузить
                        ещё</Button></Div>
                    }
                </Div>
            }

            <div className={'lastElement'}/>
            <BottomNav/>
        </Panel>
    )
}


export default GiftPanel