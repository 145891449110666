import React, {useEffect} from "react";
import {Avatar, Caption, Cell, Div, Gallery, Group} from "@vkontakte/vkui";
import {useDispatch, useSelector} from "react-redux";
import {getStats} from "../Redux/headerReducer";
import {checkDev, declOfNum} from "../utils/helpers";
import {loadQuest} from "../Redux/questionReducer";
import {setActiveModal, setActivePanel} from "../Redux/appReducer";
import {loadProfile} from "../Redux/profileReducer";
import AdManager from "../AdManager/AdManager";
import georg from "../SeasonalAassets/georg-2-50.jpg"


const SiteHead = (props) => {
    const dispatch = useDispatch()
    const header = useSelector(state => state.header);

    useEffect(() => {
        if(header.statsInProgress===null)
            dispatch(getStats())
    }, []);

    const awardsSet = header.awards.map((el,i)=>(
        <Award
            key={`award_${i}`}
            award={el}
        />
    ))

    return(
        <Div>
        <Group mode={'card'}>
            <Gallery
                slideWidth="100%"
                style={{height:60}}
                timeout={10000}
            >
                {awardsSet}
            </Gallery>
            <Broadcast
                broadcast={header.broadcast.short}
            />
            {false&&<AdManager/>}
        </Group>
        </Div>
    )
}



const Award = React.memo((props) => {
    const holiday = useSelector(state => state.app.holiday);
    const dispatch = useDispatch()
    let description, counts
    const profile = ()=>{
        dispatch(loadProfile(props.award.user.username));
        dispatch(setActivePanel('profile'))
    }
    const route = ()=> {
        if(['ma','lq'].includes(props.award.type)) {
            dispatch(loadQuest(props.award.obj_id));
            dispatch(setActivePanel('question'))
        }
        else profile()
    }
    switch (props.award.type){
        case 'ma':
            description = `${props.award.word} самый популярный вопрос`
            counts = `${props.award.count} ${declOfNum(props.award.count,['ответ', 'ответа', 'ответов'])}`
            break
        case 'lq':
            description = `${props.award.word} самый любимый вопрос`
            counts = `${props.award.count} ❤`
            break
        case 'mq':
            description = `${props.award.word}`
            counts = `${props.award.count} ${declOfNum(props.award.count,['пост', 'поста', 'постов'])}`
            break
        case 'ld':
            description = `${props.award.word}`
            counts = `${props.award.count} ❤`
            break
        default:
            break
    }
        return(

            <Cell
            style={{ backgroundImage: holiday==='vd'?`url(${georg})`:'',backgroundSize: "100% 100%" ,backgroundSize: "100% 100%" }}
                disabled
                before={<Avatar onClick={profile} src={checkDev(props.award.user.avatar_small)}/>}
                after={<span style={{color:"white"}} onClick={route}>{counts}</span>}
                description={<span style={{color:holiday==='vd'?"white":''}}>{description}</span>}
            >
                <span style={{color:holiday==='vd'?"white":''}} onClick={profile}>{props.award.user.name}</span>
            </Cell>
        )
    }
)

const Broadcast = (props) => {
    const dispatch=useDispatch()
    return(
        <Cell //disabled
              onClick={()=>{dispatch(setActiveModal('broadcastMessage'))}}
              description={props.broadcast.message?props.broadcast.message:"Здесь можете быть Вы"}
              //after={<Button onClick={()=>{dispatch(setActiveModal('broadcast'))}} mode={"secondary"} style={{borderRadius:10,height:60,width:60}}><Icon28AdvertisingCircleFillRed/></Button>}
        >
            <div style={{cursor:"pointer"}} onClick={()=>{dispatch(setActiveModal('broadcastMessage'))}}>
                <Caption level="4" weight="regular" style={{marginBottom:3}}>Сейчас в эфире:</Caption>
                {props.broadcast.user?props.broadcast.user:"Место свободно"}
            </div>
        </Cell>
    )
}

export default SiteHead