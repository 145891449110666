import React, {useEffect, useState} from "react";
import {Button, Group, Panel, PanelHeader, Spinner} from "@vkontakte/vkui";
import BottomNav from "../Nav/BottomNav";
import {useDispatch, useSelector} from "react-redux";
import {getOther} from "../Redux/notifyReducer";
import Note from "./Note";
import Style from "../QuestionPanel/Question.module.css";
import TopNav from "../Nav/TopNav";


const NotifyPanel = (props) => {
    const notes = useSelector(state => state.notifs.other);
    const app = useSelector(state => state.app);
    const dispatch = useDispatch();
    const [page,setPage] = useState(2)

    useEffect(()=>{
        dispatch(getOther())
        setPage(2)
    },[]);

    const getMore = () =>{
        dispatch(getOther(page)).then(()=>{setPage(prev=>prev+1)})
    }

    const notesSet = notes.map((el,i) =>{
        return(
            <Note
                key={el.id}
                id={el.id}
                name={el.user.name}
                username={el.user.username}
                online={el.user.online}
                friend={el.user.friend}
                avatar={el.user.avatar_small}
                body={el.body}
                link_id={el.link_id}
                obj_id={el.obj_id}
                type={el.type}
                link={el.link}
                external={el.link_external}
            />
        )})

    return(
        <Panel id={props.id}>
            <PanelHeader
                left={<TopNav/>}
            />

            {notesSet}

            {app.hasMore &&
            <Group className={Style.loadMoreContainer}>
                <Button mode={'secondary'}
                        onClick={getMore}
                        disabled={app.moreLoading}
                >
                    {app.moreLoading?<Spinner/>:'Загрузить ещё'}
                </Button>
            </Group>
            }

            <div className={'lastElement'}/>
            <BottomNav/>
        </Panel>
    )
}


export default NotifyPanel