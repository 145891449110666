import React from "react";
import {Icon12Circle,Icon20ClockOutline} from "@vkontakte/icons";
import {useSelector} from "react-redux";


const UserStatus = (props) => {
    const scheme = useSelector(state => state.app.scheme)

    if(props.online==='away')
        return(
            <Icon20ClockOutline style={{color:"#e56a34",background:scheme==='bright_light'?"#fff":"#19191a",position:"absolute",bottom:0,right:0,borderRadius:"50%"}} width={props.size?props.size:20} height={props.size?props.size:20}/>
        )
    else if(props.online)
        return(
            <Icon12Circle style={{color:"#289168",background:scheme==='bright_light'?"#fff":"#19191a",position:"absolute",bottom:0,right:0,borderRadius:"50%"}} width={props.size?props.size:20} height={props.size?props.size:20}/>
        )
    return null
}

export default UserStatus