import React from "react";
import {Avatar, Div} from "@vkontakte/vkui";
import Gift from "../GiftPanel/Gifts.module.css";
import {checkDev} from "../utils/helpers";


const ProfileGifts = (props) => {
    const giftSet=props.gifts.map((el)=>(
        <div style={{position:"relative"}}>
            <Avatar mode={'image'} className={Gift.gift} size={80} src={checkDev(el.gift.image)}/>
            <Avatar style={{position:"absolute",bottom:-5,right:-5, border: "#9D746E solid 1px"}} size={40} src={checkDev(el.presenter.avatar_small)}/>
        </div>
        ))
    return(
        <Div style={{display:"flex",justifyContent:"space-around",cursor:"pointer"}} onClick={props.showUserGift}>
            {giftSet}
        </Div>
    )
}

export default ProfileGifts