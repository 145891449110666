import React from "react";
import Style from "./Gifts.module.css";
import {checkDev} from "../utils/helpers";
import {Avatar} from "@vkontakte/vkui";
import {useDispatch, useSelector} from "react-redux";
import {selectGift, showSelectedGift} from "../Redux/giftsReducer";
import {ReactComponent as Coin} from "../Icons/coin.svg";

const Gift = (props) => {
    const dispatch=useDispatch()
    const wallet = useSelector(state => state.notifs.notifs.w);
    return(
        <Avatar style={{cursor:"pointer"}} mode={'image'} className={Style.gift} size={80} src={checkDev(props.gift.image)}
                onClick={()=>{
                    dispatch(selectGift(props.gift));
                    dispatch(showSelectedGift(true))
                }}
        ><div style={{position:"absolute",bottom:-9,right:0,color:props.gift.cost>wallet?"red":"white"}} className={Style.giftFooter}>
            <div style={{
                fontWeight: "500",
                marginRight: 1,
                marginTop: "auto",
                marginBottom: "auto",
                fontSize: 16
            }}>{props.gift.cost}</div>
            <div style={{display: "flex", marginTop: "auto", marginBottom: "auto",marginRight:3}}><Coin style={{width:20,height:20}}/></div>
        </div></Avatar>
    )
}

export default Gift