import React from "react";
import {Tabbar, TabbarItem,Counter} from "@vkontakte/vkui";
import {
    Icon20AddCircle,
    Icon24CommentOutline,
    Icon28Menu,
    Icon28MessageOutline,
    Icon28Notifications,
    Icon28UsersOutline
} from "@vkontakte/icons";
import {useDispatch, useSelector} from "react-redux";
import {setActiveModal, setActivePanel} from "../Redux/appReducer";
import {getNotes} from '../Redux/questionReducer';

const BottomNav = (props) => {
    const notifs = useSelector(state => state.notifs);
    const dispatch = useDispatch()
    
    return(
            <Tabbar itemsLayout="auto" shadow={false} style={{zIndex:100}}>
                <TabbarItem
                    text={"Ответы"}
                    indicator={
                        <Counter size="s" mode="prominent" style={{right:-7}}>
                            {(notifs.notifs.a&&notifs.notifs.a>0)?notifs.notifs.a:null}
                        </Counter>
                        }
                    onClick={()=>{dispatch(getNotes('a'))}}
                >
                        <Icon28MessageOutline/>
                </TabbarItem>
                <TabbarItem
                    text={"Комментарии"}
                    indicator={
                        <Counter size="s" mode="prominent" style={{right:-7}}>
                            {(notifs.notifs.c&&notifs.notifs.c>0)?notifs.notifs.c:null}
                        </Counter>
                        }
                    onClick={()=>{dispatch(getNotes('c'))}}
                >
                        <Icon24CommentOutline width={28} height={28} />
                </TabbarItem>
                <TabbarItem id={"addQuest"}
                    onClick={()=>{dispatch(setActivePanel('ask'))}}>
                        <Icon20AddCircle width={42} height={42} style={{color:"#6bc678",boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",borderRadius:50}}/>
                </TabbarItem>
                <TabbarItem
                    text={"Ответы друзей"}
                    indicator={
                                <Counter size="s" mode="prominent" style={{right:-7}}>
                                    {(notifs.notifs.f&&notifs.notifs.f>0)?notifs.notifs.f:null}
                                </Counter>
                                }
                    onClick={()=>{dispatch(getNotes('s'))}}
                    >
                        <Icon28UsersOutline/>
                </TabbarItem>
                {false&&<TabbarItem label={notifs.notifs.n>0&&notifs.notifs.n} onClick={()=>{dispatch(setActivePanel('notify'))}}><Icon28Notifications/></TabbarItem>}
                <TabbarItem
                    text={"Меню"}
                    indicator={
                                <Counter size="s" mode="prominent" style={{right:-3}}>
                                    {(notifs.notifs.pm&&notifs.notifs.pm>0)?notifs.notifs.pm:null}
                                </Counter>
                                }
                    onClick={()=>{dispatch(setActiveModal('menu'))}}>
                    <Icon28Menu/>
                </TabbarItem>
            </Tabbar>

    )

}

export default BottomNav