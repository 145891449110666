import React, {useEffect} from "react";
import {Button, Panel, PanelHeader, Div, Title} from "@vkontakte/vkui";
import TopNav from "../Nav/TopNav";
import BottomNav from "../Nav/BottomNav";
import {useDispatch, useSelector} from "react-redux";
import {claimReward, getRewards} from "../Redux/dailyReducer";
import {ReactComponent as Coin} from "../Icons/coin.svg";
import Style from "./Rewards.module.css"
import TimeToNextDay from "../utils/TimeToNextDay";
import LogString from "../Shared/LogString";
import {setActivePanel} from "../Redux/appReducer";


const RewardsPanel = (props) => {

    const daily = useSelector(state => state.daily);

    const dispatch = useDispatch()

    const dailyRewardsSet = daily.rewards.map((el)=>(
        <RewardString
            reward={el}
            inProgress={daily.inProgress}
            claim={()=>{dispatch(claimReward(el.id))}}
        />
    ))

    const transactionLogs = daily.logs.map((el) => (
        <LogString log={el}/>
    ))

    useEffect(()=>{
        dispatch(getRewards());
        },[]);

    return(
        <Panel id={props.id}>
            <PanelHeader
                left={<TopNav/>}
            />
            <Title level={"2"} weight={"semibold"} style={{ marginBottom: 16, textAlign:"center"}}>Награды за активность</Title>

            {dailyRewardsSet}

            {daily.allClaimed &&
            <Div>
                Сегодня Вы выполнили все активности и получили все награды. <br/>
                Выполняйте активности каждый день, чтобы получать больше бонусов. <br/>

                <div className={Style.activityContainer}>
                    <div className={Style.nameContainer}>Завтра дополнительный бонус составит: </div> <div className={Style.activityCost}> <div style={{fontWeight:"500",marginRight:3,marginTop:"auto",marginBottom:"auto",fontSize:18}}>{(daily.combo)*10}</div> <div style={{display:"flex",marginTop:"auto",marginBottom:"auto"}}><Coin style={{width:20,height:20}}/></div></div>
                </div>
                <div className={Style.activityContainer}>
                    <div className={Style.nameContainer}>До сброса активностей осталось</div> <div><TimeToNextDay/></div>
                </div>
            </Div>
            }

            <Title level={"2"} weight={"semibold"} style={{ marginBottom: 16, textAlign:"center"}}>Журнал финансов</Title>
            {transactionLogs}

            {daily.logs.length>=10&&
                <Div style={{display:"flex",justifyContent:"center"}}><Button mode={"secondary"} onClick={()=>{dispatch(setActivePanel('logs'))}}>Показать весь журнал</Button></Div>
            }

            <div className={'lastElement'}/>
            <BottomNav/>
        </Panel>
    )
}

const RewardString = (props) => {
    return(
        <Div className={Style.activityContainer}>
            <div className={Style.nameContainer}>{props.reward.name}</div>
            <div className={Style.activityCost}> <div style={{fontWeight:"500",marginRight:3,marginTop:"auto",marginBottom:"auto",fontSize:18}}>{props.reward.cost}</div> <div style={{display:"flex",marginTop:"auto",marginBottom:"auto"}}><Coin style={{width:20,height:20}}/></div></div>
            {props.reward.claimed?
                <div className={Style.activityProgress}><Button mode={"secondary"} disabled={true} size={'s'}>Получено</Button></div>:
                <>  {props.reward.claimable ?
                    <div className={Style.activityProgress}><Button mode={"commerce"} size={'s'} disabled={props.inProgress} onClick={props.claim}>Получить</Button></div>:
                    <div className={Style.activityProgress}>{props.reward.progress}/{props.reward.counts}</div>
                }
                </>
            }
        </Div>
    )
}


export default RewardsPanel