import {setBackendError, toggleIsFetching} from "./appReducer";
import otvechaiMiniApi from "../api/otvechaiMiniApi";

const SET_PROFILE = "SET_PROFILE";
const STATS_FETCHING = "STATS_FETCHING"
const SET_USER_STATS = "SET_USER_STATS"
const LOAD_PROFILE = "LOAD_PROFILE"
const UPDATE_USER_QUOTE = "UPDATE_USER_QUOTE"
const UPDATE_USER_NOTE = "UPDATE_USER_NOTE"
const TOGGLE_IS_FRIEND = "TOGGLE_IS_FRIEND"
const TOGGLE_IN_BLACKLIST = "TOGGLE_IN_BLACKLIST"

let initialState  = {
    followInProgress:null,
    profileUpdating:null,
    statsFetching:null,
    currentProfile:null,
    data:{
        is_friend:null,
        userInfo:{
            user_quote:null,
            position:{},
            userStats:{
                q:null,
                a:null,
                c:null,
                f:null,
                s:null,
                b:null,
            },
        },
        friends:[],
        subs:[],
        warnings:[],
        quest:{},
        note:'',
        presents:[]
    },
};

export const setProfile = (profile) => ({type:SET_PROFILE, profile});
export const toggleStatsFetching = (status) => ({type:STATS_FETCHING,status})
export const setStats = (stats) => ({type:SET_USER_STATS,stats})
export const loadProfile = (username) => ({type:LOAD_PROFILE,username})
export const toggleIsFriend = (status) => ({type:TOGGLE_IS_FRIEND,status})
export const toggleInBlacklist = (status) => ({type:TOGGLE_IN_BLACKLIST,status})

const setUserQuote = (user_quote) => ({type:UPDATE_USER_QUOTE,user_quote});
const setUserNote = (note) => ({type:UPDATE_USER_NOTE,note})


export const getProfile = (username) => {
    return async (dispatch) => {
        dispatch(toggleIsFetching(true));
        try{
            let response = await otvechaiMiniApi.getProfile(username);
            if(response.status!==200) {
                dispatch(setBackendError(response.status))
                dispatch(toggleIsFetching(false))
            }
            else {
                dispatch(setProfile(response.data));
                dispatch(toggleIsFetching(false))
            }
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleIsFetching(false))
        }
    }
};

export const updateUserQuote = (user_quote) => {
    return async (dispatch) => {
        dispatch(toggleIsFetching(true));
        try {
            let response = await otvechaiMiniApi.updateStatus(user_quote)
            if (response.status === 200)
                dispatch(setUserQuote(response.data.user_quote))
            else dispatch(setBackendError(response.status))
            dispatch(toggleIsFetching(false));
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleIsFetching(false))
        }

    }
}

export const updateUserNote = (id,note) => {
    return async (dispatch) => {
        dispatch(toggleIsFetching(true));
        try {
            let response = await otvechaiMiniApi.updateNote(id, note)
            if (response.data.status === 0 && response.data.id === id) {
                dispatch(setUserNote(response.data.note))
            }
            else if (response.data.status!==0) dispatch(setBackendError(response.status))
            dispatch(toggleIsFetching(false));
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleIsFetching(false))
        }

    }
}


const profileReducer = (state=initialState,action) => {
    switch (action.type) {
        case SET_PROFILE:
            return {
                ...state,
                data:action.profile
            };
        case LOAD_PROFILE:
            return {
                ...state,
                currentProfile:action.username
            };
        case STATS_FETCHING:
            return{
                ...state,
                statsFetching: action.status
            };
        case SET_USER_STATS:
            return{
                ...state,
                userStats: action.stats
            };
        case UPDATE_USER_QUOTE:
            return{
                ...state,
                data:{
                    ...state.data,
                    userInfo:{
                        ...state.data.userInfo,
                        user_quote:action.user_quote
                    }
                }
            };
        case UPDATE_USER_NOTE:
            return{
                ...state,
                data:{
                    ...state.data,
                    note:action.note
                }
            };
        case TOGGLE_IS_FRIEND:
            return{
                ...state,
                data:{
                    ...state.data,
                    is_friend:action.status
                }
            };
        case TOGGLE_IN_BLACKLIST:
            return{
                ...state,
                data:{
                    ...state.data,
                    in_blacklist:action.status
                }
            };

    }
    return state
};

export default profileReducer
