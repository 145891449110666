import {setBackendError, toggleHasMore, toggleIsFetching} from "./appReducer";
import {setLogs} from "./dailyReducer";
import otvechaiMiniApi from "../api/otvechaiMiniApi";

let initialState = {
    inProgress:null,
    rate:{
        total:null,
        daily:null,
        position:{current:null,first:null,last:null}
    },
    leaders:[]
};

const TOGGLE_RATE_IN_PROGRESS = "TOGGLE_RATE_IN_PROGRESS"
const SET_RATE = "SET_RATE"
const SET_LEADERS = "SET_LEADERS"

const toggleInProgress = (progress) => ({type:TOGGLE_RATE_IN_PROGRESS,progress});
const setRate = (rate) => ({type:SET_RATE,rate});
const setLeaders = (leaders) => ({type:SET_LEADERS,leaders});

export const getRate = (page=null,logs=false) => {
    return async (dispatch) => {
        dispatch(toggleIsFetching(true));
        try{
            const response = await otvechaiMiniApi.getRate(page)
            if(response.status===200){
                if(logs) {
                    dispatch(toggleHasMore(response.data.hasMore));
                }
                else{
                    dispatch(setRate(response.data.rate));
                }
                dispatch(setLogs(response.data.logs))
            }
            dispatch(toggleIsFetching(false))
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleIsFetching(false))
        }
    }
};

export const buyRate = (count) => {
    return async (dispatch) => {
        dispatch(toggleInProgress(true));
        try{
            const response = await otvechaiMiniApi.buyRate(count)
            if(response.status===200&&response.data.status === 0){
                dispatch(setRate(response.data.rate));
                dispatch(setLogs(response.data.logs))
            }
            dispatch(toggleInProgress(false))
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleIsFetching(false))
        }
    }
};


export const getLeaders = (daily=false) => {
    return async (dispatch) => {
        dispatch(toggleIsFetching(true));
        try{
            let response = await otvechaiMiniApi.getLeaders(daily)
            if(response.status===200){
                dispatch(setLeaders(response.data.leaders));
            }
            dispatch(toggleIsFetching(false))
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleIsFetching(false))
        }
    }
};



const rateReducer = (state=initialState,action) => {
    switch (action.type) {
        case TOGGLE_RATE_IN_PROGRESS:
            return{
                ...state,
                inProgress: action.progress
            };
        case SET_RATE:
            return{
                ...state,
                rate: action.rate
            };
        case SET_LEADERS:
            return{
                ...state,
                leaders: action.leaders
            };
        default:
            return state
    }
};

export default rateReducer