import React, {useEffect, useState} from "react";
import {
    Avatar,
    Button, FixedLayout,
    Group,
    Panel,
    PanelHeader, Snackbar, Spinner
} from "@vkontakte/vkui";
import BottomNav from "../Nav/BottomNav";
import Post from "./Post";
import {useDispatch, useSelector} from "react-redux";
import {getAnswer, getMoreAnswers, getMoreComments, getQuest, getRandomQuest, setQuestionAdded, skip, loadQuest} from '../Redux/questionReducer';
import Style from "./Question.module.css"
import {historyPush, toggleDoNotAdd} from "../Redux/routerReducer";
import TopNav from "../Nav/TopNav";

import {Icon16Done, Icon36ChevronRightOutline} from '@vkontakte/icons';
import SiteHead from "../Shared/SiteHead";
import {checkRemoved} from "../utils/helpers";
import StickyForm from "../Forms/StickyForm";
import FakeStickyForm from "../Forms/FakeStickyForm";
import MyTarget from "../AdManager/myTarget";


function QuestionPanel(props){
    const app = useSelector(state => state.app);
    const auth = useSelector(state => state.auth);
    const quest = useSelector(state => state.quest);
    const dispatch = useDispatch();
    const [page,setPage] = useState(2)

    const doNotAdd = useSelector(state => state.router.doNotAdd);

    const [showSnackBar, setShowSnackbar] = useState(false);


    useEffect(()=>{
        if (quest.needToLoad&&quest.random) dispatch(getRandomQuest())
        else if(quest.needToLoad&&quest.id) dispatch(getQuest(quest.id))
        else if(quest.needToLoad&&quest.answerId) dispatch(getAnswer(quest.answerId))
        setPage(2)

    },[quest.needToLoad]);


    //router
    useEffect(()=>{
        if(!doNotAdd){
            const type = quest.answer.id ? 'answer' : 'question'
            if (quest.quest.id || quest.answer.id) dispatch(historyPush({
                panel: 'question',
                type,
                id: quest.answer.id ? quest.answer.id : quest.quest.id
            }))
        }
        dispatch(toggleDoNotAdd(false))
    },[quest.quest.id,quest.answer.id]);

    let type = {type:'answer', placeholder:'Ответ',button:'Ответить'}
    if(!quest.id&&!quest.random&&quest.answerId) type={type:'comment', placeholder:'Комментарий', button:'Комментировать'}

    const getMore = () =>{
        if (quest.id&&!quest.random&&!quest.answerId)
            dispatch(getMoreAnswers(quest.id,page)).then(()=>{setPage(prev=>prev+1)})
        else if(!quest.id&&!quest.random&&quest.answerId)
            dispatch(getMoreComments(quest.answerId,page)).then(()=>{setPage(prev=>prev+1)})
    }

    let answersSet
    let commentsSet
    if(quest.id&&!quest.random&&!quest.answerId)
        answersSet = quest.answers.map((el,i) =>{
            return(
                <>
                <Post
                    key={el.id}
                    post={el}
                    myPost={auth.user.username===el.author_username}
                    token={auth.token.access_token}
                    isCurrentQuest={quest.id===el.id}
                    isCurrentAnswer={quest.answerId===el.id}
                    isFetching={app.isFetching}
                    inProgress={app.inProgress}
                    type={'answer'}
                    size={50}
                    needDispatch={true}
                    setShowSnackbar={setShowSnackbar}
                />
                {((i===1|| (i % 10 === 0&&i!==0)) && (quest.answers.length - i > 5))&&
                  <MyTarget
                    type={'feed'}
                  />
                }
                </>
            )})
    else if(quest.answerId&&!quest.random&&!quest.id)
        commentsSet = quest.comments.map((el,i) =>{
            return(
                <>
                <Post
                    key={el.id}
                    post={el}
                    myPost={auth.user.username===el.author_username}
                    token={auth.token.access_token}
                    isFetching={app.isFetching}
                    inProgress={app.inProgress}
                    type={'comment'}
                    size={40}
                    needDispatch={true}
                    setShowSnackbar={setShowSnackbar}
                />
                {((i===1|| (i % 10 === 0&&i!==0)) && (quest.comments.length - i > 5))&&
                  <MyTarget
                    type={'feed'}
                  />
                }
                </>
            )})

    const skipPost = () => {
        const types = {f:'streams',a:'answers',c:'comments'}
        if (quest.random)
            dispatch(getRandomQuest(quest.quest.id))
        else if(['f','a','c'].includes(quest.noteType)) dispatch(skip({id:quest.answer.id,type:types[quest.noteType]})).then((response)=>{
            if(response===false) dispatch(getRandomQuest())
        })
        else dispatch(getRandomQuest())
    }

    return(
        <Panel id={props.id}>
            <PanelHeader
                left={<TopNav
                    quest={quest.quest.id}
                    random={quest.random}
                />}
            />

            <SiteHead/>

            <MyTarget
                type={'head'}
            />

            <Post
                id={"questId"}
                post={quest.quest}
                myPost={auth.user.username===quest.quest.author_username}
                token={auth.token.access_token}
                isCurrentQuest={quest.id===quest.quest.id}
                isFetching={app.isFetching}
                inProgress={app.inProgress}
                type={'question'}
                size={60}
                setShowSnackbar={setShowSnackbar}
            />

            {(quest.id&&!quest.random&&!quest.answerId)&&
                <Group className={Style.answer}>
                    {answersSet}
                </Group>
            }

            {((quest.answerId&&!quest.random&&!quest.id)||quest.noteType)&&
                <>
            <Group className={Style.answer}>
            <Post
                post={quest.answer}
                myPost={auth.user.username===quest.answer.author_username}
                token={auth.token.access_token}
                isCurrentAnswer={true}
                isFetching={app.isFetching}
                inProgress={app.inProgress}
                type={'answer'}
                size={50}
                setShowSnackbar={setShowSnackbar}
            />
            </Group>
                    <Group className={Style.comment}>
                        {commentsSet}
                    </Group>
                </>
            }
            {(!quest.random && !app.isFetching && app.hasMore) &&
            <Group className={Style.loadMoreContainer}>
                <Button mode={'secondary'}
                        onClick={getMore}
                        disabled={app.moreLoading}
                >
                    {app.moreLoading?<Spinner/>:'Загрузить ещё'}
                </Button>
            </Group>
            }

            <FixedLayout filled={true}>
                <Button
                    onClick={skipPost}
                ><Icon36ChevronRightOutline width={60} height={60} style={{position:"fixed",top:"40%",right:0,opacity:0.4, color:app.scheme==='space_gray'?"#fff":"#4986cc"}}/></Button>
            </FixedLayout>
            {showSnackBar && <Snackbar
                onClose={() => setShowSnackbar(false)}
                action="Закрыть"
                onActionClick={() => setShowSnackbar(false)}
                before={<Avatar size={24} style={{background: '#3f8ae0'}}><Icon16Done fill="#fff" width={14}
                                                                                      height={14}/></Avatar>}
            >
                Ссылка скопирована
            </Snackbar>
            }

            {quest.questionAdded? <Snackbar
                duration={6000}
                onClose={() => dispatch(setQuestionAdded(0))}
                action="Посмотреть"
                onActionClick={() => dispatch(loadQuest(quest.questionAdded))}
                before={<Avatar size={24} style={{background: '#3f8ae0'}}><Icon16Done fill="#fff" width={14}
                                                                                      height={14}/></Avatar>}
            >
                Вы задали вопрос. Когда кто-то на него ответит - Вы получите уведомление.
            </Snackbar>:null
            }
            <div style={{marginTop:105}} className={'lastElement'}/>
            {(app.isFetching || checkRemoved(quest.quest.removed)  || checkRemoved(quest.answer.removed)) ?
            <FakeStickyForm
                placeholder={type.placeholder}
                removed={checkRemoved(quest.quest.removed)  || checkRemoved(quest.answer.removed)}
                skipPost={skipPost}
            />:
            <StickyForm
                type={type.type}
                fieldName={type.type}
                placeholder={type.placeholder}
                button={type.button}
            />
            }
            <BottomNav/>
        </Panel>
    )
}

export default QuestionPanel