import React, {useState} from "react";
import {Alert, Avatar, Button, Cell, Chip, Div, Tabs, TabsItem} from "@vkontakte/vkui";
import {loadProfile} from "../Redux/profileReducer";
import {useDispatch} from "react-redux";
import {checkDev} from "../utils/helpers";
import {Icon24Help} from "@vkontakte/icons";
import {setActivePanel} from "../Redux/appReducer";
import {removeUserFromSettings, updateIgnoredTags} from "../Redux/settingsReducer";
import {loadRandomQuest} from "../Redux/questionReducer";

const EditIgnore = (props) =>{
    const [secondTab, setSecondTab] = useState(1);

    const blSet = props.ignore.blacklist.map((el)=>(
        <BlGl
            key={el.id}
            id={el.id}
            name={el.name}
            username={el.username}
            avatar={el.avatar_small}
            setPopout={props.setPopout}
            type={'bl'}
        />
    ))
    const glSet = props.ignore.greylist.map((el)=>(
        <BlGl
            key={el.id}
            id={el.id}
            name={el.name}
            username={el.username}
            avatar={el.avatar_small}
            setPopout={props.setPopout}
            type={'gl'}
        />
    ))
    return(
        <Div>
            <Tabs>
                <TabsItem
                    selected={secondTab===1}
                    onClick={()=>{setSecondTab(1)}}
                >
                    Черный список
                </TabsItem>
                <TabsItem
                    selected={secondTab===2}
                    onClick={()=>{setSecondTab(2)}}
                >
                    Серый список
                </TabsItem>
                <TabsItem
                    selected={secondTab===3}
                    onClick={()=>{setSecondTab(3)}}
                >
                    Теги
                </TabsItem>
            </Tabs>
            {secondTab===1&&
                <>
                    <Cell description={"Заблокированные Вами люди. Добавив человека в чёрный список, Вы больше не будете встречать его на сайте."} multiline disabled/>
                    {props.ignore.blacklist?.len>0?<Div style={{fontWeight:400,fontSize:24,textAlign:"center"}}>Ваш чёрный список пуст</Div>:blSet}
                </>
            }
            {secondTab===2&&
                <>
                    <Cell description={"Если Вы добавите пользователя в серый список, Вам не будут попадаться его вопросы в случайных, но Вы всё равно сможете с ним общаться (пользователь не будет заблокирован для Вас и ни как не узнает, что он у Вас в сером списке)."} multiline disabled/>
                    {props.ignore.greylist?.len>0?<Div style={{fontWeight:400,fontSize:24,textAlign:"center"}}>Ваш серый список пуст</Div>:glSet}
                </>

            }
            {secondTab===3&&
                <>
                    <Tags
                        tags={props.ignore.tags}
                        disabled={props.profileUpdating}
                        setShowSnackbar={props.setShowSnackbar}
                    />

                </>
            }
        </Div>
    )
}

const BlGl = (props) => {
    const dispatch = useDispatch()
    return(
        <Cell
            removable={true}
            before={props.avatar?<Avatar src={`${checkDev(props.avatar)}`} size={36}/>:<Avatar  size={80}><Icon24Help/></Avatar>}
            onRemove={() => {
                props.setPopout(
                    <Alert
                        actions={[{
                            title: 'Отмена',
                            autoclose: true,
                            mode: 'cancel'
                        }, {
                            title: 'Удалить',
                            autoclose: true,
                            mode: 'destructive',
                            action: () =>  dispatch(removeUserFromSettings(props.id,props.type)),
                        }]}
                        actionsLayout="horizontal"
                        onClose={()=>{props.setPopout(null)}}
                        header={`Удалить ${props.type==='gl'?'из серого списка?':''} ${props.type==='bl'?'из черного списка?':''}`}
                        text={`Вы уверены, что хотите удалить пользователя ${props.name} из ${(props.type==='gl')?'серого списка?':''} ${(props.type==='bl')?'черного списка?':''}`}
                    />
                )
            }}
        ><span style={{cursor:"pointer"}} onClick={() => {dispatch(setActivePanel('profile')); dispatch(loadProfile(props.username))}}>{props.name}</span></Cell>
    )
}


const Tags = (props) => {
    const [tags, setTags] = useState(props.tags.ignored);
    const dispatch = useDispatch()



    const addTag = (tag) => {
        if (!tags.some(el => el.id === tag.id))
            setTags(prevState => prevState.concat(tag))
    }

    const removeTag = (tag) => {
        setTags(prevState => prevState.filter(o => (o.id !== tag.id)))
    }

    const saveIgnoredTags = () => {
        const tagsId = tags.map(el => el.id)
        dispatch(updateIgnoredTags(tagsId)).then(()=>{props.setShowSnackbar(true)})
    }

    let allTags,ignoredTags

    allTags = props.tags.all.map((el) => (
        <Chip
            style={{cursor:"pointer",marginLeft:2}}
            key={el.id}
            removable={false}
            before={'#'}
            onClick={() => {
                addTag(el)
            }}
        >
            {el.name}
        </Chip>
    ))

    ignoredTags = tags.map((el) => (
        <Chip
            style={{cursor:"pointer",marginLeft:2,background:"#ff5964"}}
            key={el.id}
            removable={false}
            before={'#'}
            onClick={() => {
                removeTag(el)
            }}
        >
            {el.name}
        </Chip>
    ))

    return(
        <Div>
            <Cell description={"Нажмите на тег, чтобы добавить его в игнорируемые, или удалить его оттуда. Если Вы добавите тег в игнорируемые, то не будете видеть вопросы, в которых он присутсвует (вопросы, на определённую тему, или содержащие мат)."} multiline disabled/>
            <Div>
                <span>Все:</span>{allTags}
            </Div>
            <Div>
                <span>Игнорируемые:</span>{ignoredTags}
            </Div>
            <Div style={{display: "flex", justifyContent: "space-between"}}>
                <Button centered onClick={saveIgnoredTags} disabled={props.disabled}>Сохранить </Button>
                <Button centered onClick={
                    ()=>{
                        dispatch(setActivePanel('question'));
                        dispatch(loadRandomQuest())
                    }
                }> Отмена </Button>
            </Div>
        </Div>
    )
}

export default EditIgnore