import React from "react";
import {Title} from "@vkontakte/vkui";
import {setGiftUser, setShowcaseCategoryId} from '../Redux/giftsReducer';
import {setActiveModal} from '../Redux/appReducer';
import { useDispatch } from "react-redux";


const PmBlocked = (props) => {
    const dispatch=useDispatch()
    const statusMessage = {
        101:'Этот пользователь забанен. Писать ему бесполезно.',
        102:'Вы лишены права голоса.',
        103:'Этот пользователь удалил свой профиль.',
        104:'Этот пользователь у Вас в чёрном списке.',
        105:'Вы в чёрном списке у этого пользователя.',
        106:'Этому пользователю могут писать только его друзья.'

    }
    return(
        <>
            <Title level={props.status===106?"6":"2"} weight="semibold" style={{textAlign:"center",paddingTop:props.status===106?0:10}}>{statusMessage[props.status]}</Title>
            {props.status===106&&<Title level={"6"} weight="semibold" style={{textAlign:"center"}}>
                <span
                 onClick={() => {
                    dispatch(setGiftUser({
                        name: props.name,
                        username: props.username,
                        avatar: props.avatar_small
                    }));
                    dispatch(setShowcaseCategoryId(null))
                    dispatch(setActiveModal('giftShowcase'))
                }}
                 style={{cursor:"pointer",color:"#e56a34",fontWeight:"bold",fontSize:"110%"}}>Отправьте подарок</span>, чтобы привлечь внимание
                </Title>}
        </>
    )
}

export default PmBlocked