import React from "react";
import {Button, Div, FormItem, FormLayout, } from "@vkontakte/vkui";
import {Field, Form} from "react-final-form";
import {FinalFormCheckbox} from "./FormControls";

const EditForm = (props) => {

    return(
        <Form
            initialValues={props.initialValues}
            onSubmit={(val,form)=>{props.onSubmit(val);}}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
                <FormLayout>
                    <FormItem>
                        <Field component={FinalFormCheckbox}
                               name={'hide_age'}
                               type={"checkbox"}
                        >Скрывать возраст</Field>
                    </FormItem>
                    <FormItem>
                        <Field component={FinalFormCheckbox}
                               name={'hide_location'}
                               type={"checkbox"}
                        >Скрыть местоположение</Field>
                    </FormItem>
                    <FormItem>
                        <Field component={FinalFormCheckbox}
                               name={'hide_last_online'}
                               type={"checkbox"}
                        >Скрыть время последнего посещения</Field>
                    </FormItem>
                    <FormItem
                        bottom={"Перейти на новую строку можно комбинацией Shift+Enter. На мобильных устройствах эта комбинация не работает."}
                    >
                        <Field component={FinalFormCheckbox}
                               name={'disable_enter'}
                               type={"checkbox"}
                        >Отключить отправку сообщений по enter</Field>
                    </FormItem>
                    <FormItem bottom={
                        "Только если вопросы закончились"
                    }>
                        <Field component={FinalFormCheckbox}
                               name={'show_skipped'}
                               type={"checkbox"}
                        >Показывать пропущенные вопросы</Field>
                    </FormItem>
                    <FormItem bottom={
                        "Писать Вам личные сообщения смогут только друзья и те, с кем Вы начали разговор."
                    }>
                        <Field component={FinalFormCheckbox}
                               name={'pm_private'}
                               type={"checkbox"}
                        >Закрыть личку</Field>
                    </FormItem>
                    <Div style={{display: "flex", justifyContent: "space-between"}}>
                        <Button centered onClick={handleSubmit} disabled={props.formDisabled}> Сохранить </Button>
                        <Button centered onClick={props.onCancel}> Отмена </Button>
                    </Div>
                </FormLayout>
            )}
        />
    )
}


export default EditForm