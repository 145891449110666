import React from "react";
import {Field, Form} from "react-final-form";
import {Alert, CellButton, FormItem, FormLayout} from "@vkontakte/vkui";
import {FinalFormInputArea} from "./FormControls";
import {composeValidators, maxValue, mustBeNumber, required} from "./Validators";
import {Icon24ArrowRightSquareOutline} from "@vkontakte/icons";
import {useDispatch, useSelector} from "react-redux";
import {RateString} from "../RatePanel/RatePanel";
import {removeUser} from "../Redux/socialReducer";


const BuyRateForm = (props) => {

    const disabled = useSelector(state => state.app.inProgress);
    const dispatch = useDispatch()

    return(
        <Form
            initialValues={{count: 0}}
            onSubmit={(val,form)=>{props.onSubmit(val);setTimeout(form.reset)}}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
                <FormLayout >
                    <div style={{display:'flex',justifyContent:"space-between"}}>
                        <FormItem top={"Вы потратите:"}>
                    <Field component={FinalFormInputArea}
                           type={"number"}
                           name={'count'}
                           validate={composeValidators(mustBeNumber, maxValue(parseInt(props.max,0)),required)}
                           style={{width:80, height:50}}
                           disabled={disabled}

                    />
                        </FormItem>


                        <CellButton onClick={handleSubmit} style={{width:80,height:50,marginTop:"auto",marginBottom:"auto"}}><Icon24ArrowRightSquareOutline style={{color:"#6bc678"}} width={50} height={50}/></CellButton>
                        <FormItem top={"Вы получите:"}>
                            <RateString count={isNaN(values.count)||values.count<0?0:parseInt(values.count)*5}/>
                        </FormItem>
                        {/*<FormItem top={"Вы получите:"}>
                            {<div style={{width:80, height:50,textAlign:"center",paddingTop:10}}>{isNaN(values.count)||values.count<0?0:parseInt(values.count)*5}</div>}
                        </FormItem>
                        */}
                    </div>
                </FormLayout>
            )}
        />
    )
}

export default BuyRateForm


/*
<div style={{display:'flex',justifyContent:"space-between"}}>
                        <Input type={'number'} style={{width:80, height:50}}/>
                        <CellButton style={{width:80,height:50}}><Icon24ArrowRightSquareOutline style={{color:"#6bc678"}} width={50} height={50}/></CellButton>
                        <Input style={{width:80, height:50}}/>
                    </div>
*/