import {oembedApi} from "../api/embedApi";

let initialState = {}

const TOGGLE_FETCH_MEDIA_ERROR = "TOGGLE_FETCH_MEDIA_ERROR";
const TOGGLE_MEDIA_FETCHING = "TOGGLE_MEDIA_FETCHING";
const SET_MEDIA_LOADED = "SET_MEDIA_LOADED";
const SET_MEDIA_CONTENT = "SET_MEDIA_CONTENT";
const CLEAR_POST_CACHE = "CLEAR_POST_CACHE";

const createStateObject = (state, postId,mediaId) => {
    if (!state[postId] || (state[postId] && !state[postId][mediaId])) return {
        ...state, [postId]: {
            ...state[postId], [mediaId]: {
                error: null,
                fetching: null,
                loaded: null,
                content: {
                    "title": null,
                    "author_name": null,
                    "author_url": null,
                    "type": null,
                    "height": null,
                    "width": null,
                    "version": null,
                    "provider_name": null,
                    "provider_url": null,
                    "thumbnail_height": null,
                    "thumbnail_width": null,
                    "thumbnail_url": null,
                    "html": null
                }
            }
        }
    };
    else return state
}

const toggleFetchMediaError = (postId,mediaId,status) => ({type:TOGGLE_FETCH_MEDIA_ERROR, postId,mediaId,status});
const toggleMediaFetching = (postId,mediaId,status) => ({type:TOGGLE_MEDIA_FETCHING, postId,mediaId,status});
const setMediaLoaded = (postId,mediaId) => ({type:SET_MEDIA_LOADED, postId,mediaId});
const setMediaContent = (postId,mediaId,content) => ({type:SET_MEDIA_CONTENT, postId,mediaId,content});
export const clearPostCache = (postId) =>({type:CLEAR_POST_CACHE, postId})

export const getMedia = (postId,mediaId,provider,url) => {
    return async (dispatch) => {
        dispatch(toggleMediaFetching(postId,mediaId,true))
        try{
            let response = await oembedApi.getMedia(provider,url);
            if(response.status===200&&response.data.type){
                dispatch(setMediaLoaded(postId,mediaId))
                dispatch(setMediaContent(postId,mediaId,response.data))
                dispatch(toggleMediaFetching(postId,mediaId,false))
                return 0
            }
            else {
                dispatch(toggleFetchMediaError(postId, mediaId, true))
                dispatch(toggleMediaFetching(postId,mediaId,false))
                return 1
            }
        }
        catch (e) {
            dispatch(toggleFetchMediaError(postId,mediaId,true))
            dispatch(toggleMediaFetching(postId,mediaId,false))
            return 1
        }
    }
};

export const getShortMedia = (postId,mediaId,provider,url) =>{
    return async (dispatch) => {
        dispatch(toggleMediaFetching(postId,mediaId,true))
        try{
            let expand = await oembedApi.getRedirectUrl(url);
            if(expand.status===200){
                return dispatch(getMedia(postId,mediaId,provider,expand.data.url))
            }
        }
        catch (e) {
            dispatch(toggleFetchMediaError(postId,mediaId,true))
            dispatch(toggleMediaFetching(postId,mediaId,false))
            return 1
        }
    }
}



const embedReducer = (state=initialState,action) => {
    switch (action.type) {
        case TOGGLE_FETCH_MEDIA_ERROR:
            state = createStateObject(state,action.postId,action.mediaId)
            return{
                ...state,[action.postId]:{...state[action.postId],[action.mediaId]:{...state[action.postId][action.mediaId],error: action.status}}
            };
        case TOGGLE_MEDIA_FETCHING:
            state = createStateObject(state,action.postId,action.mediaId)
            return{
                ...state,[action.postId]:{...state[action.postId],[action.mediaId]:{...state[action.postId][action.mediaId],fetching: action.status}}
            };
        case SET_MEDIA_LOADED:
            state = createStateObject(state,action.postId,action.mediaId)
            return{
                ...state,[action.postId]:{...state[action.postId],[action.mediaId]:{...state[action.postId][action.mediaId],loaded: true}}
            };
        case SET_MEDIA_CONTENT:
            state = createStateObject(state,action.postId,action.mediaId)
            return{
                ...state,[action.postId]:{...state[action.postId],[action.mediaId]:{...state[action.postId][action.mediaId],content: action.content}}
            };
        case CLEAR_POST_CACHE:
            return{
                ...state,[action.postId]:{}
            };
        default:
            return state
    }
};

export default embedReducer