export const required = (value) => {
    if (value && value.trim().length>0) return undefined

    return ("Поле обязательно для заполнения")
};

export const maxLength = (maxL) => (value) => {
    if (value && value.length > maxL) return `Максимальная длина ${maxL} символов`;
    return undefined
};

export const sendByKey = (e, submitCallback) => {
    if (e.keyCode===13 && !e.shiftKey) submitCallback();
}

export const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined)

export const mustBeNumber = value => (isNaN(value)||value<1 ? 'Введите число' : undefined)

export const maxValue = max => value =>
    isNaN(value) || value <= max ? undefined : `Слишком дорого!`