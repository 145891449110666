import React from "react";
import Style from "./Question.module.css";
import {EmbededImg, EmbededYandex, MetaBlockedEmbed, OembedInsta, OembedSoundCloud, OembedTikTok, OembedYoutube} from "../Embed/Embed";
import Poll from "./Poll"
const reactStringReplace = require('react-string-replace')

function SplittedBody(props){

    if(props.loading)
        return(
            <div>
                <div className={Style.loadingText1}/>
                <div className={Style.loadingText2}/>
                <div className={Style.loadingText3}/>
            </div>
        )
    let postBody = []
    for (const [index,el] of props.body.content.entries()){
        if (el.type==='banned') postBody.push(
            <div className={Style.red} key={`${props.type}_${props.id}_${index}`}>
                Кудах-тах-тах
            </div>
        )
        else if(el.type==='text') {
            let replacedText = reactStringReplace(el.content, /((?:https|http):\/\/[^\][ \n |()"'`<>]+)/ig, (match, i) => (
                <a key={`link_${props.type}_${props.id}_${index}`} className={Style.linkColor} href={match} target={"_blank"} rel={"noopener noreferrer"}>{match}</a>
            ))
            replacedText = reactStringReplace(replacedText, /(\n)/ig, (match, i) => (
                <br key={`br_${props.type}_${props.id}_${index}`}/>
            ));
            postBody.push(
                <div key={`${props.type}_${props.id}_${index}`}>
                    {replacedText}
                </div>
            )
        }
        else if (el.type==='image') {
            postBody.push(
                <EmbededImg
                    key={`${props.type}_${props.id}_${index}`}
                    src={el.src}
                    type={props.type}
                    mobile={props.mobile}
                />
            )
        }
        else if (el.type==='ytb'||el.type==='ytbs') {
            postBody.push(
                <OembedYoutube
                    key={`${props.type}_${props.id}_${index}`}
                    ytbId={el.id}
                    measure={props.measure}
                    url={el.url}
                    type={props.type}
                    postId={`${props.type}_${props.id}`}
                    mediaId={`m_${el.index}`}
                    mobile={props.mobile}
                    shorts = {el.type==='ytbs'}
                />
            )
        }

        else if (el.type==='tiktok'||el.type==='vmtiktok') {
            postBody.push(
                <OembedTikTok
                    key={`${props.type}_${props.id}_${index}`}
                    ytbId={el.id}
                    measure={props.measure}
                    url={el.url}
                    type={props.type}
                    postId={`${props.type}_${props.id}`}
                    mediaId={`m_${el.index}`}
                    mobile={props.mobile}
                    vm={el.type==='vmtiktok'}
                />
            )
        }
        else if (el.type==='soundCloud'||el.type==='soundCloudApp') {
            postBody.push(
                <OembedSoundCloud
                    key={`${props.type}_${props.id}_${index}`}
                    ytbId={el.id}
                    measure={props.measure}
                    url={el.url}
                    type={props.type}
                    postId={`${props.type}_${props.id}`}
                    mediaId={`m_${el.index}`}
                    mobile={props.mobile}
                    app={el.type==='soundCloudApp'}
                />
            )
        }
        else if (['insta','fb'].includes(el.type)){
            postBody.push(
                <MetaBlockedEmbed/>
            )
        }
        /*
        else if (el.type==='insta') {
            postBody.push(
                <OembedInsta
                    key={`${props.type}_${props.id}_${index}`}
                    ytbId={el.id}
                    measure={props.measure}
                    url={el.url}
                    type={props.type}
                    postId={`${props.type}_${props.id}`}
                    mediaId={`m_${el.index}`}
                    mobile={props.mobile}
                />
            )
        }
        */
        else if (el.type==='yaTrack') {
            postBody.push(
                <EmbededYandex
                    key={index}
                    trackId={el.id}
                />
            )
        }
        else if (el.type==='yaAlbum') {
            postBody.push(
                <EmbededYandex
                    key={index}
                    albumId={el.id}
                />
            )
        }
    }

    return(
        <div>
            {postBody}
            
        </div>
    )
}

export default SplittedBody