import React, {useEffect} from "react";
import {
    Alert,
    Avatar,
    Cell,
    Group,
    Header,
    List,
    ModalPage,
    ModalPageHeader,
    PanelHeaderButton,
} from "@vkontakte/vkui";
import {Icon24Cancel, Icon24Help} from "@vkontakte/icons";
import {useDispatch, useSelector} from "react-redux";
import {getUsers, removeUser} from "../Redux/socialReducer";
import {checkDev} from "../utils/helpers";
import Style from "./Modal.module.css"
import {loadProfile} from "../Redux/profileReducer";
import {setActivePanel} from "../Redux/appReducer";



function ModalFriends(props){
    const auth = useSelector(state => state.auth);
    const profile = useSelector(state => state.profile);
    const social = useSelector(state => state.social);
    const dispatch = useDispatch();
    useEffect(()=>{
        if (profile.currentProfile)
            dispatch(getUsers(profile.currentProfile,props.type))
        else dispatch(getUsers(auth.user.username,props.type))
    },[]);

    const userList = social.users.map((el,i)=>(
        <Cell
            key={el.id} removable={social.myProfile}
            before={el.avatar?<Avatar src={`${checkDev(el.avatar)}`} size={36}/>:<Avatar  size={80}><Icon24Help/></Avatar>}
            onRemove={() => {
                props.setPopout(
                    <Alert
                        actions={[{
                            title: 'Отмена',
                            autoclose: true,
                            mode: 'cancel'
                        }, {
                            title: 'Удалить',
                            autoclose: true,
                            mode: 'destructive',
                            action: () => dispatch(removeUser(el.id,i,props.type,auth.token.access_token)),
                        }]}
                        actionsLayout="horizontal"
                        onClose={()=>{props.setPopout(null)}}
                        header={`Удалить ${props.type==='friends'?'друга?':''} ${props.type==='subs'?'подписчика?':''}`}
                        text={`Вы уверены, что хотите удалить пользователя ${el.name} из ${(props.type==='friends')?'друзей?':''} ${(props.type==='subs')?'подписчиков?':''}`}
                    />
                )
            }}
            onClick={() => {dispatch(setActivePanel('profile'));dispatch(loadProfile(el.username));props.closeModal()}}

        > <div className={Style.socialNameContainer} onClick={() => {if(social.myProfile) {
            dispatch(setActivePanel('profile'));
            dispatch(loadProfile(el.username));
            props.closeModal()
        }
        }}>{el.name}</div> </Cell>
    ))

    return(
        <ModalPage id={props.id}
                   dynamicContentHeight
                   header={
                       <ModalPageHeader
                           right={<PanelHeaderButton onClick={props.closeModal}><Icon24Cancel/></PanelHeaderButton>}
                       >
                           {props.type==='friends'&&'Друзья'}  {props.type==='subs'&&'Подписчики'}
                       </ModalPageHeader>
                   }
                   >
                    <Group header={<Header mode="secondary">{props.type==='friends'&&'Друзья'}  {props.type==='subs'&&'Подписчики'} пользователя {profile.data.userInfo.name}</Header>}>
                        <List>
                            {userList}
                        </List>
                    </Group>

        </ModalPage>
    )
}

export default ModalFriends