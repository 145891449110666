import React, {useEffect} from "react";
import {Panel, PanelHeader, PanelSpinner} from "@vkontakte/vkui";
import BottomNav from "../Nav/BottomNav";
import {useDispatch, useSelector} from "react-redux";
import {getPms} from "../Redux/pmReducer";
import Chat from "./Chat";
import TopNav from "../Nav/TopNav";


const ChatsPanel = (props) => {
    const pms = useSelector(state => state.pm.pms);
    const scheme = useSelector(state => state.app.scheme);
    const myAvatar = useSelector(state => state.auth.user.avatar_small);
    const dispatch = useDispatch()
    const isFetching = useSelector(state => state.app.isFetching);

    const pmsSet = pms.map((el,i) =>{
        return(
            <Chat
                key={`chat_${i}`}
                scheme={scheme}
                unread={!el.viewed}
                preview_text={el.body}
                preview_avatar={el.myMessage?myAvatar:el.user.avatar}
                name={el.user.name}
                username={el.user.username}
                avatar={el.user.avatar}
                online={el.user.online}
            />
        )})


    useEffect(()=>{
            dispatch(getPms())
    },[]);


    return(
        <Panel id={props.id}>
            <PanelHeader
                left={<TopNav/>}
            />
            {isFetching?<PanelSpinner/>:pmsSet}
            <div className={'lastElement'}/>
            <BottomNav/>
        </Panel>
    )
}

export default ChatsPanel