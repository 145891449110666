import React, {useEffect} from "react";
import {
    Alert,
    Avatar,
    Div,
    ModalPage,
    ModalPageHeader,
    PanelHeaderBack,
    PanelHeaderButton,
} from "@vkontakte/vkui";
import {Icon24Cancel} from "@vkontakte/icons";
import {useDispatch, useSelector} from "react-redux";
import {clearGiftState, getGiftsShowcase, sendGift, setShowcaseCategoryId} from "../Redux/giftsReducer";
import GiftShowcaseCategory from "../GiftPanel/GiftShowcaseCategory";
import Gift from "../GiftPanel/Gift";
import {checkDev} from "../utils/helpers";
import { Icon28GiftCircleFillYellow } from '@vkontakte/icons';
import GiftForm from "../Forms/GiftForm";

const GiftShowcaseModal = (props) => {
    const dispatch = useDispatch();
    const gifts = useSelector(state => state.gifts);
    const wallet = useSelector(state => state.notifs.notifs.w);

    const send = (data) => {
        data['gift'] = gifts.selected.id
        dispatch(sendGift(gifts.user.username,data)).then((response)=>{
            if(response===0)
            props.setPopout(
                <Alert
                    actions={[{
                        title: 'Ok',
                        autoclose: true,
                        mode: 'cancel'
                    }]}
                    actionsLayout="horizontal"
                    onClose={()=>{props.setPopout(null)}}
                    header={`Подарок отправлен`}
                    text={`Вы получите уведомление, когда ${gifts.user.name} его примет`}
                />
            )
            dispatch(clearGiftState())
            dispatch(setShowcaseCategoryId(null))
            props.closeModal()
        })

    }

    useEffect(()=>{
        dispatch(getGiftsShowcase(gifts.categoryId));
    },[gifts.categoryId]);

    console.log(gifts)

    const showcaseSet = gifts.showcase.map((el)=> {
        if(gifts.showcaseCategory==='all')
        return <GiftShowcaseCategory
            cat={el}
            />
        return  <Gift gift={el}/>
    })

    return(
        <ModalPage id={props.id}
                   dynamicContentHeight
                   header={
                       <ModalPageHeader
                           right={<PanelHeaderButton onClick={()=>{props.closeModal();dispatch(clearGiftState())}}><Icon24Cancel/></PanelHeaderButton>}
                           left={(gifts.showcaseCategory!=='all'||gifts.showSelected)&&<PanelHeaderBack onClick={()=>{if(gifts.showSelected)dispatch(clearGiftState());else dispatch(setShowcaseCategoryId(null));}}/>}
                       > {gifts.showSelected?'Отправьте':'Выберите'} подарок
                       </ModalPageHeader>
                   }
        >
            {gifts.showSelected ?
                <Div>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <Gift gift={gifts.selected} wallet={wallet}/>
                        <Icon28GiftCircleFillYellow width={60} height={60} style={{marginTop:"auto",marginBottom:"auto"}}/>
                        <Avatar src={checkDev(gifts.user.avatar)} size={80}/>
                    </div>
                    <GiftForm
                        onCancel={props.closeModal}
                        onSubmit={send}
                        formDisabled={gifts.inProgress||wallet<gifts.selected.cost}
                    />
                </Div>:
                <Div style={gifts.showcaseCategory !== 'all' ? {display: "flex", justifyContent:"space-around", flexWrap: "wrap"} : {}}>
                    {showcaseSet}
                </Div>
            }


        </ModalPage>
    )
}

export default GiftShowcaseModal