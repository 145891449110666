import React, {useCallback, useRef, useState} from "react";
import {Avatar, Button, Div, File, FormItem, FormLayout, Group} from "@vkontakte/vkui";
import {Field, Form} from "react-final-form";
import {FinalFormInputArea, FinalFormSelectArea} from "./FormControls";
import {required} from "./Validators";
import {Icon24Camera} from "@vkontakte/icons";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {checkDev, scrollToRef} from "../utils/helpers";


const ProfileForm = (props) => {

    const [previewUrl, setPreviewUrl] = useState();
    const imgRef = useRef(null);



    const preview = (e) => {
        if (e.target&&e.target.files&&e.target.files.length>0) {
            props.setFile({data:e.target.files[0],dataUrl:URL.createObjectURL(e.target.files[0])})
            setTimeout(()=> {
                const scrollContainer = document.getElementById('app_root')
                const profileButtons = document.getElementById('profileButtons')
                scrollToRef(profileButtons.offsetTop, scrollContainer)
            },200)

        }
    }

    const onLoad = useCallback(img => {
        imgRef.current = img;
    }, []);

    const makeClientCrop = async (crop,percentCrop) => {
        if (imgRef.current && crop.width && crop.height) {
            console.log('width',imgRef.current.width,'height',imgRef.current.height)
            createCropPreview(imgRef.current, crop);
            props.setCropPercent(percentCrop)
        }
    };

    const createCropPreview = async (image, crop) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    reject(new Error('Canvas is empty'));
                    return;
                }
                window.URL.revokeObjectURL(previewUrl);
                setPreviewUrl(window.URL.createObjectURL(blob));
            }, 'image/png');
        });
    };


    return(
        <Form
            initialValues={props.initialValues}
            onSubmit={(val,form)=>{props.onSubmit(val);}}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
                <FormLayout>
                    <Group>
                        <FormItem top={"Имя"}>
                            <Field component={FinalFormInputArea}
                               type={"text"}
                               name={'name'}
                               validate={required}
                               scheme={props.scheme}
                            />
                        </FormItem>
                        <FormItem top="Пол">
                            <Field component={FinalFormSelectArea}
                                placeholder={"Выберите пол"}
                                options={[
                                    {value: '0', label: 'Мужской'},
                                    {value: '1', label: 'Женский'},
                                    {value: '2', label: 'Не указан'}
                                ]}
                                   name={'gender'}
                                   scheme={props.scheme}
                            />
                        </FormItem>
                        <FormItem top={"Дата рождения"}>
                            <Field component={FinalFormInputArea}
                                   name={'birthday'}
                                   type={"date"}/>
                        </FormItem>
                        <FormItem  top={"Город"}>
                            <Field
                                name={'location'}
                                component={FinalFormInputArea}
                            />
                        </FormItem>
                        <FormItem top="Аватар">
                            <File before={<Icon24Camera/>} controlSize={"s"} mode={"secondary"} accept="image/*" onChange={preview}>
                                Изменить
                            </File>
                        </FormItem>
                        {props.file.data.size/1024/1024 > 4 &&
                        <div style={{textAlign:"center", color:"#e64646"}}>Размер файла слишком большой!</div>
                        }
                        {props.file.dataUrl ?
                        <Div>
                            <ReactCrop width={"100%"} src={props.file.dataUrl} crop={props.crop}
                                       onChange={newCrop => props.setCrop(newCrop)}
                                       onImageLoaded={onLoad}
                                       minWidth={100}
                                       onComplete={makeClientCrop}
                                       circularCrop={true}
                            />
                            {previewUrl && <Avatar src={previewUrl} size={96}/>}
                        </Div>:
                            <Div>
                                <img src={checkDev(props.avatarBig)} alt={'preview'} width={"100%"}/>
                                <Avatar src={checkDev(props.avatarSmall)} size={96}/>
                            </Div>
                        }

                        {props.file.data.size/1024/1024 > 4 &&
                            <div style={{textAlign:"center", color:"#e64646"}}>Размер файла слишком большой!</div>
                        }

                        <Div id={'profileButtons'} style={{display: "flex", justifyContent: "space-between"}}>
                            <Button onClick={handleSubmit} disabled={props.formDisabled}> Сохранить </Button>
                            <Button onClick={props.onCancel}> Отмена </Button>
                        </Div>
                    </Group>
                </FormLayout>
            )}
        />
    )
}


export default ProfileForm