import React from "react";
import {Avatar, Caption, Cell} from "@vkontakte/vkui";
import {checkDev, timeHumanize} from "../utils/helpers";
import Style from "./Gifts.module.css";


const Present = (props) => {
    return(
        <>
            <Cell
                before={<Avatar src={`${checkDev(props.avatar)}`} size={36}/>}
                description={timeHumanize(props.date)}
                onClick={props.loadProfile}
            >
                {props.name?props.name:'Аноним'}

            </Cell>
            <Avatar mode={'image'} style={{marginLeft:"auto",marginRight:"auto"}} className={Style.gift} size={80} src={checkDev(props.gift)}/>
            <div style={{textAlign:"center"}}>{props.comment}</div>
            {props.private&&props.name&&<Caption level="3" weight="regular" style={{marginBottom: 16, textAlign: "center"}}>Это приватный подарок.
                Только Вы видите отправителя.</Caption>}
        </>
    )
}


export default Present