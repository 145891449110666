import React from "react";
import {Counter, PanelHeaderButton} from "@vkontakte/vkui";
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel} from "../Redux/appReducer";
import {getRandomQuest, loadAnswer, loadQuest, loadRandomQuest} from "../Redux/questionReducer";
import {increasePosition, toggleDoNotAdd} from "../Redux/routerReducer";
import {loadProfile} from "../Redux/profileReducer";
import { Icon28GiftCircleFillYellow,Icon20AddCircle ,Icon24BrowserForward,Icon24BrowserBack,Icon28NotificationCircleFillGray,Icon28Menu} from '@vkontakte/icons';
import {ReactComponent as Coin} from "../Icons/coin.svg";
import {setStatsType, setUserName, toggleLoadMyStats, toggleStatsFromNotes} from "../Redux/userPostsReducer";
import {setPmCurrentUser} from "../Redux/pmReducer";
import {setGiftUser} from "../Redux/giftsReducer";


const TopNav = (props) => {

    const dispatch = useDispatch()
    const router = useSelector(state=>state.router)
    const notifs = useSelector(state => state.notifs.notifs);
    const user = useSelector(state => state.auth.user);

    const historyBack = () => {
        let prevPage = router.position < router.history.length-1?router.history[router.position+1]:null
        dispatch(toggleDoNotAdd(true))
        dispatch(increasePosition())
        if (prevPage.panel === 'question') {
            prevPage.type==='question' && dispatch(loadQuest(prevPage.id))
            prevPage.type==='answer' && dispatch(loadAnswer(prevPage.id))
        }
        else if (prevPage.panel === 'profile') dispatch(loadProfile(prevPage.id))
        else if(prevPage.panel === 'stats') {
            dispatch(toggleStatsFromNotes(false))
            dispatch(setUserName(prevPage.id))
            dispatch(setStatsType(prevPage.type))
            if(prevPage.id==='myStats')dispatch(toggleLoadMyStats(true))
        }
        else if(prevPage.panel === 'chat'){
            dispatch(setPmCurrentUser(prevPage.id));
        }

        dispatch(setActivePanel(prevPage.panel))
    }
    return(
        <>
            {router.position < router.history.length-1&&<PanelHeaderButton style={{marginRight:window.innerWidth>340?10:0}} onClick={historyBack} ><Icon24BrowserBack width={28} height={28}/></PanelHeaderButton>}
            {!props.backOnly &&
            <>
                {false&&<PanelHeaderButton onClick={() => {
                    dispatch(setActivePanel('ask'))
                }}><Icon20AddCircle width={28} height={28} style={{color: "#6bc678"}}/></PanelHeaderButton>}

                <PanelHeaderButton
                    style={{width: 48, height: 48}} onClick={() => {
                        dispatch(setGiftUser(user));
                        dispatch(setActivePanel('gifts'))
                }}
                    label={notifs.g > 0 && <Counter size="s" mode="prominent" aria-label="Уведомлений: ">{notifs.g}</Counter>}><Icon28GiftCircleFillYellow/></PanelHeaderButton>
                <PanelHeaderButton style={{width: 48, height: 48}} onClick={() => {
                    dispatch(setActivePanel('rewards'))
                }} label={notifs.d > 0 &&
                <Counter size="s" mode="prominent" aria-label="Уведомлений: ">{notifs.d}</Counter>}>
                    <div style={{width: 28, height: 28, padding: 10}}><Coin/></div>
                </PanelHeaderButton>

                <PanelHeaderButton
                    style={{width: 48, height: 48}} onClick={() => {
                    dispatch(setActivePanel('notify'))
                }}
                    label={notifs.n > 0 && <Counter size="s" mode="prominent" aria-label="Уведомлений: ">{notifs.n}</Counter>}><Icon28NotificationCircleFillGray/></PanelHeaderButton>

                <PanelHeaderButton
                    style={{marginLeft:window.innerWidth>340?10:0}}
                    onClick={() => {
                        if (props.quest && props.random)
                            dispatch(getRandomQuest(props.quest))
                        else if (props.quest) dispatch(getRandomQuest())
                        else {
                            dispatch(setActivePanel('question'));
                            dispatch(loadRandomQuest())
                        }
                    }
                    }
                ><Icon24BrowserForward height={28} width={28}/></PanelHeaderButton>
            </>
            }
            </>

    )
}

export default TopNav