import React from "react";
import {timeHumanize} from "../utils/helpers";
import Style from "../RewardsPanel/Rewards.module.css";
import {ReactComponent as Coin} from "../Icons/coin.svg";
import {Icon16StarCircle} from "@vkontakte/icons";


export const LogString = (props) => {
    return(
        <div style={{display:"flex"}}>
            <div style={{flex:"0 0 9em"}}>{timeHumanize(props.log.date)}</div>  <div style={{flexGrow:1}}>{props.log.comment}</div>
            <div className={Style.activityCost}> <div style={{fontWeight:"500",marginRight:3,marginTop:"auto",marginBottom:"auto",fontSize:18, color:props.log.total>0?"#6bc678":"#FF3131"}}>{props.log.total>0&&"+"}{props.log.total}</div> <div style={{display:"flex",marginTop:"auto",marginBottom:"auto"}}>{props.rate?<Icon16StarCircle style={{color:"#6bc678"}} width={20} height={20}/>:<Coin style={{width:20,height:20}}/>}</div></div>
        </div>
    )
}

export default LogString