import {useState, useEffect} from 'react';
import { Div, FormItem, MiniInfoCell,Input, FormLayout, Group,Header, Button} from "@vkontakte/vkui";
import { Icon20StatisticCircleFillBlue } from "@vkontakte/icons";
import {Checkbox} from "@vkontakte/vkui";

const PollForm = (props) => {
    const [pollChecked, setPollChecked] = useState(false);
    
    const [title, setTitle] = useState("");
    const [options, setOptions] = useState([{name:""},{name:""}]);
    const updateOption = index => e => {
        let newOptions = [...options];
        newOptions[index] = {name:e.target.value}
        setOptions(newOptions)
    }

    const addOption=(e)=>{
        e.preventDefault()
        setOptions(prev => [...prev, {name:""}])
    }

    useEffect(()=>{
        if(pollChecked){
            props.setPollData(
                {name:title, 
                options}
            )
        }else{
            props.setPollData({name:"", options:[]})
        }
    },[pollChecked,title,options]);

  return (
    <>
      <MiniInfoCell
        before={<Icon20StatisticCircleFillBlue />}
        textWrap="full"
        textLevel="secondary"
      >

        "К вопросу можно добавить опрос (не обязательно)."
      </MiniInfoCell>
      <FormItem>
        <Checkbox
          name={"poll"}
          type={"checkbox"}
          onChange={(e)=>{setPollChecked(e.target.checked)}}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>Добавить опрос</div>
          </div>
        </Checkbox>
      </FormItem>
      {pollChecked&&
            <AddPoll
            title={title}
            setTitle={setTitle}
            options={options}
            updateOption={updateOption}
            addOption={addOption}
            />
            }
    </>
  );
};

const AddPoll = (props) => {
  const setTitle=(e)=>{
      props.setTitle(e.target.value)
  }
  
  let optionsSet = props.options.map((el,i)=>(
    <Option
        number={i+1}
        value={el.value}
        updateOption={props.updateOption(i)}
    />
))

  return(
    <Div>
      <FormLayout>
        <Group>
          <FormItem 
            top={"Тема"}
            bottom={"Напишите тему опроса (обязательно)"}
          >
            <Input
              name={'title'}
              placeholder={'Напишите тему опроса...'}
              type={"text"}
              value={props.title}
              onChange={setTitle}
              required={true}
              maxLength={150}
            />
          </FormItem>
          <Group style={{paddingLeft:"5%",paddingRight:"5%"}}
            mode={"card"}
            header={
              <Header
                  mode="primary"
                  subtitle={"Добавьте варианты ответов (обязательно)"}
              >
                Варианты ответов
              </Header>
            }
            
          >
            {optionsSet}
            {props.options.length<5&&<Div style={{marginLeft:20,marginRight:20}}><Button stretched onClick={props.addOption}>Ещё вариант</Button></Div>}

          </Group>
        </Group>
      </FormLayout>
    </Div>
  )
}


const Option = (props) => {
  return(
    <FormItem 
            top={`Вариант ${props.number}`}
          >
            <Input
              name={`option ${props.number}`}
              placeholder={`Вариант ${props.number}...`}
              type={"text"}
              value={props.name}
              onChange={props.updateOption}
              maxLength={150}
            />
          </FormItem>
  )
}

export default PollForm;
