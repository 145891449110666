
let initialState = {
    post:{
        type:null,
        id:null,
        author_username:null,
        author_name:null,
        author_id:null,
        remove:null,
        subscribed:null,
        author_gl:null,
        needDispatch: null,
    },
    note:{
        id:null,
        name:null,
        username:null,
        friend:null,
        link_id:null,
        obj_id:null,
        type:null
    }
}

const SET_POST = 'SET_POST'
const SET_NOTE = 'SET_NOTE'

export const setPostModal = (post) => ({type:SET_POST, post});
export const setNoteModal = (note) => ({type:SET_NOTE, note});


const modalReducer  = (state = initialState, action) => {
    switch (action.type) {
        case SET_POST:
            return {
                ...state,
                post:action.post
            };
        case SET_NOTE:
            return {
                ...state,
                note:action.note
            };
        default:
            return state
    }
};

export default modalReducer