import React, {useEffect, useState} from "react";
import Style from "./Embed.module.css"
import {useDispatch, useSelector} from "react-redux";
import {getMedia, getShortMedia} from "../Redux/embedReducer";
import cn from "classnames"
import PictureLoading from "../Images/picture.png"
import {Spinner} from "@vkontakte/vkui";
import {getPostImage} from "../Redux/questionReducer";
import { Icon16PlayCircleFillPink,Icon32Music,Icon36Play,Icon36LikeOutline} from '@vkontakte/icons';


export const EmbededImg = (props) => {
        return(
            <div>
                <img src={props.src} className={Style.w98}
                     alt={'Картинка загружается...'}
                     onLoad={props.measure&&props.measure}
                />
            </div>
        )
}

export const LoadingImg = (props) => {
    const imageTypes = {question:'q',answer:'a',comment:'c'}
    const dispatch = useDispatch()
    useEffect(()=>{
        let interval
        let timeout
        if(props.image_processing)
            interval  = setInterval(()=>{dispatch(getPostImage(imageTypes[props.type],props.id))},3000)
        timeout = setTimeout(()=>{clearInterval(interval)},60000)
        return () => {
            clearInterval(interval);clearTimeout(timeout);
        }
    },[props.image_processing]);
    return(
        <div className={Style.imageProcessingContainer} style={{backgroundImage:`url(${PictureLoading})`}}>
            <Spinner className={Style.loadingSpinner}/>
            <div style={{textAlign:"center"}}>Картинка обрабатывается</div>
        </div>
    )
}

export const OembedYoutube = (props) => {
    const embed = useSelector(state => state.embed);
    const dispatch = useDispatch()
    const [display, toggleDisplay] = useState(false)
    useEffect(()=>{
        if(!embed[props.postId]||(embed[props.postId]&&!embed[props.postId][props.mediaId])||(embed[props.postId]&&!embed[props.postId][props.mediaId]&&!embed[props.postId][props.mediaId].loaded))
            if (props.shorts) dispatch(getShortMedia(props.postId,props.mediaId,'ytb',props.url)).then((result)=>{if(result===1&&props.measure)try{props.measure()}catch(e){console.log("measure error")}})
            else dispatch(getMedia(props.postId,props.mediaId,'ytb',props.url)).then((result)=>{if(result===1&&props.measure)try{props.measure()}catch(e){console.log("measure error")}})
    },[]);

    if (embed[props.postId]&&embed[props.postId][props.mediaId]&&embed[props.postId][props.mediaId].fetching) {
        return (
            <div className={Style.videoContainer}>
                <div>
                    {//<img src={LoadingIcon className={(!props.mobile && props.type !== 'bp') ? Style.videoLoadingIconSmall : Style.videoLoadingIcon} alt={"error"}/>
                    }
                    <div className={Style.videoTitleBlock}>Видео загружается!</div>
                </div>
            </div>
        )
    }
    else if (embed[props.postId]&&embed[props.postId][props.mediaId]&&embed[props.postId][props.mediaId].error) return(<a className={Style.linkColor} href={props.url} target={"_blank"} rel={"noopener noreferrer"}>{props.url}</a>)
    else if(display) {
        return (
            <>
                <div className={Style.videoContainer} dangerouslySetInnerHTML={{__html: embed[props.postId][props.mediaId].content.html}}/>
            </>
        )
    }
    else if (embed[props.postId]&&embed[props.postId][props.mediaId])return(
        <div onClick={()=>{toggleDisplay(true)}} className={Style.videoContainer}>
            <div>
                <img src={embed[props.postId][props.mediaId].content.thumbnail_url} alt="youtube video preview" className={Style.ytbPreviewImg}/>
                {//<img src={PlayVideoIcon} alt={"Play"} className={cn(Style.playButtonYtb)}/>
                }
                <div className={Style.videoTitleBlock}>{embed[props.postId][props.mediaId].content.title?.trim()===''?'Описание отсутсвует':embed[props.postId][props.mediaId].content.title}</div>
                <Icon16PlayCircleFillPink width={80} height={80} style={{position:"absolute",top:"40%",left:"40%"}}/>
            </div>
        </div>
    )
    return (
        <div className={Style.videoMessageContainer}/>
    )
};

export const OembedTikTok = (props) => {
    const [display, toggleDisplay] = useState(false)
    const embed = useSelector(state => state.embed);
    const dispatch = useDispatch()
    useEffect(()=>{
        if(!embed[props.postId]||(embed[props.postId]&&!embed[props.postId][props.mediaId])||(embed[props.postId]&&!embed[props.postId][props.mediaId]&&!embed[props.postId][props.mediaId].loaded))
            if (props.vm) dispatch(getShortMedia(props.postId,props.mediaId,'tiktok',props.url)).then((result)=>{if(result===1&&props.measure)try{props.measure()}catch(e){console.log("measure error")}})
            else dispatch(getMedia(props.postId,props.mediaId,'tiktok',props.url)).then((result)=>{if(result===1&&props.measure)try{props.measure()}catch(e){console.log("measure error")}})
        return(removeTiktokScript)
    },[]);

    const addTikTokScript = () =>{
        const script = document.createElement("script");
        script.src = "https://www.tiktok.com/embed.js";
        script.async = true;
        script.id = `script_${props.postId}_${props.mediaId}`
        document.body.appendChild(script);
    }
    const removeTiktokScript = () =>{
        const script = document.getElementById(`script_${props.postId}_${props.mediaId}`)
        if(script) document.body.removeChild(script)
    }

    if (embed[props.postId]&&embed[props.postId][props.mediaId]&&embed[props.postId][props.mediaId].fetching)  return(
        <div onClick={()=>{toggleDisplay(true)}} className={cn(Style.tikTokContainer,Style.tikTokMessagePadding)}>
            {//<img src={LoadingIcon} className={cn(Style.playButtonTt)} alt={"Loading"}/>
            }
            <div className={cn(Style.tiktokMessageBlock)}>Видео загружается</div>
        </div>
    )
    else if (embed[props.postId]&&embed[props.postId][props.mediaId]&&embed[props.postId][props.mediaId].error) return(
        <a className={Style.linkColor} href={props.url} target={"_blank"} rel={"noopener noreferrer"}>{props.url}</a>
    )
    else if(display) {
        return (
            <div className={Style.tikTokContainer}>
                <div dangerouslySetInnerHTML={{__html: embed[props.postId][props.mediaId].content.html}}/>
            </div>
        )
    }
    else if (embed[props.postId]&&embed[props.postId][props.mediaId])return(
        <div onClick={()=>{addTikTokScript();toggleDisplay(true)}} className={cn(Style.tikTokContainer,Style.tikTokContainerPadding)}>
            <img src={embed[props.postId][props.mediaId].content.thumbnail_url} alt="tiktok video preview" onLoad={props.measure} className={Style.tikTokPreviewImg}/>
            {//<img src={PlayVideoIcon} alt={"Play"} className={cn(Style.playButtonTt)}/>
            }
            <div className={cn(Style.tiktokTitleBlock)}>{embed[props.postId][props.mediaId].content.title.trim()===''?'Описание отсутсвует':embed[props.postId][props.mediaId].content.title}</div>
        </div>
    )
    return(<div className={cn(Style.tikTokContainer,Style.tikTokMessagePadding)}/>)
}

export const OembedSoundCloud = (props) => {
    const embed = useSelector(state => state.embed);
    const dispatch = useDispatch()
    const [display, toggleDisplay] = useState(false)
    useEffect(()=>{
        if(!embed[props.postId]||(embed[props.postId]&&!embed[props.postId][props.mediaId])||(embed[props.postId]&&!embed[props.postId][props.mediaId]&&!embed[props.postId][props.mediaId].loaded))
            if (props.app) dispatch(getShortMedia(props.postId,props.mediaId,'soundCloud',props.url)).then((result)=>{if(result===1&&props.measure)try{props.measure()}catch(e){console.log("measure error")}})
            else dispatch(getMedia(props.postId,props.mediaId,'soundCloud',props.url)).then((result)=>{if(result===1&&props.measure)
                try{props.measure()}catch(e){console.log("measure error")}

            })
    },[]);

    if (embed[props.postId]&&embed[props.postId][props.mediaId]&&embed[props.postId][props.mediaId].fetching) {
        return (
            <div className={cn(Style.audioContainer)}>
                {//<img src={LoadingIcon} className={cn(Style.videoLoadingIcon)} alt={"error"}/>
                }
                <div className={cn(Style.audioTitleBlock)}>
                    <div className={Style.audioTitleIconBlock}>
                        {//<img src={LoadingIcon} alt={"loading"} className={cn(Style.audioPlayButton)}/>
                        }
                        </div>
                    <div className={Style.audioTitleTextBlock}>Музыка загружается</div>
                </div>
            </div>
        )
    }
    else if (embed[props.postId]&&embed[props.postId][props.mediaId]&&embed[props.postId][props.mediaId].error) return(
        <a className={Style.linkColor} href={props.url} target={"_blank"} rel={"noopener noreferrer"}>{props.url}</a>
    )
    else if(display) {
        return (
            <>
                <div className={Style.audioContainer} dangerouslySetInnerHTML={{__html: embed[props.postId][props.mediaId].content.html}}/>
            </>
        )
    }
    else if (embed[props.postId]&&embed[props.postId][props.mediaId])return(
        <div onClick={()=>{toggleDisplay(true)}} className={cn(Style.audioContainer)}>
            <img src={embed[props.postId][props.mediaId].content.thumbnail_url} alt="youtube video preview" onLoad={props.measure} className={Style.ytbPreviewImg}/>

            <div className={cn(Style.audioTitleBlock)}>
                <div className={cn(Style.audioPlayButton)} >{
                    //<PlayMusicIcon fill={`${darkTheme && '#F6F5F4'}`}/>
                    }
                        </div>
                <div className={Style.audioTitleTextBlock}>{embed[props.postId][props.mediaId].content.title.trim()===''?'Описание отсутсвует':embed[props.postId][props.mediaId].content.title}</div>
            </div>
        </div>
    )
    return (
        <div className={Style.audioContainer}/>
    )
};

export const MetaBlockedEmbed = (props) => {
    return <div style={{color:"red"}}>
        <div>
            Ссылки на сайты компании Meta не публикуются.
        </div>
        <div>
        Meta признана экстремистской организацией, её деятельность в России запрещена.
        </div>
    </div>
}

export const OembedInsta = (props) => {
    const embed = useSelector(state => state.embed);
    const dispatch = useDispatch()

    useEffect(()=>{
        if(!embed[props.postId]||(embed[props.postId]&&!embed[props.postId][props.mediaId])||(embed[props.postId]&&!embed[props.postId][props.mediaId]&&!embed[props.postId][props.mediaId].loaded))
            dispatch(getMedia(props.postId,props.mediaId,'insta',props.url)).then((result)=>{if(result===1&&props.measure)try{props.measure()}catch(e){console.log("measure error")}})
    },[]);
    if (embed[props.postId]&&embed[props.postId][props.mediaId]&&embed[props.postId][props.mediaId].fetching)
        return (
            <div className={Style.center}>
                {//<img src={LoadingIcon} className={Main.w98} alt={'Картинка загружается...'}/>
                }
            </div>
        )
    else if (embed[props.postId]&&embed[props.postId][props.mediaId])
        return(
            <div className={cn(Style.center)}>
                <img src={embed[props.postId][props.mediaId].content.thumbnail_url} className={Style.w98} onLoad={props.measure&&props.measure}/>
                <div className={cn(Style.instaTitleBlock)}>
                    <a className={Style.instaLink} target="_blank" rel="noopener noreferrer" href={props.url}>
                        {//<img src={InstagramIcon} className={cn(Style.instaIcon)}/>
                        }
                    </a>
                    <div className={Style.instaLinkContainer}>
                        <a target="_blank" rel="noopener noreferrer" href={`${embed[props.postId][props.mediaId].content.provider_url}${embed[props.postId][props.mediaId].content.author_name}`}>@{embed[props.postId][props.mediaId].content.author_name}</a>
                    </div>
                </div>
            </div>
        )
    else if (embed[props.postId]&&embed[props.postId][props.mediaId]&&embed[props.postId][props.mediaId].error) return(<a className={Style.linkColor} href={props.url} target={"_blank"} rel={"noopener noreferrer"}>{props.url}</a>)
    return null
};

export const EmbededYandex = (props) => {
    const [display, toggleDisplay] = useState(false)
    //const darkTheme = useSelector(state => state.app.darkTheme);
    if(display) {
        if (props.albumId)
            return (
                <iframe title={props.albumId} frameBorder="0" width="100%" height="450"
                        src={`https://music.yandex.ru/iframe/#album/${props.albumId}`}/>
            )
        else if (props.trackId)
            return (
                <iframe title={props.trackId} frameBorder="0" width="100%" height="180"
                        src={`https://music.yandex.ru/iframe/#track/${props.trackId}`}/>
            )
    }
    return(
        <div className={Style.yaMusic} onClick={()=>{toggleDisplay(true)}}>
            <div className={Style.cover}>
                <Icon32Music/>
            </div>
            <div className={Style.container}>
                <div>
                    <div className={Style.title1}>Яндекс Музыка</div>
                    <div className={Style.title2}>Нажмите для проигрывания</div>
                </div>
                <div className={Style.controls}>
                    <div className={Style.playButton}>
                        <Icon36Play width={28} height={28} />
                    </div>
                    <div className={Style.likeButton}>
                        <Icon36LikeOutline width={28} height={28} style={{color:"#fff"}}/>
                    </div>
                </div>

                <div className={Style.progress}>
                    <div className={Style.indicator}/>
                    <div className={Style.hrContainer}>
                        <div className={Style.hr}/>
                    </div>
                </div>

            </div>
        </div>
    )
}
