import { Div } from "@vkontakte/vkui";
import React, {useEffect} from "react";
import { useSelector } from "react-redux";

const MyTarget = (props) => {
    const client = useSelector(state => state.app.info.client);
    const slot_id = {
        head:  1031810,
        feed: 1031827
    }
    
    useEffect(()=> {    
        const script = `(MRGtag = window.MRGtag || []).push({})`
        const adScript = new Function(script)
        adScript();
        },[]);
    
    if(client!=='ok') return null
    return(
        <Div>
            <ins className={"mrg-tag"} style={{display:"block",textDecoration: "none"}} data-ad-client={`ad-${slot_id[props.type]}`} data-ad-slot={slot_id[props.type]}></ins>
        </Div> 
    )
}

export default MyTarget