import React from "react";
import {Avatar, Cell, Separator} from "@vkontakte/vkui";
import Style from "./Chats.module.css"
import cn from "classnames"
import {checkDev} from "../utils/helpers";
import {useDispatch} from "react-redux";
import {setActivePanel} from "../Redux/appReducer";
import {setPmCurrentUser} from "../Redux/pmReducer";
import UserStatus from "../QuestionPanel/UserStatus";

const Chat = (props) => {
    const dispatch = useDispatch()

    return(
        <>
            <Separator/>
        <Cell
            before={<Avatar src={checkDev(props.avatar)} size={60}><UserStatus online={props.online}/></Avatar>}
            description={
                <Cell className={cn(Style.messagePreview,{[Style.unreadLight]:props.scheme==='bright_light'&&props.unread},{[Style.unreadDark]:props.scheme==='space_gray'&&props.unread})}
                      before={<Avatar src={checkDev(props.preview_avatar)} size={20}/>}
                      disabled={true}
                      multiline={true}
                >{props.preview_text}
                </Cell>
            }
            multiline={true}
            onClick={()=> {
                dispatch(setPmCurrentUser(props.username));
                dispatch(setActivePanel('chat'));
            }}
        >
            {props.name}
        </Cell>
        </>
    )
}

export default Chat