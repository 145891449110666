import * as axios from "axios";
import {checkDev} from "../utils/helpers";


const providers = {
    ytb: {url:'https://www.youtube.com/oembed?url=',params:''},
    tiktok: {url:'https://www.tiktok.com/oembed?url=',params:''},
    soundCloud: {url:'https://soundcloud.com/oembed?url=',params:'&auto_play=true&maxheight=450'},
    insta:{url:'https://graph.facebook.com/v10.0/instagram_oembed?url=',params:'&access_token=375904543039330|c5df01a06cc59b9e1cf6bb5053941835'}
}

export const oembedApi = {
    getMedia:(provider,url)=>{
        return axios.get(`${providers[provider].url}${url}&format=json${providers[provider].params}`).then(response=> {
            return response
        })
    },
    getRedirectUrl:(url)=>{
        return axios.put(checkDev('/api/expand'),{url},{withCredentials:true,xsrfCookieName:'csrftoken',xsrfHeaderName:'X-CSRFTOKEN'}).then(response=> {
            return response
        })
    }
}