import React, {useEffect} from "react";
import {Avatar, Cell, Div, Group, ModalPage, ModalPageHeader, PanelHeaderButton} from "@vkontakte/vkui";
import {Icon24Cancel, Icon28AdvertisingCircleFillRed, Icon20MessageOutline} from '@vkontakte/icons';
import {useDispatch, useSelector} from "react-redux";
import {getBroadcastDetails} from "../Redux/headerReducer";
import {checkDev, timeHumanize} from "../utils/helpers";
import {loadProfile} from "../Redux/profileReducer";
import {setActiveModal, setActivePanel} from "../Redux/appReducer";
import {loadQuest} from "../Redux/questionReducer"


const ModalBroadcastMessage = (props) => {
    const message = useSelector(state => state.header.broadcast.details);
    const quest = useSelector(state => state.header.broadcast.short.quest);
    const isFetching = useSelector(state => state.app.isFetching)

    const dispatch = useDispatch()

    useEffect(() => {
        if(!isFetching)
            dispatch(getBroadcastDetails())
    }, []);

    return(
        <ModalPage id={props.id}
                   dynamicContentHeight
                   header={
                       <ModalPageHeader
                           left={<Icon28AdvertisingCircleFillRed height={20} width={20}/>}
                           right={<PanelHeaderButton onClick={props.closeModal}><Icon24Cancel/></PanelHeaderButton>}
                       >
                           В Эфире
                       </ModalPageHeader>
                   }
        >
            <Div>
                <Group mode={"card"}>
                        <Cell
                            description={timeHumanize(message.date)}
                            before={<Avatar src={checkDev(message.user.avatar_small)}/>}
                            onClick={()=>{dispatch(loadProfile(message.user.username));dispatch(setActivePanel('profile'));props.closeModal()}}
                        >{message.user.name}</Cell>
                    <Group>
                        <Cell
                            multiline={true}
                            disabled
                        >{message.message}</Cell>
                    </Group>
                </Group>
                <Group mode={"card"}>
                <Cell multiline={true}
                      before={<Icon20MessageOutline/>}
                      onClick={()=>{
                        dispatch(dispatch(setActivePanel('question')))
                        dispatch(loadQuest(quest))
                        props.closeModal()
                      }}
                >Нажмите, чтобы посмотреть ответы</Cell>
                </Group>
                <Group mode={"card"}>
                <Cell multiline={true}
                      before={<Icon28AdvertisingCircleFillRed/>}
                      onClick={()=>{dispatch(setActiveModal('broadcast'))}}
                >Нажмите, чтобы отправить сообщение в Эфир</Cell>
                </Group>
            </Div>


        </ModalPage>
    )
}

export default ModalBroadcastMessage