import React from "react";
import Style from "./Gifts.module.css";
import {Avatar, Title} from "@vkontakte/vkui";
import {useDispatch} from "react-redux";
import {setShowcaseCategoryId} from "../Redux/giftsReducer";
import Gift from "./Gift";

const GiftShowcaseCategory = (props) => {
    const dispatch = useDispatch()
    let giftsSet
    if(props.cat.gifts) giftsSet = props.cat.gifts.map((el)=>(
        <Gift gift={el}/>
    ))
    return(
        <div>
            <Title level="3" weight="semibold">{props.cat.name}</Title>
            <div style={{display:"flex",flexWrap:"wrap",justifyContent: "space-around"}}>
                {giftsSet}
                <Avatar style={{cursor:"pointer"}} onClick={()=>dispatch(setShowcaseCategoryId(props.cat.id))} mode={'image'} className={Style.gift} size={80}><span style={{color:"white"}}>Ещё</span></Avatar>
            </div>
        </div>
    )
}

export default GiftShowcaseCategory