import React from "react";
import reactStringReplace from "react-string-replace";
import {EmbededImg} from "../Embed/Embed";
import Style from "../QuestionPanel/Question.module.css";

const ChatMessageBody = (props) => {

    let replacedText = reactStringReplace(props.body,/((?:https|http):[^\][ \n |()"'`]+(?:\.jpg|\.jpeg|\.png|\.gif|\.bmp))/ig,(match, i) => (
        <EmbededImg
            src={match}
        />
    ));

    replacedText = reactStringReplace(replacedText, /((?:https|http):\/\/[^\][ \n |()"'`<>]+)/ig, (match, i) => (
        <a className={Style.linkColor} href={match} target={"_blank"} rel={"noopener noreferrer"}>{match}</a>
    ))
    replacedText = reactStringReplace(replacedText, /(\n)/ig, (match, i) => (
        <br />
    ));

    return(
        <>
            {replacedText}
        </>
    )
}

export default ChatMessageBody