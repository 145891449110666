import React from "react";
import {Button,Div, FormItem, FormLayout} from "@vkontakte/vkui";
import {Field, Form} from "react-final-form";
import {FinalFormCheckbox, FinalFormTextArea} from "./FormControls";
import {maxLength} from "./Validators";


const GiftForm = (props) => {
    const maxLength200 = maxLength(200)
    return(
        <Form
            initialValues={{comment: "",private:false,anonymous:false}}
            onSubmit={(val,form)=>{props.onSubmit(val);setTimeout(form.reset)}}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
                <FormLayout style={{marginTop:10}}>
                    <FormItem>
                    <Field component={FinalFormTextArea}
                           disabled={props.formDisabled}
                           type={"text"}
                           name={"comment"} placeholder={"Пожелание..."}
                           validate={maxLength200}

                    />
                    </FormItem>
                    <FormItem bottom={"Только получатель будет видеть отправителя подарка."}>
                        <Field
                            component={FinalFormCheckbox}
                            type={"checkbox"} name={'private'}
                            disabled={props.formDisabled}
                        >Приватный</Field>
                    </FormItem>
                    <FormItem bottom={"Никто не будет видеть отправителя подарка."}>
                        <Field
                            component={FinalFormCheckbox}
                            type={"checkbox"} name={'anonymous'}
                            disabled={props.formDisabled}
                        >Анонимный</Field>
                    </FormItem>
                    <Div style={{display: "flex", justifyContent: "space-between"}}>
                        <Button centered disabled={props.formDisabled} onClick={handleSubmit}> Отправить </Button>
                        <Button centered onClick={props.onCancel}> Отмена </Button>
                    </Div>
                </FormLayout>
            )}
        />
    )
}

export default GiftForm