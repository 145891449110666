import {setBackendError, toggleIsFetching} from "./appReducer";
import otvechaiMiniApi from "../api/otvechaiMiniApi";

let initialState = {
    current:null,
    title:null,
    body:null
};

const SET_STATIC_TITLE = 'SET_STATIC_TITLE';
const SET_STATIC_BODY = 'SET_STATIC_BODY';
const SET_STATIC_PAGE = 'SET_STATIC_PAGE';

const setTitle = (title) => ({type:SET_STATIC_TITLE, title});
const setBody = (body) => ({type:SET_STATIC_BODY, body});
export const setStaticPage = (page) => ({type:SET_STATIC_PAGE, page});

export const getStatic = (page) => {
    return async (dispatch) => {
        dispatch(toggleIsFetching(true));
        try{
            let response = await otvechaiMiniApi.getStatic(page);
            dispatch(setTitle(response.data.title));
            dispatch(setBody(response.data.body));
            dispatch(toggleIsFetching(false))

        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleIsFetching(false))
        }
    }
};

const staticReducer = (state=initialState,action) => {
    switch (action.type) {
        case SET_STATIC_TITLE:
            return{
                ...state,
                title: action.title
            };
        case SET_STATIC_BODY:
            return{
                ...state,
                body: action.body
            };
        case SET_STATIC_PAGE:
            return{
                ...state,
                current: action.page
            };
        default:
            return state
    }
};
export default staticReducer