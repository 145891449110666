import React from "react";
import {
    Group,
    ModalPage,
    ModalPageHeader,
    PanelHeaderButton,
} from "@vkontakte/vkui";
import {Icon24Cancel} from "@vkontakte/icons";
import SendForm from "../Forms/Forms";
import {useSelector} from "react-redux";


function StatusModal(props){
    const formDisabled = useSelector(state => state.app.isFetching);
    return(
        <ModalPage id={props.id}
                   dynamicContentHeight
                   header={
                       <ModalPageHeader
                           right={<PanelHeaderButton onClick={props.closeModal}><Icon24Cancel/></PanelHeaderButton>}
                       > {props.type==='status'&&'Статус'}{props.type==='note'&&'Заметка'}{props.type==='abuse'&&'Жалоба'}
                       </ModalPageHeader>
                   }
        >

            <Group>
                <SendForm
                    fieldName={props.type}
                    initialValues={{[props.type]: props.initialValues}}
                    type={props.type}
                    placeholder={props.placeholder}
                    onSubmit={props.onSubmit}
                    formDisabled={formDisabled}
                />
            </Group>

        </ModalPage>
    )
};

export default StatusModal