import React from "react";
import {Div,MiniInfoCell, ModalPage, ModalPageHeader, PanelHeaderButton} from "@vkontakte/vkui";
import {Icon20FireCircleFillRed, Icon24Cancel, Icon28AdvertisingCircleFillRed} from "@vkontakte/icons";
import SendForm from "../Forms/Forms";
import Style from "../RewardsPanel/Rewards.module.css";
import {ReactComponent as Coin} from "../Icons/coin.svg";
import {useDispatch, useSelector} from "react-redux";
import {addBroadcast} from "../Redux/headerReducer";


const ModalBroadcast = (props) => {
    const wallet = parseInt(useSelector(state => state.notifs.notifs.w));
    const cost = parseInt(useSelector(state => state.header.broadcast.short.cost));
    const dispatch=useDispatch()
    return(
        <ModalPage id={props.id}
                   dynamicContentHeight
                   header={
                       <ModalPageHeader
                           left={<Icon28AdvertisingCircleFillRed height={20} width={20}/>}
                           right={<PanelHeaderButton onClick={props.closeModal}><Icon24Cancel/></PanelHeaderButton>}
                       >
                           Эфир
                       </ModalPageHeader>
                   }
        >
            <MiniInfoCell
                textWrap="full"
                textLevel="primary"
            >
                Напишите сообщение и отправьте его в Эфир. <br/>
                Сообщение увидят все пользователи сайта. <br/>
                Сообщение будет в Эфире до тех пор, пока кто-то другой не отправит новое сообщение. <br/>
                С каждым сообщением стоимость Эфира возрастает.
            </MiniInfoCell>

            <Div style={{display:"flex"}}>
                <div style={{flexGrow:1}}>Текущая стоимость Эфира</div>
                <div className={Style.activityCost}> <div style={{fontWeight:"500",marginRight:3,marginTop:"auto",marginBottom:"auto",fontSize:18, color:"#6bc678"}}>{cost}</div> <div style={{display:"flex",marginTop:"auto",marginBottom:"auto"}}><Coin style={{width:20,height:20}}/></div></div>
            </Div>

            {(!wallet || wallet<cost) &&
            <MiniInfoCell
                textWrap="full"
                textLevel="primary"
            >
                <div style={{color:"#FF3131"}}>Недостаточно монет!</div>
                Выполняйте ежедневные активности, чтобы получить больше монет.
            </MiniInfoCell>
            }

            <MiniInfoCell
                before={<Icon20FireCircleFillRed />}
                textWrap="full"
                textLevel="primary"
            >
                Внимание! <br/>
                В Эфире запрещено размещать: <br/>
                - Сообщения, содержащие мат <br/>
                - Сообщения, содержащие оскорбления в адрес кого бы то ни было <br/>
                - Религиозную, политическую или любую другую пропаганду <br/>
                - Ссылки на сторонние ресурсы
            </MiniInfoCell>

            <SendForm
                type={'broadcast'}
                fieldName={'broadcast'}
                formDisabled={!wallet || wallet<cost}
                onSubmit={(val)=>{dispatch(addBroadcast(val.broadcast)).then(()=>{props.closeModal()})}}
                />
        </ModalPage>
    )
}

export default ModalBroadcast