import {setBackendError, toggleHasMore, toggleIsFetching, toggleMoreLoading} from "./appReducer";
import otvechaiMiniApi from "../api/otvechaiMiniApi";

let initialState = {
    inProgress:null,
    logsType:null,
    rewards:[],
    combo:null,
    allClaimed:null,
    logs:[],
};

const SET_REWARDS = 'SET_REWARDS';
const TOGGLE_REWARDS_IN_PROGRESS = 'TOGGLE_REWARDS_IN_PROGRESS';
const TOGGLE_REWARD_CLAIMED = 'TOGGLE_REWARD_CLAIMED';
const SET_ALL_CLAIMED = 'SET_ALL_CLAIMED';
const SET_COMBO = 'SET_COMBO';
const SET_LOGS = 'SET_LOGS';
const ADD_LOGS = "ADD_LOGS";
const ADD_LOG = "ADD_LOG";

const SET_LOGS_TYPE = "SET_LOGS_TYPE";

const setRewards = (rewards) =>  ({type:SET_REWARDS,rewards});
const toggleInProgress = (progress) => ({type:TOGGLE_REWARDS_IN_PROGRESS,progress});
const toggleRewardClaimed = (id,claimed=true) => ({type:TOGGLE_REWARD_CLAIMED,id,claimed})
const setAllClaimed = (claimed) => ({type:SET_ALL_CLAIMED,claimed})
const setCombo = (combo) => ({type:SET_COMBO,combo})
export const setLogs = (logs) => ({type:SET_LOGS,logs})
export const addLogs = (logs) => ({type:ADD_LOGS, logs});
const addLog = (log) => ({type:ADD_LOG, log});

export const setLogsType = (logsType) => ({type:SET_LOGS_TYPE,logsType})


export const getRewards = (logs=false) => {
    return async (dispatch) => {
        dispatch(toggleIsFetching(true));
        try{
            let response
            if(logs) response = await otvechaiMiniApi.getfinanceLogs()
            else response = await otvechaiMiniApi.getDaily();
            if(response.status===200){
                if(logs) {
                    dispatch(toggleHasMore(response.data.hasMore));
                }
                else{
                    dispatch(setRewards(response.data.rewards));
                    dispatch(setCombo(response.data.combo))
                    dispatch(setAllClaimed(response.data.allClaimed))
                }
                dispatch(setLogs(response.data.logs))
            }
            dispatch(toggleIsFetching(false))
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleIsFetching(false))
        }
    }
};

export const getMoreLogs = (page,rate) => {
    return async (dispatch) => {
        dispatch(toggleMoreLoading(true))
        try {
            let response
            if (rate) response = await otvechaiMiniApi.getRate(page);
            else response = await otvechaiMiniApi.getfinanceLogs(page);
            if(response.status===200) {
                dispatch(addLogs(response.data.logs));
                dispatch(toggleHasMore(response.data.hasMore));
            }
            dispatch(toggleMoreLoading(false));
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleMoreLoading(false));
        }
    }
}

export const claimReward = (id) => {
    return async (dispatch) => {
        dispatch(toggleInProgress(true));
        try{
            let response = await otvechaiMiniApi.claimDaily(id);
            if(response.status===200 && response.data.status === 0){
                dispatch(toggleRewardClaimed(response.data.id,response.claimed));
                dispatch(setAllClaimed(response.data.allClaimed))
                dispatch(setCombo(response.data.combo))
                dispatch(addLog(response.data.transaction))
                dispatch(toggleInProgress(false))
            }
            else dispatch(toggleInProgress(false))
        }
        catch (e) {
            dispatch(toggleInProgress(false))
        }
    }
};


const dailyReducer = (state=initialState,action) => {
    switch (action.type) {
        case TOGGLE_REWARDS_IN_PROGRESS:
            return{
                ...state,
                inProgress: action.progress
            };
        case SET_REWARDS:
            return{
                ...state,
                rewards: action.rewards
            };
        case SET_COMBO:
            return{
                ...state,
                combo: action.combo
            };
        case SET_ALL_CLAIMED:
            return{
                ...state,
                allClaimed: action.claimed
            };
        case SET_LOGS:
            return{
                ...state,
                logs: action.logs
            };
        case ADD_LOGS:
            return{
                ...state,
                logs: state.logs.concat(action.logs)
            };
        case ADD_LOG:
            return{
                ...state,
                logs: [action.log].concat(state.logs)
            };
        case TOGGLE_REWARD_CLAIMED:
            return {
                ...state,
                rewards:state.rewards.map(a => {
                    if (a.id === action.id){
                        return{...a, claimed:action.claimed}
                    }
                    return a
                })
            };
        case SET_LOGS_TYPE:
            return {
                ...state,
                logsType:action.logsType
            };
        default:
            return state
    }
};

export default dailyReducer