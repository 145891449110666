import React, {useEffect, useState} from "react";
import {Panel, PanelHeader, PanelSpinner, Div, Button, Title} from "@vkontakte/vkui";
import TopNav from "../Nav/TopNav";
import BottomNav from "../Nav/BottomNav";
import {useDispatch, useSelector} from "react-redux";
import {getMoreLogs, getRewards, setLogsType} from "../Redux/dailyReducer";
import LogString from "../Shared/LogString";
import {getRate} from "../Redux/rateReducer";


const LogsPanel = (props) => {
    const dispatch = useDispatch()
    const daily = useSelector(state => state.daily);
    const app = useSelector(state => state.app);
    const [page, setPage] = useState(2);

    const loadMore = () => {
        if(!app.moreLoading) {
            if(daily.logsType==='rate') dispatch(getRate(page,true));
            else dispatch(getMoreLogs(page))
            setPage(prev=>prev+1)
        }
    }


    useEffect(()=>{
        if(daily.logsType==='rate') dispatch(getRate(1,true));
        else dispatch(getRewards(true));
        return(()=>{
            dispatch(setLogsType(null))
        })
    },[]);



    const transactionLogs = daily.logs.map((el) => (
        <LogString log={el}
             rate={daily.logsType==='rate'}
        />
    ))

    return(
        <Panel id={props.id}>
            <PanelHeader
                left={<TopNav/>}
            />
            {app.isFetching ? <PanelSpinner/> : <>
                <Title level={"2"} weight={"semibold"} style={{
                    marginBottom: 16,
                    textAlign: "center"
                }}> {daily.logsType === 'rate' ? 'Журнал рейтинга' : 'Журнал финансов'} </Title>

                <Div>{transactionLogs}</Div>
                {app.hasMore &&
                <Div style={{display: "flex", justifyContent: "center"}}><Button mode={"secondary"} onClick={loadMore}>Загрузить
                    ещё</Button></Div>
                }
            </>
            }

            <div className={'lastElement'}/>
            <BottomNav/>
        </Panel>
    )
}

export default LogsPanel