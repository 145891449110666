import React from "react";
import {Alert, Cell, Group, ModalPage, ModalPageHeader, PanelHeaderButton, PanelHeaderContent} from "@vkontakte/vkui";
import {
    Icon24Cancel,
    Icon28UserCircleFillBlue,
    Icon28QuestionOutline,
    Icon28CommentOutline,
    Icon28CancelCircleFillRed,
    Icon20StatisticCircleFillBlue
} from "@vkontakte/icons";
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel} from "../Redux/appReducer";
import {loadProfile} from "../Redux/profileReducer";
import {loadAnswer, loadQuest} from "../Redux/questionReducer";
import {removeNotif} from "../Redux/notifyReducer";


const ModalNotesMenu = (props) => {
    const note = useSelector(state => state.modal.note);
    const dispatch = useDispatch()

    console.log("Modal!!", note)

    return(
        <ModalPage id={props.id}
                   dynamicContentHeight
                   header={
                       <ModalPageHeader
                           right={<PanelHeaderButton onClick={props.closeModal}><Icon24Cancel/></PanelHeaderButton>}
                       >
                           <PanelHeaderContent>
                               Действия
                           </PanelHeaderContent>
                       </ModalPageHeader>
                   }
        >

            <Group>
                <Cell
                    before={<Icon28UserCircleFillBlue/>}
                    onClick={()=>{
                        dispatch(setActivePanel('profile'));dispatch(loadProfile(note.username));props.closeModal()
                    }}
                >
                    Профиль пользователя
                </Cell>
                {note.type==='lq' &&
                <Cell
                    before={<Icon28QuestionOutline/>}
                    onClick={()=>{
                        dispatch(dispatch(setActivePanel('question')));dispatch(loadQuest(note.link_id));props.closeModal()
                    }}
                >
                    К вопросу
                </Cell>
                }
                {note.type==='nvq' &&
                <Cell
                    before={<Icon20StatisticCircleFillBlue/>}
                    onClick={()=>{
                        dispatch(dispatch(setActivePanel('question')));dispatch(loadQuest(note.link_id));props.closeModal()
                    }}
                >
                    К опросу
                </Cell>
                }
                {(note.type==='la'||note.type==='lc')&&
                <Cell
                    before={<Icon28CommentOutline/>}
                    onClick={()=>{
                        dispatch(dispatch(setActivePanel('question')));dispatch(loadAnswer(note.link_id));props.closeModal()
                    }}
                >
                    {note.type==='la'?'К ответу':'К комментарию'}
                </Cell>
                }
                <Cell
                    before={<Icon28CancelCircleFillRed/>}
                    onClick={() => {
                        props.setPopout(
                            <Alert
                                actions={[{
                                    title: 'Отмена',
                                    autoclose: true,
                                    mode: 'cancel'
                                }, {
                                    title: 'Удалить',
                                    autoclose: true,
                                    mode: 'destructive',
                                    action: () => dispatch(removeNotif({type:'single',id:note.id})),
                                }]}
                                actionsLayout="horizontal"
                                onClose={()=>{props.setPopout(null)}}
                                header={`Удалить уведомление?`}
                                text={`Вы уверены, что хотите удалить уведомление?`}
                            />
                        );props.closeModal()
                    }}
                >
                    Удалить

                </Cell>

            </Group>

        </ModalPage>
    )
}


export default ModalNotesMenu