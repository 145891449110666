import Style from "./Shared.module.css"
import React from "react";
import {declOfNum} from "../utils/helpers";

export const UserStatus = (props) =>{
return(
    <div>
        {(props.age||props.location)&&<div>{props.age&& <>{props.age} {declOfNum(props.age,['Год', 'Года', 'Лет'])}</>}{(props.age&&props.location)&&','} {props.location&&props.location}</div>}
        {props.status==='online'||props.status==='mini'?<div className={Style.online}>Онлайн</div>:
            <>
                {props.status==='away'?
                    <div className={Style.away}>Отошёл</div>:
                    <div>{props.status}</div>
                }
            </>
        }
    </div>
)
}