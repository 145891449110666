import React from "react";
import Style from "./Chat.module.css";
import {Cell} from "@vkontakte/vkui";
import {timeHumanize} from "../utils/helpers";
import cn from "classnames"
import ChatMessageBody from "./ChatMessageBody";

const Message = (props) => {
    return(
        <Cell
            className={cn(`${props.myMessage?Style.myMessage:Style.message}`,
                {[Style.myLight]:props.scheme==='bright_light'&&props.myMessage},{[Style.light]:props.scheme==='bright_light'&&!props.myMessage},
                {[Style.myDark]:props.scheme==='space_gray'&&props.myMessage},{[Style.dark]:props.scheme==='space_gray'&&!props.myMessage}
            )}
            description={<div className={props.myMessage?Style.myDate:Style.date}>{timeHumanize(props.date_pub)}</div>}
            disabled={true}
            multiline={true}
        >
            <ChatMessageBody
                body={props.body}
            />
        </Cell>
    )
}

export default Message