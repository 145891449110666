import React from "react";
import {
    Div,
    Avatar,
    Cell,
    Chip,
    Footer,
    Group,
    Spinner,
    SubnavigationBar,
    SubnavigationButton, Panel, PanelHeader, Button,
} from "@vkontakte/vkui";
import {checkDev} from "../utils/helpers";
import {
    Icon20LikeOutline,
    Icon20MessageOutline,
    Icon20ViewOutline,
    Icon20LikeCircleFillRed,
    Icon24MoreHorizontal,
    Icon20CommentOutline,
    Icon24ShareOutline,
    Icon24LinkedOutline,
} from "@vkontakte/icons";
import {useDispatch, useSelector} from "react-redux";
import Style from "./Question.module.css"
import {setActiveModal, setActivePanel} from "../Redux/appReducer";
import {
    likeAnswer,
    likeComments,
    likeQuest,
    loadAnswer,
    loadQuest,
    setEditPost
} from "../Redux/questionReducer";

import {likeUserQuests,likeUserAnswers,likeUserComments} from "../Redux/userPostsReducer"
import {setPostModal} from "../Redux/modalReducer";
import SplittedBody from "./SplittedBody";
import {EmbededImg, LoadingImg} from "../Embed/Embed";
import bridge from "@vkontakte/vk-bridge";
import UserStatus from "./UserStatus";
import Poll from "./Poll"

function Post(props){
    const dispatch = useDispatch()
    const client = useSelector(state => state.app.info.client);
    const app_id = useSelector(state => state.app.info.app_id);

    const getLink = () => {
        if(client==='vk') return `https://vk.com/app${app_id}/#/${props.type[0]}/${props.post.id}`
        else if(client==='ok') return `https://ok.ru/app/${app_id}/#/${props.type[0]}/${props.post.id}`
    }

    const toggleEditMode = () => {
        if(props.myPost) {
            dispatch(setEditPost(props.type,props.post.id,props.post.body.raw,props.post.image,props.post.image_processing,props.post.tags))
            dispatch(setActiveModal('edit'))
        }
    }

    const post = {
        type:props.type,
        id:props.post.id,
        author_username:props.post.author_username,
        author_name:props.post.author_name,
        author_id:props.post.author_id,
        remove:props.post.remove,
        subscribed:props.post.subscribed,
        author_gl:props.post.author_gl,
        needDispatch:props.needDispatch
    }

    let tagSet
    if (props.type==='question'&&props.post.tags)
        tagSet = props.post.tags.map((el)=>(
            <Chip
                key={el.id}
                removable={false}
                before={'#'}
            >
                {el.name}
            </Chip>
        ))


    if(!props.post.body||!props.post.body.raw) return null

    return(
        <Div>
            <Group mode={"card"}>
            <Group>
                <Cell
                    before={props.isFetching?<Avatar size={props.size}/>:<Avatar src={`${checkDev(props.post.author_avatar)}`} size={props.size}><UserStatus online={props.post.author_online}/></Avatar>}
                    after={<Icon24MoreHorizontal/>}
                    description={props.isFetching?<div className={Style.loadingDate}/>:props.post.date_pub}
                    disabled={props.isFetching}
                    onClick={()=> {
                        dispatch(setPostModal(post))
                        dispatch(setActiveModal('postAction'))
                    }}
                >
                    {props.isFetching?<div className={Style.loadingName}/>:props.post.author_name}

                </Cell>
            </Group>
            <Group>
                <Cell
                    multiline
                    disabled={!props.myPost}
                    onClick={toggleEditMode}
                >
                    <SplittedBody
                        loading={props.isFetching}
                        body={props.post.body}
                        type={props.type}
                        id={props.post.id}
                        poll={props.post.poll}
                    />
                    {props.post.image &&
                    <EmbededImg
                        key={`${props.type}_${props.id}_attached`}
                        src={props.post.image}
                        type={props.type}
                        mobile={props.mobile}
                    />
                    }
                    {props.post.image_processing &&
                    <LoadingImg
                        key={`${props.type}_${props.id}_attached`}
                        id={props.post.id}
                        type={props.type}
                        image_processing = {props.post.image_processing}
                    />
                    }
                    {props.myPost&&<Footer mode="secondary">Нажмите для редактирования</Footer>}
                </Cell>
                {props.post.poll&&
            <Poll
                poll={props.post.poll}
                type={props.type}
                posId={props.post.id}
                stats={props.stats}
            />
            }
            </Group>

            {props.type === 'question' &&
                <> {props.post.tags&&props.post.tags.length > 0 &&
                <Group header={<span className={Style.tags}>Теги вопроса:</span>}>
                    {tagSet}
                </Group>
                }
            <Group>
                <SubnavigationBar mode={'fixed'}>
                    <SubnavigationButton
                        disabled={props.isFetching || props.inProgress || props.myPost}
                        before={(props.post.liked && !props.myPost && !props.isFetching) ? <Icon20LikeCircleFillRed/> :
                            <Icon20LikeOutline/>}
                        onClick={() => {
                            if ((!props.isFetching && !props.inProgress && !props.myPost)) dispatch(props.stats? likeUserQuests(props.post.id,props.stats):likeQuest(props.post.id, props.token))
                        }}
                    >{props.isFetching ? <Spinner size={'small'}/> : props.post.likes}</SubnavigationButton>
                    <SubnavigationButton
                        disabled={props.isFetching || props.isCurrentQuest}
                        before={<Icon20MessageOutline/>}
                        onClick={() => {
                            if (!props.isCurrentQuest) {
                                dispatch(dispatch(setActivePanel('question')))
                                dispatch(loadQuest(props.post.id))
                            }
                        }}
                    >{props.isFetching ? <Spinner size={'small'}/> : props.post.answers}</SubnavigationButton>
                    <SubnavigationButton
                        before={<Icon20ViewOutline/>}
                        disabled
                    >{props.isFetching ? <Spinner size={'small'}/> : props.post.views}</SubnavigationButton>
                </SubnavigationBar>
                <SubnavigationBar mode={'fixed'}>
                    <SubnavigationButton
                        disabled={props.isFetching}
                        before={<Icon24ShareOutline/>}
                        onClick={()=>{
                            //bridge.send("VKWebAppShare", {"link": getLink(),dont_parse_links:0});
                            bridge.send("VKWebAppShowWallPostBox", {
                                "message": `${props.post.body.raw} \n \n Здесь вы сможете получить ответы,на свои бессознательные вопросы. \n 👇🏻👇🏻👇🏻`,
                                "attachments": getLink()
                            })
                                .catch((e)=>{console.log(e)})
                        }}
                    >Поделиться</SubnavigationButton>
                    <SubnavigationButton
                        before={<Icon24LinkedOutline/>}
                        disabled={props.isFetching}
                        onClick={()=>{
                            bridge.send("VKWebAppCopyText", {"text": getLink()}).then((response)=>{
                                if(response.result) props.setShowSnackbar(true)
                            });
                        }}
                    >Ссылка</SubnavigationButton>
                </SubnavigationBar>

            </Group>
                </>
            }
            {props.type === 'answer' &&
            <Group>
                <SubnavigationBar mode={'fixed'}>
                    <SubnavigationButton
                        disabled={props.isFetching || props.inProgress || props.myPost}
                        before={(props.post.liked && !props.myPost && !props.isFetching) ? <Icon20LikeCircleFillRed/> :
                            <Icon20LikeOutline/>}
                        onClick={() => {
                            if ((!props.isFetching && !props.inProgress && !props.myPost)) dispatch(props.stats? likeUserAnswers(props.post.id,props.stats):likeAnswer(props.post.id,!props.needDispatch))
                        }}
                    >{props.isFetching ? <Spinner size={'small'}/> : props.post.likes}</SubnavigationButton>
                    <SubnavigationButton
                        disabled={props.isFetching || props.isCurrentAnswer}
                        before={<Icon20CommentOutline/>}
                        onClick={() => {
                            if (!props.isCurrentAnswer) {
                                dispatch(setActivePanel('question'))
                                dispatch(loadAnswer(props.post.id))
                            }
                        }}
                    >{props.isFetching ? <Spinner size={'small'}/> : props.post.comments}</SubnavigationButton>
                </SubnavigationBar>
            </Group>
            }
            {props.type === 'comment' &&
            <Group>
                <SubnavigationBar>
                    <SubnavigationButton
                        disabled={props.isFetching || props.inProgress || props.myPost}
                        before={(props.post.liked && !props.myPost && !props.isFetching) ? <Icon20LikeCircleFillRed/> :
                            <Icon20LikeOutline/>}
                        onClick={() => {
                            if ((!props.isFetching && !props.inProgress && !props.myPost)) dispatch(props.stats?likeUserComments(props.post.id,props.stats):likeComments(props.post.id))
                        }}
                    >{props.isFetching ? <Spinner size={'small'}/> : props.post.likes}</SubnavigationButton>
                </SubnavigationBar>
            </Group>
            }
            </Group>
        </Div>
    )
}

export default Post