import React from "react";
import {
    Alert,
    ModalRoot,
} from "@vkontakte/vkui";
import ModalFriends from "./ModalFriends";
import ModalWarnings from "./ModalWarnings";
import StatusModal from "./StatusModal";
import ModalProfileAction from "./ModalProfileAction";
import ModalMenu from "./ModalMenu";
import {useDispatch, useSelector} from "react-redux";
import {updateUserNote, updateUserQuote} from "../Redux/profileReducer";
import {reportUser} from "../Redux/socialReducer";
import {setActiveModal} from "../Redux/appReducer";
import ModalPostAction from "./ModalPostAction";
import ModalEdit from "./ModalEdit";
import ModalNotesMenu from "./ModalNotesMenu";
import ModalBroadcast from "./ModalBroadcast";
import ModalBroadcastMessage from "./ModalBroadcastMessage";
import GiftShowcaseModal from "./GiftShowcaseModal";


function Modal(props){
    const quote = useSelector(state => state.profile.data.userInfo.user_quote);
    const note = useSelector(state => state.profile.data.note);
    const token = useSelector(state => state.auth.token.access_token);
    const userId = useSelector(state => state.profile.data.userInfo.id);
    const dispatch = useDispatch()
    const closeModal = () => {dispatch(setActiveModal(null))}
    const app = useSelector(state=>state.app)

    const abuseSended = <Alert
        actions={[{
            title: 'Ok',
            autoclose: true,
            mode: 'cancel'
        }]}
        actionsLayout="horizontal"
        onClose={()=>{props.setPopout(null)}}
        header={`Жалоба отправлена`}
        text={`Мы рассмотрим Вашу жалобу в ближайшее время.`}
    />

    return(
        <ModalRoot activeModal={app.activeModal}>
            <ModalFriends
                id={'friends'}
                closeModal={closeModal}
                settlingHeight={100}
                fullscreen
                type={'friends'}
                dynamicContentHeight
                setPopout={props.setPopout}
            />
            <ModalFriends
                id={'subs'}
                closeModal={closeModal}
                settlingHeight={100}
                fullscreen
                type={'subs'}
                dynamicContentHeight
                setPopout={props.setPopout}
            />
            <ModalWarnings
                id={'warnings'}
                closeModal={closeModal}
                settlingHeight={100}
                fullscreen
                dynamicContentHeight
            />
            <StatusModal
                id={'status'}
                closeModal={closeModal}
                settlingHeight={100}
                fullscreen
                type={'status'}
                initialValues={quote}
                onSubmit={(val)=>{dispatch(updateUserQuote(val.status)).then(()=>{closeModal()})}}
            />
            <StatusModal
                id={'note'}
                closeModal={closeModal}
                settlingHeight={100}
                fullscreen
                type={'note'}
                placeholder={'Здесь Вы можете оставить комментарий о пользователе. Комментарий будет виден только Вам.'}
                initialValues={note}
                onSubmit={(val)=>{dispatch(updateUserNote(userId,val.note)).then(()=>{closeModal()})}}
            />
            <StatusModal
                id={'abuse'}
                closeModal={closeModal}
                settlingHeight={100}
                fullscreen
                type={'abuse'}
                placeholder={'Текст жалобы'}
                onSubmit={(val)=>{dispatch(reportUser(userId,val.abuse,token)).then(()=>{closeModal();props.setPopout(abuseSended)})}}
            />
            <ModalProfileAction
                id={'profileAction'}
                closeModal={closeModal}
                settlingHeight={100}
                fullscreen
                setPopout={props.setPopout}
            />
            <ModalPostAction
                id={'postAction'}
                closeModal={closeModal}
                settlingHeight={100}
                fullscreen
                setPopout={props.setPopout}
            />
            <ModalMenu
                id={'menu'}
                closeModal={closeModal}
                settlingHeight={100}
                fullscreen
            />
            <ModalEdit
                id={'edit'}
                closeModal={closeModal}
                settlingHeight={100}
                fullscreen
            />
            <ModalNotesMenu
                id={'notes'}
                closeModal={closeModal}
                settlingHeight={100}
                fullscreen
                setPopout={props.setPopout}
            />
            <ModalBroadcast
                id={'broadcast'}
                closeModal={closeModal}
                fullscreen
                settlingHeight={100}
            />
            <ModalBroadcastMessage
                id={'broadcastMessage'}
                closeModal={closeModal}
                fullscreen
                settlingHeight={100}
            />
            <GiftShowcaseModal
                id={'giftShowcase'}
                closeModal={closeModal}
                fullscreen
                settlingHeight={100}
                setPopout={props.setPopout}
            />
        </ModalRoot>
    )
}

export default Modal