import {setBackendError, toggleHasMore, toggleInProgress, toggleIsFetching, toggleMoreLoading} from "./appReducer";
import otvechaiMiniApi from "../api/otvechaiMiniApi";
import {concatUniq} from "../utils/helpers";


let initialState = {
    status:null,
    pmInProgress:null,
    currentUser:null,
    viewed:null,
    count:null,
    user:{},
    messages:[],
    pms:[]
}

const SET_PMS = "SET_PMS";

const TOGGLE_PM_IN_PROGRESS = 'TOGGLE_PM_IN_PROGRESS';

const SET_PM_COUNT = "SET_PM_COUNT"
const ADD_PM_COUNT = "ADD_PM_COUNT"
const SET_PM_USER = "SET_PM_USER"
const SET_PM_MESSAGES = "SET_PM_MESSAGES"
const ADD_PM_MESSAGES = "ADD_PM_MESSAGES";
const ADD_NEW_PM_MESSAGE = "ADD_NEW_PM_MESSAGE";
const TOGGLE_PM_VIEWED = 'TOGGLE_PM_VIEWED';

const SET_PM_CURRENT_USER = 'SET_PM_CURRENT_USER'

const SET_PM_STATUS = 'SET_PM_STATUS'

const setPmCount = (counts) => ({type:SET_PM_COUNT, counts});
export const addPmCount = (counts) => ({type:ADD_PM_COUNT, counts});
const setPmUser = (user) => ({type:SET_PM_USER, user});
export const setPmMessages = (messages) => ({type:SET_PM_MESSAGES, messages});
const addPmMessages = (messages) => ({type:ADD_PM_MESSAGES, messages});
export const addNewPmMessage = (message) => ({type:ADD_NEW_PM_MESSAGE, message});
export const togglePmViewed = (viewed) => ({type:TOGGLE_PM_VIEWED, viewed});

export const setPmCurrentUser = (user) => ({type:SET_PM_CURRENT_USER, user});

const setPmStatus = (status) => ({type:SET_PM_STATUS, status});

const setPms = (pms) => ({type:SET_PMS, pms});

export const getPms = () => {
    return async (dispatch) => {
        dispatch(toggleIsFetching(true));
        try{
            let response = await otvechaiMiniApi.getPms();
            if(response.data.status===0){
                dispatch(setPms(response.data.pms));
                dispatch(toggleIsFetching(false))
            }
            else{
                dispatch(toggleIsFetching(false))
            }
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleIsFetching(false))
            dispatch(toggleMoreLoading(false))
        }
    }
};

export const getPm = (username=null, page=null) => {
    return async (dispatch) => {
        dispatch(toggleIsFetching(true));
        try{
            let response = await otvechaiMiniApi.getPm(username,page);
            if(response.status===200){
                dispatch(setPmStatus(response.data.status))
                dispatch(setPmCount(response.data.count));
                dispatch(setPmUser(response.data.user));
                dispatch(setPmMessages(response.data.messages));
                dispatch(togglePmViewed(response.data.viewed))
                dispatch(toggleHasMore(response.data.hasMore));
            }
            dispatch(toggleIsFetching(false))
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleIsFetching(false))
            dispatch(toggleMoreLoading(false))
        }
    }
};

export const addPm = (username, page=null) => {
    return async (dispatch) => {
        dispatch(toggleMoreLoading(true));
        try{
            let response = await otvechaiMiniApi.getPm(username,page);
            if(response.status===200){
                dispatch(addPmMessages(response.data.messages));
                dispatch(toggleHasMore(response.data.hasMore));
            }
            dispatch(toggleMoreLoading(false));
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleIsFetching(false))
            dispatch(toggleMoreLoading(false))
        }
    }
};

export const postPm = (username, pm) => {
    return async (dispatch) => {
        dispatch(toggleInProgress(true));
        try{
            let response = await otvechaiMiniApi.postPm(username,pm);
            dispatch(toggleInProgress(false));
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleInProgress(false))
        }
    }
};



const pmReducer = (state=initialState,action) => {
    switch (action.type) {
        case TOGGLE_PM_IN_PROGRESS:
            return {
                ...state,
                pmInProgress: action.progress
            };
        case SET_PM_CURRENT_USER:
            return {
                ...state,
                currentUser: action.user
            };
        case TOGGLE_PM_VIEWED:
            return {
                ...state,
                viewed: action.viewed};
        case SET_PM_COUNT:
            return {
                ...state,
                count: action.counts
            };
        case ADD_PM_COUNT:
            return {
                ...state,
                count: state.count + action.counts
            };
        case SET_PM_USER:
            return{
                ...state,
                user: action.user
            };
        case SET_PM_MESSAGES:
            return{
                ...state,
                messages: action.messages
            };
        case ADD_PM_MESSAGES:
            return {
                ...state,
                messages: concatUniq(action.messages,state.messages)
            }
        case ADD_NEW_PM_MESSAGE:
            return {
                ...state,
                messages: concatUniq(state.messages,action.message)
            };

        case SET_PMS:
            return{
                ...state,
                pms: action.pms
            };
        case SET_PM_STATUS:
            return {
                ...state,
                status: action.status};
        default:
            return state
    }
};

export default pmReducer