import {Panel, Button, PanelHeader, Div, Link} from '@vkontakte/vkui';
import React, { useEffect } from "react";
import { Icon16ErrorCircleFill } from '@vkontakte/icons';
import { useSelector } from 'react-redux';


const ErrorPanel = (props) => {
    const client = useSelector(state=>state.app.info.client)
    useEffect(()=>{
        if(!localStorage.getItem('multiple_error')){
            localStorage.setItem('multiple_error',1)
            console.error('Refreshing app')
            document.location.reload()
        }
    },[]);
    return(
        <Panel id={props.id}>
                <PanelHeader
                    left={<Icon16ErrorCircleFill width={28} height={28}/>}
                >Ошибка</PanelHeader>
                <div style={{marginTop:100}}>
                <Div style={{textAlign:"center", fontSize:28}}>
                   К сожалению в приложении возникла ошибка
                </Div>
                <Div style={{textAlign:"center", fontSize:28}}>
                    Пожалуйста перезапустите приложение
                </Div>
                <Div style={{display:"flex",justifyContent:"center"}}>
                <Button appearance={'negative'} size={"l"} sizeY='regular' onClick={()=>{document.location.reload()}}>
                    Перезапустить
                </Button>
                </Div>
                <Div style={{textAlign:"center", fontSize:18}}>
                    Если ошибка будет повторяться - расскажите о ней в <Link href={client==='ok'?'https://ok.ru/group/56543854985246':'https://vk.com/otvechaicom'} target='_blank'>группе</Link> приложения.
                </Div>
                </div>
        </Panel>
    )
}



export default ErrorPanel