import React from "react";
import {Avatar, Cell} from "@vkontakte/vkui";
import {checkDev} from "../utils/helpers";
import {Icon24MoreHorizontal} from "@vkontakte/icons";
import GiftIcon from "../Icons/giftNote.svg"
import QuestIcon from "../Icons/question.svg"
import PollIcon from "../Icons/polling.png"
import {setActiveModal} from "../Redux/appReducer";
import {useDispatch} from "react-redux";
import {setNoteModal} from "../Redux/modalReducer";
import reactStringReplace from "react-string-replace";

const Note = (props) => {
    const dispatch = useDispatch()

    let noteHead
    let noteBody = props.body
    let avatar = checkDev(props.avatar)

    switch(props.type){
        case "la":
            noteHead = `Пользователю ${props.name} понравился Ваш ответ:`
            break
        case "lq":
            noteHead = `Пользователю ${props.name} понравился Ваш вопрос:`
            break
        case "lc":
            noteHead = `Пользователю ${props.name} понравился Ваш комментарий:`
            break
        case "da":
            noteHead = `${props.name} удалил Ваш ответ:`
            break
        case "dq":
            noteHead = `${props.name} удалил Ваш вопрос:`
            break
        case "dc":
            noteHead = `${props.name} удалил Ваш комментарий:`
            break
        case "dp":
            noteHead = `${props.name} удалил Ваше фото`
            break
        case "nf":
            noteHead = `Пользователь ${props.name} Добавил Вас в друзья`
            break
        case "w":
            noteHead = `${props.name} вынес Вам предупреждение:`
            break
        case "rw":
            noteHead = `${props.name} снял с Вас предупреждение:`
            break
        case "m":
            noteHead = `${props.name} лишил Вас права голоса:`
            noteBody = `"Немота" будет снята ${props.body}`
            break
        case "rm":
            noteHead = `${props.name} досрочно снял с Вас "Немоту"`
            break
        case "pa":
            noteHead = `${props.name} ${props.body} Ваш подарок`
            noteBody = ""
            avatar = GiftIcon
            break
        case "di":
            noteHead = `${props.name} удалил изображение, загруженное Вами`
            noteBody = ""
            break
        case "lbp":
            noteHead = `Пользователю ${props.name} понравился Ваш пост в блоге`
            break
        case "lbc":
            noteHead = `Пользователю ${props.name} понравился Ваш комментарий в блоге`
            break
        case "dbp":
            noteHead = `${props.name} удалил Ваш пост в блоге`
            break
        case "dbc":
            noteHead = `${props.name} удалил Ваш комментарий в блоге`
            break
        case "ns":
            noteHead = `Пользователь ${props.name} подписался на Ваш блог`
            break
        case "o":
            noteHead = reactStringReplace(props.body, /(\n)/ig, (match, i) => (
                <br/>
                ))
            noteBody = ""
            avatar = QuestIcon
            break
        case "nvq":
            noteHead = `Новые голоса в Вашем опросе`
            avatar = PollIcon
            break
        default:
            noteHead = "unknown notification"
                }

   const modal = {
       name:props.name,
       username:props.username,
       friend:props.friend,
       link_id:props.link_id,
       obj_id:props.obj_id,
       type:props.type,
       id:props.id
   }

    return(
        <Cell
            before={<Avatar src={avatar} size={60}/>}
            after={<Icon24MoreHorizontal/>}
            description={noteBody}
            onClick={()=>{dispatch(setNoteModal(modal));dispatch(setActiveModal('notes'))}}
            multiline={true}
            style={{marginBottom:5}}
            disabled={props.type==='o'&&!props.link}
        >
            {noteHead}

        </Cell>
    )
}
/*

 */

export default Note