import React, {useEffect} from "react";
import {
    Alert,
    Button,
    Caption,
    Div,
    Group,
    Panel,
    PanelHeader,
    PanelSpinner,
    Title
} from "@vkontakte/vkui";
import TopNav from "../Nav/TopNav";
import BottomNav from "../Nav/BottomNav";
import {useDispatch, useSelector} from "react-redux";
import {buyRate, getRate} from "../Redux/rateReducer";
import Style from "../RewardsPanel/Rewards.module.css";
import {Icon16StarCircle} from "@vkontakte/icons";
import LogString from "../Shared/LogString";
import {setActivePanel} from "../Redux/appReducer";
import {setLogsType} from "../Redux/dailyReducer";
import BuyRateForm from "../Forms/BuyRateForm";
import {ReactComponent as Coin} from "../Icons/coin.svg";
import {declOfNum} from "../utils/helpers";

const RatePanel = (props) =>{

    const dispatch = useDispatch();
    const rate = useSelector(state => state.rate);
    const logs = useSelector(state => state.daily.logs);
    const app = useSelector(state => state.app);
    const wallet = useSelector(state => state.notifs.notifs.w);

    useEffect(()=>{
        dispatch(getRate());
    },[]);

    const transactionLogs = logs.map((el) => (
        <LogString log={el} rate={true}/>
    ))

    const onSubmit = (val) => {
            props.setPopout(
                <Alert
                    actions={[{
                        title: 'Нет',
                        autoclose: true,
                        mode: 'cancel'
                    }, {
                        title: 'Да',
                        autoclose: true,
                        mode: 'destructive',
                        action: () => dispatch(buyRate(parseInt(val.count))),
                    }]}
                    actionsLayout="horizontal"
                    onClose={()=>{props.setPopout(null)}}
                    header={`Обменять монеты на рейтинг?`}
                    text={`Вы уверены, что хотите обменять ${val['count']} ${declOfNum(val['count'],['монету','монеты','монет'])} на ${isNaN(val['count'])||val['count']<0?0:parseInt(val['count'])*5} рейтинга?`}
                />
            )
    }

    return(
        <Panel id={props.id}>
            <PanelHeader
                left={<TopNav/>}
            />

            {app.isFetching ? <PanelSpinner/> : <Div>

                <Title level={"2"} weight={"semibold"} style={{
                    marginBottom: 16,
                    textAlign: "center"
                }}> Ваш рейтинг </Title>

                <Group>
                    <RateString name={"Ваш рейтинг:"} count={rate.rate.total}/>
                    <RateString name={`За сегодня Ваш рейтинг ${rate.rate.daily>0?'вырос':'снизился'}  на:`} count={rate.rate.daily}/>
                    {rate.rate.position.current ?
                        <>
                        <RateString name={`Вы входите в таблицу лидеров сайта и занимаете там ${rate.rate.position.current}-е место`} count={null}/>
                          {rate.rate.position.current > 1 &&
                        <RateString name={"Чтобы попасть на 1-е место Вам нужно набрать:"}
                                    count={rate.rate.position.first - rate.rate.total + 1}/>
                        }
                        </>:
                        <RateString name={"Чтобы попасть в таблицу лидеров сайта Вам нужно набрать:"} count={rate.rate.position.last - rate.rate.total + 1}/>
                    }
                </Group>


                <Title level={"2"} weight={"semibold"} style={{
                marginBottom: 16,
                textAlign: "center"
            }}> Как получить больше рейтинга?   </Title>

                <Group>
                    <RateString name={"Задавайте вопросы"} sup={"*"} count={3}/>
                    <RateString name={"Отвечайте на вопросы"} sup={"**"} count={2}/>
                    <RateString name={"Оставляйте комментарии к ответам"} count={1}/>

                    <Caption level="4" weight="regular" style={{ marginTop: 16 }}><sup>*</sup> Рейтинг начисляется только за 3 вопроса в сутки.</Caption>
                    <Caption level="4" weight="regular"><sup>**</sup> Рейтинг не начисляется за ответы на свои вопросы. Если Вы ответили несколько раз на 1 вопрос - рейтинг начислится только за первый ответ.</Caption>
                </Group>

                <Title level={"2"} weight={"semibold"} style={{
                marginBottom: 16,
                textAlign: "center"
            }}> Обмен монет на рейтинг   </Title>

                <Group>
                    <RateString name={"Ваши монеты:"} count={wallet} coins={true}/>
                    <RateString name={"За 1 монету можно получить"} count={5}/>
                    <RateString name={"Введите количество монет, которые хотите потратить и нажмите на стрелку."} count={null}/>
                    <BuyRateForm
                        onSubmit={onSubmit}
                        max={wallet}
                    />
                </Group>

                <Title level={"2"} weight={"semibold"} style={{
                marginBottom: 16,
                textAlign: "center"
            }}> Журнал рейтинга  </Title>

                <Group>
                    {transactionLogs}
                    {logs.length >= 10 &&
                    <Div style={{display: "flex", justifyContent: "center"}}><Button mode={"secondary"} onClick={() => {
                        dispatch(setLogsType('rate'));dispatch(setActivePanel('logs'))
                    }}>Показать весь журнал</Button></Div>
                    }
                </Group>
            </Div>}

            <div className={'lastElement'}/>
            <BottomNav/>
        </Panel>
    )
}


export const RateString = (props) => {
    return(
        <Div className={Style.activityContainer}>
            <div className={Style.nameContainer}>{props.name}{props.sup&&<sup>{props.sup}</sup>}</div>
            {props.count &&
            <div className={Style.activityCost}>
                <div style={{
                    fontWeight: "500",
                    marginRight: 3,
                    marginTop: "auto",
                    marginBottom: "auto",
                    fontSize: 18
                }}>{props.count}</div>
                <div style={{display: "flex", marginTop: "auto", marginBottom: "auto"}}>{props.coins?<Coin style={{width:20,height:20}}/>:<Icon16StarCircle style={{color:"#6bc678"}} width={20} height={20}/>}</div>
            </div>
            }
        </Div>
    )
}


export default RatePanel