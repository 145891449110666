import {setBackendError, toggleIsFetching} from "./appReducer";
import otvechaiMiniApi from "../api/otvechaiMiniApi";
import {toggleInBlacklist, toggleIsFriend} from "./profileReducer";


let initialSocialState = {
    user:{},
    users:[],
    myProfile:null
}

const SET_USER = 'SET_USER';
const SET_USERS = 'SET_USERS';
const SET_MY_PROFILE = 'SET_MY_PROFILE';
const SET_USER_REMOVED = 'SET_USER_REMOVED';

export const setUser = (user) => ({type:SET_USER, user});
export const setMyProfile = (myProfile) => ({type:SET_MY_PROFILE, myProfile});
export const setUsers = (users) => ({type:SET_USERS, users});
export const setUserRemoved = (id) => ({type:SET_USER_REMOVED, id});

export const getUsers = (username,type, token) => {
    return async (dispatch) => {
        dispatch(setUsers([]));
        dispatch(toggleIsFetching(true));
        let response
        try{
            if(type==='friends') {
                response = await otvechaiMiniApi.getFriends(username,token);
            }
            else if(type==='subs')
                response = await otvechaiMiniApi.getSubs(username,token);
            if(response.status===200){
                dispatch(setUser(response.data.user));
                dispatch(setUsers(response.data.users));
                dispatch(setMyProfile(response.data.myProfile))
                dispatch(toggleIsFetching(false))
            }
            else{
                dispatch(setBackendError(response.status))
                dispatch(toggleIsFetching(false))
            }
        }

        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleIsFetching(false))
        }
    }
};

export const removeUser = (id,i,type,token) => {
    return async (dispatch) => {
        let  response
        if(type==='friends')
            response = await otvechaiMiniApi.unFollow(id,token)
        else if(type==='subs')
            response = await otvechaiMiniApi.unSub(id,token)
        if(response.data.status===0){
            dispatch(setUserRemoved(i))
        }
    }
}

export const friend = (action,id,token) => {
    return async (dispatch) => {
        let response
        try {
            if (action === 'add')
                response = await otvechaiMiniApi.follow(id, token)
            else if (action === 'remove')
                response = await otvechaiMiniApi.unFollow(id, token)
            if (response.data.status === 0) {
                dispatch(toggleIsFriend(response.data.is_friend))
            }
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleIsFetching(false))
        }
    }
}


export const blacklist = (action,id,token) => {
    return async (dispatch) => {
        dispatch(toggleIsFetching(true));
        let response
        try {
            if (action === 'add') response = await otvechaiMiniApi.addBlacklist(id, token);
            else if (action === 'remove') response = await otvechaiMiniApi.removeBlacklist(id, token);
            if (response.data.status === 0 && response.data.id === id) {
                dispatch(toggleInBlacklist(response.data.in_blacklist))
                dispatch(toggleIsFetching(false));
            }
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleIsFetching(false))
        }
    }
}

export const reportUser = (id,abuse,token) => {
    return async (dispatch) => {
        dispatch(toggleIsFetching(true));
        try {
            let response = await otvechaiMiniApi.abuseUser(id, abuse, token);
            if (response.data.status === 0 && response.data.id === id) {
                dispatch(toggleIsFetching(false));
            }
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleIsFetching(false))
        }
    }
}


const socialReducer = (state=initialSocialState,action) => {
    switch (action.type) {
        case SET_USERS:
            return{
                ...state,
                users: action.users
            };
        case SET_USER:
            return{
                ...state,
                user: action.user
            };
        case SET_MY_PROFILE:
            return{
                ...state,
                myProfile: action.myProfile
            };
        case SET_USER_REMOVED:
            return {
                ...state,

                users:[...state.users.slice(0,action.id), ...state.users.slice(action.id+1,state.users.length)]
            };
        default:
            return state
    }
};

export default socialReducer