import {setBackendError, toggleIsFetching} from "./appReducer";
import otvechaiMiniApi from "../api/otvechaiMiniApi";
import {setSettings, setUserStatus, updateUserAvatar} from "./authReducer";


let initialState  = {
    profileUpdating:null,
    anket:{
        info:{},
        avatar:{}
    },
    settings: {
        interface: {font_size:1},
            menu:{},
            notifications: {
            disable_all_notifs:null
        }
        },
    ignore:{
        blacklist:[],
        greylist:[],
        tags:{
            all:[],
            ignored:[]
        }
    }
}

const SET_ANKET = 'SET_ANKET';
const SET_INTERFACE_SETTINGS = 'SET_INTERFACE_SETTINGS';
const SET_MENU_SETTINGS = 'SET_MENU_SETTINGS';
const SET_BLACKLIST_SETTINGS = 'SET_BLACKLIST_SETTINGS';
const SET_GREYLIST_SETTINGS = 'SET_GREYLIST_SETTINGS';
const SET_TAGS_SETTINGS = 'SET_TAGS_SETTINGS';
const ADD_TAGS_TO_IGNORE = 'ADD_TAGS_TO_IGNORE';
const REMOVE_TAGS_FROM_IGNORE = 'REMOVE_TAGS_FROM_IGNORE';
const SET_NOTIFICATIONS_SETTINGS = 'SET_NOTIFICATIONS_SETTINGS';
const SET_BL_USER_REMOVED = 'SET_BL_USER_REMOVED';
const SET_GL_USER_REMOVED = 'SET_GL_USER_REMOVED';
const PROFILE_UPDATING = 'PROFILE_UPDATING';

const ENABLE_ALL_NOTIFS = 'ENABLE_ALL_NOTIFS'

const setAnket = (anket) => ({type:SET_ANKET,anket})
const setInterfaceSettings = (iface) => ({type:SET_INTERFACE_SETTINGS,iface})
const setMenuSettings = (menu) => ({type:SET_MENU_SETTINGS,menu})
const setNotificationsSettings = (notifications) => ({type:SET_NOTIFICATIONS_SETTINGS,notifications})
const setBlacklistSettings = (blacklist) => ({type:SET_BLACKLIST_SETTINGS,blacklist})
const setGreylistSettings = (greylist) => ({type:SET_GREYLIST_SETTINGS,greylist})
const setTagsSettings = (tags) => ({type:SET_TAGS_SETTINGS,tags})
const addTagsToIgnore = (tags) => ({type:ADD_TAGS_TO_IGNORE,tags})
const removeTagsToIgnore = (tags) => ({type:REMOVE_TAGS_FROM_IGNORE,tags})
export const setBlUserRemoved = (id) => ({type:SET_BL_USER_REMOVED, id});
export const setGlUserRemoved = (id) => ({type:SET_GL_USER_REMOVED, id});
export const enableAllNotifs = () => ({type:ENABLE_ALL_NOTIFS});

const toggleProfileUpdating = (status) => ({type:PROFILE_UPDATING,status})

export const getAnket = () => {
    return async (dispatch) => {
        dispatch(toggleIsFetching(true));
        try{
            let response = await otvechaiMiniApi.getAnket();
            dispatch(setAnket(response.data.userInfo));
            dispatch(setInterfaceSettings(response.data.settings.interface));
            dispatch(setMenuSettings(response.data.settings.menu));
            dispatch(setNotificationsSettings(response.data.settings.notifications));
            dispatch(setBlacklistSettings(response.data.ignore.blacklist));
            dispatch(setGreylistSettings(response.data.ignore.greylist));
            dispatch(setTagsSettings(response.data.ignore.tags));
            dispatch(toggleIsFetching(false))
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleIsFetching(false))
        }
    }
};

export const updateAnket = (form) => {
    return async (dispatch) => {
        dispatch(toggleProfileUpdating(true));
        try {
            let response = await otvechaiMiniApi.updateAnket(form)
            dispatch(setAnket(response.data.userInfo));
            dispatch(updateUserAvatar(response.data.userInfo.avatar.avatar_small))
            dispatch(toggleProfileUpdating(false));
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleProfileUpdating(false))
        }
    }
}

export const updateInterface = (form) => {
    return async (dispatch) => {
        dispatch(toggleProfileUpdating(true));
        try {
            let response = await otvechaiMiniApi.updateInterface(form)
            dispatch(setInterfaceSettings(response.data.settings.interface));
            dispatch(setSettings(response.data.settings.interface));
            dispatch(toggleProfileUpdating(false));
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleProfileUpdating(false))
        }
    }
}

export const updateNotifications = (form) => {
    return async (dispatch) => {
        dispatch(toggleProfileUpdating(true));
        try {
            let response = await otvechaiMiniApi.updateNotifications(form)
            dispatch(setNotificationsSettings(response.data.settings.notifications));
            dispatch(toggleProfileUpdating(false));
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleProfileUpdating(false))
        }
    }
}

export const deleteProfile = () => {
    return async (dispatch) => {
        dispatch(toggleProfileUpdating(true));
        try {
            let response = await otvechaiMiniApi.deleteProfile()
            if(response.data.userStatus===103)
                dispatch(setUserStatus(response.data.userStatus));
            dispatch(toggleProfileUpdating(false));
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleProfileUpdating(false))
        }
    }
}

export const restoreProfile = () => {
    return async (dispatch) => {
        dispatch(toggleProfileUpdating(true));
        try {
            let response = await otvechaiMiniApi.restoreProfile()
            if(response.data.userStatus===1)
                dispatch(setUserStatus(response.data.userStatus));
            dispatch(toggleProfileUpdating(false));
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleProfileUpdating(false))
        }
    }
}

export const updateIgnoredTags = (tags) => {
    return async (dispatch) => {
        dispatch(toggleProfileUpdating(true));
        try {
            let response = await otvechaiMiniApi.updateIgnoredTags(tags)
            dispatch(setTagsSettings(response.data.tags));
            dispatch(toggleProfileUpdating(false));
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleProfileUpdating(false))
        }
    }
}

export const removeUserFromSettings = (id,type) => {
    return async (dispatch) => {
        let response
        if(type==='bl'){
            response = await otvechaiMiniApi.removeBlacklist(id);
            response.data.status === 0 && dispatch(setBlUserRemoved(id))
        }
        else if (type==='gl'){
            response = await otvechaiMiniApi.removeGreylist(id);
            response.data.status === 0 && dispatch(setGlUserRemoved(id));
        }
    }
}


const settingsReducer = (state=initialState,action) => {
    switch (action.type) {
        case SET_ANKET:
            return {
                ...state,
                anket: action.anket
            };
        case SET_INTERFACE_SETTINGS:
            return {
                ...state,
                settings:{...state.settings, interface:action.iface}
            };
        case SET_MENU_SETTINGS:
            return {
                ...state,
                settings:{...state.settings, menu:action.menu}
            };
        case SET_NOTIFICATIONS_SETTINGS:
            return {
                ...state,
                settings:{...state.settings, notifications:action.notifications}
            };
        case SET_BLACKLIST_SETTINGS:
            return {
                ...state,
                ignore:{...state.ignore, blacklist:action.blacklist}
            };
        case SET_GREYLIST_SETTINGS:
            return {
                ...state,
                ignore:{...state.ignore, greylist:action.greylist}
            };
        case SET_TAGS_SETTINGS:
            return {
                ...state,
                ignore:{...state.ignore, tags:action.tags}
            };
        case ADD_TAGS_TO_IGNORE:
            return {
                ...state,
                ignore:{...state.ignore,
                    tags:{...state.ignore.tags,
                        ignored:state.ignore.tags.ignored.concat(action.tags)}}
            };
        case REMOVE_TAGS_FROM_IGNORE:
            return {
                ...state,
                ignore:{...state.ignore,
                    tags:{...state.ignore.tags,
                        ignored:state.ignore.tags.ignored.filter(o => (o.id !== action.tags.id))}}
            };
        case SET_BL_USER_REMOVED:
            return {
                ...state,

                ignore:{...state.ignore, blacklist:state.ignore.blacklist.filter(o => (o.id !== action.id))},
            };
        case SET_GL_USER_REMOVED:
            return {
                ...state,
                ignore:{...state.ignore, greylist:state.ignore.greylist.filter(o => (o.id !== action.id))},
            };
        case PROFILE_UPDATING:
            return{
                ...state,
                profileUpdating: action.status
            };
        case ENABLE_ALL_NOTIFS:
            return{
                ...state,
                settings: {...state.settings,notifications:{...state.settings.notifications,disable_all_notifs:false}}
            };
        default:
            return state
    }
};

export default settingsReducer