import React, {useEffect, useState} from "react";
import {Avatar, Cell, CellButton, Div, Panel, PanelHeader, PanelSpinner, Tabs, TabsItem, Title} from "@vkontakte/vkui";
import TopNav from "../Nav/TopNav";
import BottomNav from "../Nav/BottomNav";
import {useDispatch, useSelector} from "react-redux";
import {getLeaders} from "../Redux/rateReducer";
import {checkDev} from "../utils/helpers";
import {loadProfile} from "../Redux/profileReducer";
import {setActivePanel} from "../Redux/appReducer";
import {Icon16StarCircle} from "@vkontakte/icons";


const LeadersPanel = (props) => {

    const [currentTab, setCurrentTab] = useState(1);
    const dispatch = useDispatch();
    const leaders = useSelector(state => state.rate.leaders);
    const app = useSelector(state => state.app);

    useEffect(()=>{
        dispatch(getLeaders(currentTab===2))
    },[currentTab]);

    const leadersSet = leaders.map((el,i)=>(
        <Cell style={{marginTop:10}}
              disabled
              before={<Avatar style={{cursor:"pointer"}} size={60} src={checkDev(el.avatar_small)} onClick={()=>{dispatch(setActivePanel('profile'));dispatch(loadProfile(el.username))}}/>}
              after={<CellButton onClick={()=>{dispatch(setActivePanel('rate'))}} after={<Icon16StarCircle style={{color:"#6bc678"}} width={32} height={32}/>}><div><div style={{textAlign:"right"}}>{el.rate}</div><div style={{color:"green",textAlign:"right"}}>+{el.daily_rate}</div></div></CellButton>}
        ><span style={{cursor:"pointer"}} onClick={()=>{dispatch(setActivePanel('profile'));dispatch(loadProfile(el.username))}}>{el.name}</span></Cell>
    ))


    return(
        <Panel id={props.id}>
            <PanelHeader
                left={<TopNav/>}
            />

            <Tabs>
                <TabsItem selected={currentTab===1}
                          onClick={()=>{setCurrentTab(1)}}
                >
                    Общее
                </TabsItem>

                <TabsItem selected={currentTab===2}
                          onClick={()=>{setCurrentTab(2)}}
                >
                    Сегодня
                </TabsItem>
            </Tabs>
                <Div>
                    <Title level={"1"} weight={"semibold"} style={{
                        marginBottom: 16,
                        textAlign: "center"
                    }}>{currentTab === 1 ? 'Лидеры сайта' : 'Сегодняшние лидеры'}</Title>
                    {app.isFetching ? <PanelSpinner/> :
                        leadersSet
                    }
                </Div>

            <div className={'lastElement'}/>
            <BottomNav/>

        </Panel>
    )
}


export default LeadersPanel