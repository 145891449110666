import React from "react";
import {Checkbox, FormField, IconButton, Input, Select, Textarea} from "@vkontakte/vkui";
import Style from "./Forms.module.css"
import {Icon28Send} from "@vkontakte/icons";


export const FinalFormTextArea =({input,meta, ...props}) =>{
    const is_invalid = meta.touched && meta.error && meta.submitFailed;
    return(
        <>
            <Textarea {...input} {...props} className={props.className}/>
            {is_invalid &&
            <div>
                <span className={Style.error}>{meta.error}</span>
            </div>
            }
        </>
    )
}

export const FinalFormInputArea =({input,meta, ...props}) =>{
    const is_invalid = meta.touched && meta.error && meta.submitFailed;
    return(
        <>
            <Input {...input} {...props} className={props.className}/>
            {is_invalid &&
            <div>
                <span className={Style.error}>{meta.error}</span>
            </div>
            }
        </>
    )
}

export const FinalFormSelectArea =({input,meta, ...props}) =>{
    const is_invalid = meta.touched && meta.error && meta.submitFailed;
    return(
        <>
            <Select {...input} {...props} className={props.className}/>
            {is_invalid &&
            <div>
                <span className={Style.error}>{meta.error}</span>
            </div>
            }
        </>
    )
}

export const FinalFormCheckbox =({input,meta, ...props}) =>{
    return(
        <Checkbox {...input} {...props} className={props.className}/>
    )
}

export const FinalFormChatField =({input,meta, ...props}) =>{
    const is_invalid = meta.touched && meta.error && meta.submitFailed;
    return(
        <div style={{flexGrow:1}}>
            <FormField style={{flexGrow:1}} after={<IconButton onClick={props.handleSubmit}><Icon28Send/></IconButton>}>
                <textarea rows={1}
                
                          style ={{
                              position: 'relative',
                              display: 'block',
                              width: '100%',
                              margin: 0,
                              padding: 11,
                              fontSize: 16,
                              lineHeight: '19px',
                              textOverflow: 'ellipsis',
                              color: ['space_gray','vkcom_dark'].includes(props.scheme)?'#e1e3e6':'#000',
                              border: 'none',
                              background: 'transparent',
                              zIndex: 2,
                              resize:"none"
                          }}

                          {...input} {...props}/>
            </FormField>

            {is_invalid &&
            <div>
                <span className={Style.error}>{meta.error}</span>
            </div>
            }
        </div>
    )
}