import React from "react";
import {
    Group,
    ModalPage,
    ModalPageHeader,
    PanelHeaderButton,
} from "@vkontakte/vkui";
import {Icon24Cancel} from "@vkontakte/icons";
import SendForm from "../Forms/Forms";
import {useDispatch, useSelector} from "react-redux";
import {editPost} from "../Redux/questionReducer";


function ModalEdit(props){
    const formDisabled = useSelector(state => state.app.isFetching);
    const edit = useSelector(state => state.quest.edit)
    const dispatch = useDispatch()
    const onSubmit = (val) => {
        dispatch(editPost(edit.type,edit.id,{body:val[edit.type],tags:val.tags}))
        props.closeModal()
    }

    const imageTypes = {question:'q',answer:'a',comment:'c'}

    return(
        <ModalPage id={props.id}
                   dynamicContentHeight
                   header={
                       <ModalPageHeader
                           right={<PanelHeaderButton onClick={props.closeModal}><Icon24Cancel/></PanelHeaderButton>}
                       > Редактирование {edit.type==='question'&&'вопроса'}{edit.type==='answer'&&'ответа'}{edit.type==='comment'&&'комментария'}
                       </ModalPageHeader>
                   }
        >

            <Group>
                <SendForm
                    fieldName={edit.type}
                    initialValues={{[edit.type]: edit.body,tags:edit.tags}}
                    image={edit.image}
                    image_processing={edit.image_processing}
                    type={edit.type}
                    onSubmit={onSubmit}
                    formDisabled={formDisabled}
                    onCancel={props.closeModal}
                    edit={true}
                    id={edit.id}
                    imageType={imageTypes[edit.type]}
                />
            </Group>

        </ModalPage>
    )
};

export default ModalEdit