import {applyMiddleware, combineReducers, createStore} from "redux";
import thunkMiddleware from 'redux-thunk'

import authReducer from "./authReducer";
import appReducer from "./appReducer";
import notifyReducer from "./notifyReducer";
import profileReducer from "./profileReducer";
import socialReducer from "./socialReducer";
import questionReducer from "./questionReducer";
import modalReducer from "./modalReducer";
import embedReducer from "./embedReducer";
import userPostReducer from "./userPostsReducer";
import pmReducer from "./pmReducer";
import settingsReducer from "./settingsReducer";
import routerReducer from "./routerReducer";
import dailyReducer from "./dailyReducer";
import rateReducer from "./rateReducer";
import headerReducer from "./headerReducer";
import giftsReducer from "./giftsReducer";
import staticReducer from "./staticReducer";


let reducers = combineReducers({
    app:appReducer,
    auth:authReducer,
    notifs:notifyReducer,
    profile:profileReducer,
    social:socialReducer,
    quest:questionReducer,
    userPosts:userPostReducer,
    modal:modalReducer,
    embed:embedReducer,
    pm:pmReducer,
    settings:settingsReducer,
    router:routerReducer,
    daily:dailyReducer,
    rate:rateReducer,
    header:headerReducer,
    gifts:giftsReducer,
    static:staticReducer
});


let store = createStore(reducers,applyMiddleware(thunkMiddleware));

export default store