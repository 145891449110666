import React from "react";
import {Alert, Cell, Group, ModalPage, ModalPageHeader, PanelHeaderButton} from "@vkontakte/vkui";
import {
    Icon28UserCircleFillBlue,
    Icon24Cancel,
    Icon16CancelCircle,
    Icon28CancelCircleFillRed,
    Icon24Notification,
    Icon24NotificationDisable,
    Icon28WarningTriangleOutline,
    Icon20ViewCircleFillRed, Icon20View
} from '@vkontakte/icons';
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel} from "../Redux/appReducer";
import {loadProfile} from "../Redux/profileReducer";
import {loadRandomQuest, removePost, sendReport, sendSub, setGl} from "../Redux/questionReducer";
function ModalPostAction(props){
    const post = useSelector(state => state.modal.post);
    const myPost = (useSelector(state => state.auth.user.username)===useSelector(state => state.modal.post.author_username)||post.remove===true);
    const dispatch = useDispatch();
    const types = {
        'question':'вопрос',
        'answer':'ответ',
        'comment':'комментарий'
    }
    return(
        <ModalPage id={props.id}
                   dynamicContentHeight
                   header={
                       <ModalPageHeader
                           right={<PanelHeaderButton onClick={props.closeModal}><Icon24Cancel/></PanelHeaderButton>}
                       >
                           Действия
                       </ModalPageHeader>
                   }
        >
            <Group>
                {post.author_username&&<Cell
                    before={<Icon28UserCircleFillBlue/>}
                    onClick={() => {
                        dispatch(setActivePanel('profile'));dispatch(loadProfile(post.author_username));props.closeModal()
                    }}
                >
                    Профиль автора

                </Cell>}
                {post.remove &&
                <Cell
                    before={post.remove==='mod'?<Icon28CancelCircleFillRed/>:<Icon16CancelCircle width={28} height={28}/>}
                    onClick={() => {
                        props.setPopout(
                            <Alert
                                actions={[{
                                    title: 'Отмена',
                                    autoclose: true,
                                    mode: 'cancel'
                                }, {
                                    title: 'Удалить',
                                    autoclose: true,
                                    mode: 'destructive',
                                    action: () => dispatch(removePost(post.id,post.type,post.needDispatch)).then(()=> {
                                        if(!post.needDispatch)dispatch(loadRandomQuest())
                                    }),
                                }]}
                                actionsLayout="horizontal"
                                onClose={()=>{props.setPopout(null)}}
                                header={`Удалить ${types[post.type]}?`}
                                text={`Вы уверены, что хотите удалить ${myPost?`свой ${types[post.type]}`:`${types[post.type]} пользователя ${post.author_name}`}?`}
                            />
                        );props.closeModal()
                    }}
                >
                    Удалить

                </Cell>
                }
                {(!myPost&&post.type!=='comment'&&(post.type!=='answer'||post.subscribed))&&<Cell
                    before={post.subscribed ? <Icon24NotificationDisable/> :
                        <Icon24Notification width={28} height={28}/>}
                    onClick={() => {
                        dispatch(sendSub(post.id,post.type)).then(
                            ()=> {
                                props.closeModal();
                                    props.setPopout(
                                        <Alert
                                            actions={[{
                                                title: 'Ок',
                                                autoclose: true,
                                                mode: 'default'
                                            },]}
                                            actionsLayout="horizontal"
                                            onClose={()=>{props.setPopout(null)}}
                                            header={`Вы ${post.subscribed?"отписались":"подписались"}`}
                                            text={`Вы ${post.subscribed?`отписались от ${types[post.type]}а`:`подписались на ${types[post.type]}`} пользователя ${post.author_name}`}
                                        />
                                    );props.closeModal()

                            });

                    }}
                >
                    {post.subscribed ? 'Отписаться' : 'Подписаться'}

                </Cell>}

                {!myPost&&<Cell
                    before={<Icon28WarningTriangleOutline/>}
                    onClick={() => {
                        props.setPopout(
                            <Alert
                                actions={[{
                                    title: 'Отмена',
                                    autoclose: true,
                                    mode: 'cancel'
                                }, {
                                    title: 'Пожаловаться',
                                    autoclose: true,
                                    mode: 'destructive',
                                    action: () => dispatch(sendReport(post.id,post.type)).then((result)=>{
                                        props.setPopout(
                                            <Alert
                                                actions={[{
                                                    title: 'Ок',
                                                    autoclose: true,
                                                    mode: 'default'
                                                }, ]}
                                                actionsLayout="horizontal"
                                                onClose={()=>{props.setPopout(null)}}
                                                header={`${result===0?'Жалоба отправлена':''}${result===2?'Жалоба не отправлена':''}`}
                                                text={`${result===0?'Мы рассмотрим Вашу жалобу в ближайшее время':''}${result===2?`Вы уже жаловались на этот ${types[post.type]}`:''}`}
                                            />
                                        )
                                    })
                                }]}
                                actionsLayout="horizontal"
                                onClose={()=>{props.setPopout(null)}}
                                header={`Отправить жалобу?`}
                                text={`Пожаловаться на ${types[post.type]} пользователя ${post.author_name}?`}
                            />
                        );props.closeModal()
                    }}
                    >
                    Пожаловаться

                </Cell>}
                {(!myPost&&!post.author_id===0&&post.type==='question')&&
                    <Cell
                        before={post.author_gl ? <Icon20View width={28} height={28}/> :
                            <Icon20ViewCircleFillRed width={28} height={28}/>}
                        onClick={() => {
                            props.setPopout(
                                <Alert
                                    actions={[{
                                        title: 'Отмена',
                                        autoclose: true,
                                        mode: 'cancel'
                                    }, {
                                        title: `${post.author_gl?'Удалить':'Добавить'}`,
                                        autoclose: true,
                                        mode: 'destructive',
                                        action: () => dispatch(setGl(post.author_id,post.author_gl)),
                                    }]}
                                    actionsLayout="horizontal"
                                    onClose={()=>{props.setPopout(null)}}
                                    header={post.author_gl?'Удалить из серого списка?':'Добавить в серый список?'}
                                    text={`${post.author_gl?'Удалить из серого списка':'Добавить в серый список'} пользователя ${post.author_name}?`}
                                />
                            );props.closeModal()
                        }}
                    >
                        {post.author_gl ? 'Удалить из серого списка' : 'Добавить в серый список'}

                    </Cell>
                }
            </Group>
        </ModalPage>
    )
}

export default ModalPostAction