import React, {useEffect, useRef, useState} from "react";
import {
    Panel,
    PanelHeader,
    Div,
    FixedLayout, PanelHeaderBack, PanelHeaderContent, Avatar, Button, Spinner, Group, PanelSpinner
} from "@vkontakte/vkui";
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel} from "../Redux/appReducer";
import Style from "./Chat.module.css"
import Message from "./Message";
import {
    addNewPmMessage,
    addPm,
    addPmCount,
    getPm,
    postPm,
    setPmCurrentUser,
    setPmMessages,
    togglePmViewed
} from "../Redux/pmReducer";
import {checkDev, declOfNum, scrollToRef, useEventListener} from "../utils/helpers";
import { Icon16CheckDoubleOutline,Icon20Check } from '@vkontakte/icons';
import useWebSocket, {ReadyState} from "react-use-websocket";
import {refreshTokens} from "../Redux/authReducer";
import ChatForm from "../Forms/ChatForm";
import {useIdleTimer} from "react-idle-timer";
import {loadProfile} from "../Redux/profileReducer";
import {historyPush, toggleDoNotAdd} from "../Redux/routerReducer";
import TopNav from "../Nav/TopNav";
import PmBlocked from "./PmBlocked";
import UserStatus from "../QuestionPanel/UserStatus";


const ChatPanel = (props) => {
    const dispatch=useDispatch()
    const scheme = useSelector(state => state.app.scheme);
    const hasMore = useSelector(state => state.app.hasMore);
    const moreLoading = useSelector(state => state.app.moreLoading);
    const pm = useSelector(state => state.pm);
    const [page, setPage] = useState(2);
    const bottomRef = useRef(null)
    const isFetching = useSelector(state => state.app.isFetching);
    const auth = useSelector(state => state.auth);
    const [meTyping, toggleMeTyping] = useState(null);
    const [userTyping, toggleUserTyping] = useState(null);
    const [unread, toggleUnread] = useState(false);

    const doNotAdd = useSelector(state => state.router.doNotAdd);
    const history = useSelector(state => state.router.history);

    const sendPm = (val) => {
        dispatch(postPm(pm.currentUser, {message:val.chat}));
        toggleMeTyping(false)
    }



    const scrollBottom = () =>{
        const scrollContainer = document.getElementById('app_root')
        if (bottomRef.current&&scrollContainer)
            setTimeout(()=> {
                scrollToRef(bottomRef.current.offsetTop, scrollContainer)
            })
    }

    const {
        sendMessage,
        lastMessage,
        readyState,
    } = useWebSocket(checkDev(`/ws/pm/${pm.currentUser}`,true), {
            shouldReconnect: async (closeEvent) =>{
                if(closeEvent.code === 4001){
                    let status = await dispatch(refreshTokens())
                    return status === 0;
                }
                else {
                    return true
                }
            },
            retryOnError: true,
        }
    );

    const onFocus = () => {
        readPm()
        if(readyState === ReadyState.OPEN){
            console.log("Проверяем новые сообщения")
            sendMessage(JSON.stringify({type:'check',last:lastPm()}))
        }
    }
    const lastPm = () => {
        if(pm.messages.length > 0)
            return pm.messages[pm.messages.length-1]['id']
        else
            return 0
    }

    const readPm = () => {
        if(unread && readyState === ReadyState.OPEN){
            toggleUnread(false)
            sendMessage(JSON.stringify({type:'viewed'}))
        }
    }

    useEventListener('focus', onFocus);

    useEventListener('mousemove', readPm);
    useEventListener('touchmove', readPm);
    useEventListener('keydown', readPm);

    const handleOnIdle = event => {
        if(meTyping)
            toggleMeTyping(false)
    }

    const handleOnActive = event => {
        if(!meTyping)
            toggleMeTyping(true)
    }

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 5,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        events:['keydown'],
        debounce: 500
    })



   const wsPmMessageHandler = (data) => async (dispatch) => {
        if(data.type==='newMessage') {
            if (auth.user.id === data.data['message']['sender']) {
                data.data['message']['recipient'] = pm.user.id
                data.data['message']['myMessage'] = true
                dispatch(togglePmViewed(data.data['viewed']))
            } else {
                data.data['message']['recipient'] = auth.user.id
                data.data['message']['myMessage'] = false
                toggleUnread(true)
            }
            dispatch(addNewPmMessage(data.data['message']))
            dispatch(addPmCount(1))
            scrollBottom()
        }
        else if (data.type === 'check' && data.data['messages'].length > 0) {
            if (data.data['messages'].some((el)=>(el.sender===pm.user.id)))
                toggleUnread(true)
            dispatch(addNewPmMessage(data.data['messages']))
            dispatch(addPmCount(data.data['messages'].length))
            dispatch(togglePmViewed(data.data['viewed']))
            if (bottomRef.current)
                bottomRef.current.scrollIntoView({behavior: 'smooth'})
        } else if (data.type === 'view') {
            dispatch(togglePmViewed(data.data['viewed']))
        }
        else if (data.type === 'typing'&& data.user!==auth.user.id) {
            toggleUserTyping(data.typing)
        }
    }

    useEffect(()=>{
        if(lastMessage) dispatch(wsPmMessageHandler(JSON.parse(lastMessage.data)))
    },[lastMessage]);



    useEffect(()=>{
        dispatch(getPm(pm.currentUser)).then(scrollBottom)
        return(()=> {
            dispatch(setPmCurrentUser(null));
            dispatch(setPmMessages([]))
        })
    },[pm.currentUser]);

    //router
    useEffect(()=>{
        if(!doNotAdd){
            if (pm.currentUser) dispatch(historyPush({
                panel: 'chat',
                type:null,
                id: pm.currentUser
            }))
        }
        dispatch(toggleDoNotAdd(false))
    },[pm.currentUser]);

    useEffect(()=>{
        if(readyState === ReadyState.OPEN)
            sendMessage(JSON.stringify({type:'typing',typing:meTyping}))
    },[meTyping]);


    if(isFetching)
        return(
            <Panel id={props.id}>
                <PanelSpinner/>
            </Panel>
        )

    const getMore = () => {
        if(!pm.moreLoading) {
            const messageContainer = document.getElementById('messageContainer')
            const scrollContainer = document.getElementById('app_root')
            const oldHigh = messageContainer.scrollHeight
            dispatch(addPm(pm.user.username, page)).then(()=>{
                scrollToRef(messageContainer.scrollHeight - oldHigh,scrollContainer,false)
            })
            setPage(prev=>prev+1)
        }
    }

   const messageSet = pm.messages.map((el,i) =>{
        return(
            <Message
                key={el.id}
                body={el.body}
                date_pub={el.date_pub}
                myMessage={el.myMessage}
                scheme={scheme}
            />
        )})

    return(
        <Panel id={props.id}>
            <PanelHeader left={history.length>1?<TopNav backOnly={true}/>:<PanelHeaderBack onClick={ ()=>dispatch(setActivePanel('chats'))}/>}>
                <PanelHeaderContent
                    status={userTyping?<span className={Style.typing}>печатает</span>:`${pm.count} ${declOfNum(pm.count,['сообщение','сообщения','собщений'])}`}
                    before={<Avatar size={36} src={checkDev(pm.user.avatar)}><UserStatus size={14} online={pm.user.online}/></Avatar>}
                >
                    <span
                        style={{cursor:"pointer"}}
                        onClick={()=>{
                        dispatch(loadProfile(pm.user.username));dispatch(setActivePanel('profile'))
                    }
                    }>{pm.user.name}</span>
                </PanelHeaderContent>
            </PanelHeader>

            <Div className={Style.container} id={'messageContainer'}>
                {hasMore&&<Group className={Style.loadMoreContainer}>
                    <Button mode={'secondary'}
                            onClick={getMore}
                            disabled={moreLoading}
                    >
                        {moreLoading?<Spinner/>:'Загрузить ещё'}
                    </Button>
                </Group>}

                {messageSet}
                <div id={'bottomRef'} ref={bottomRef}/>


            </Div>
            <FixedLayout filled vertical={"bottom"}>
                <Div style={{height:90}}>
                    <Div className={Style.viewed}>
                        {pm.viewed ?
                            <>
                                <Icon16CheckDoubleOutline className={Style.viewedIcon}/>
                                <span className={Style.viewStatusText}>сообщение прочитано</span>
                            </>:
                            <>
                                <Icon20Check height={16} width={16}/>
                                <span className={Style.viewStatusText}>сообщение не прочитано</span>
                            </>
                        }
                    </Div>
                    {pm.status === 0 ?
                        <ChatForm
                            scheme={scheme}
                            onSubmit={sendPm}
                            toggleMeTyping={toggleMeTyping}
                            meTyping={meTyping}
                        />:
                        <PmBlocked
                            status={pm.status}
                            name={pm.user.name}
                            username={pm.user.username}
                            avatar={pm.user.avatar}
                        />
                    }
                </Div>
            </FixedLayout>
        </Panel>
    )
}

export default ChatPanel