import React from "react";
import {Alert, Cell, Group, ModalPage, ModalPageHeader, PanelHeaderButton} from "@vkontakte/vkui";
import {Icon24Cancel,Icon28UserStarBadgeOutline,Icon28UserStarBadgeSlashOutline,Icon28BlockOutline,Icon24UnblockOutline,Icon28WarningTriangleOutline} from "@vkontakte/icons";
import {useDispatch, useSelector} from "react-redux";
import {blacklist, friend} from "../Redux/socialReducer";
import {setActiveModal} from "../Redux/appReducer";

function ModalProfileAction(props){
    const profile = useSelector(state => state.profile);
    const token = useSelector(state => state.auth.token.access_token);
    const dispatch = useDispatch();
    return(
        <ModalPage id={props.id}
                   dynamicContentHeight
                   header={
                       <ModalPageHeader
                           right={<PanelHeaderButton onClick={props.closeModal}><Icon24Cancel/></PanelHeaderButton>}
                       >
                           Действия
                       </ModalPageHeader>
                   }
        >
            <Group>
                {(!profile.data.in_blacklist && !profile.data.me_blacklisted) &&
                <>
                    {profile.data.is_friend ?
                        <Cell
                            before={<Icon28UserStarBadgeSlashOutline/>}
                            onClick={() => {
                                props.setPopout(
                                    <Alert
                                        actions={[{
                                            title: 'Отмена',
                                            autoclose: true,
                                            mode: 'cancel'
                                        }, {
                                            title: 'Удалить',
                                            autoclose: true,
                                            mode: 'destructive',
                                            action: () => dispatch(friend('remove',profile.data.userInfo.id,token)),
                                        }]}
                                        actionsLayout="horizontal"
                                        onClose={()=>{props.setPopout(null)}}
                                        header={`Удалить друга?`}
                                        text={`Вы уверены, что хотите удалить пользователя ${profile.data.userInfo.name} из друзей?`}
                                    />
                                );props.closeModal()
                            }}
                        >
                            Удалить из друзей
                        </Cell> :
                        <Cell
                            before={<Icon28UserStarBadgeOutline/>}
                            onClick={()=>{dispatch(friend('add',profile.data.userInfo.id,token)).then(()=>props.closeModal())}}
                        >
                            Добавить в друзья

                        </Cell>
                    }
                </>
                }
                {!profile.data.is_friend &&
                <>
                    {profile.data.in_blacklist ?
                        <Cell
                            before={<Icon24UnblockOutline width={28} height={28}/>}
                            onClick={()=>{dispatch(blacklist('remove',profile.data.userInfo.id,token)).then(()=>props.closeModal())}}
                        >
                            Разблокировать

                        </Cell> :
                        <Cell
                            before={<Icon28BlockOutline/>}
                            onClick= {() => {
                                props.setPopout(
                                    <Alert
                                        actions={[{
                                            title: 'Отмена',
                                            autoclose: true,
                                            mode: 'cancel'
                                        }, {
                                            title: 'Заблокировать',
                                            autoclose: true,
                                            mode: 'destructive',
                                            action: () => dispatch(blacklist('add',profile.data.userInfo.id,token)).then(()=>props.closeModal()),
                                        }]}
                                        actionsLayout="horizontal"
                                        onClose={()=>{props.setPopout(null)}}
                                        header={`Заблокировать пользователя?`}
                                        text={`Вы уверены, что хотите заблокировать пользователя ${profile.data.userInfo.name} из друзей?`}
                                    />
                                );props.closeModal()
                            }}
                        >
                            Заблокировать

                        </Cell>
                    }
                </>
                }
                {!profile.data.is_friend &&
                <Cell
                    before={<Icon28WarningTriangleOutline/>}
                    onClick={() => {
                        dispatch(setActiveModal('abuse'))
                    }}
                >
                    Жалоба

                </Cell>
                }

            </Group>
        </ModalPage>
    )
}

export default ModalProfileAction