import { Icon28PictureOutline, Icon28Send } from '@vkontakte/icons';
import {FixedLayout, Div, IconButton, FormField} from '@vkontakte/vkui';
import React from "react";
import {useSelector} from 'react-redux';


const FakeStickyForm = (props) => {
    const scheme = useSelector(state => state.app.scheme);
    return(
        <FixedLayout vertical="bottom" style={{bottom:props.position?props.position:45}}>
            <Div style={{display:"flex", justifyContent:"space-between",backgroundColor:['space_gray','vkcom_dark'].includes(scheme)?"#2c2d2e":"#fff"}}>
            <IconButton disabled={true}><Icon28PictureOutline/></IconButton>
            <div style={{flexGrow:1}}>
            <FormField style={{flexGrow:1}} after={<IconButton onClick={()=>{if(props.removed)props.skipPost()}} disabled={!props.removed}><Icon28Send/></IconButton>}>
            <textarea rows={1}
                      disabled={true}
                      placeholder={props.removed?`${props.placeholder} удалён`:props.placeholder}
                
                style ={{
                    position: 'relative',
                    display: 'block',
                    width: '100%',
                    margin: 0,
                    padding: 11,
                    fontSize: 16,
                    lineHeight: '19px',
                    textOverflow: 'ellipsis',
                    color: ['space_gray','vkcom_dark'].includes(scheme)?'#e1e3e6':'#000',
                    border: 'none',
                    background: 'transparent',
                    zIndex: 2,
                    resize:"none"
                }}

            />
            </FormField>
            </div>
            </Div>
        </FixedLayout>
    )
}

export default FakeStickyForm